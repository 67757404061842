/*

  To add a new Enum for translation, you will need to:
  1. In the codegen.ts, add the Enum to: 
      config => generates => './src/GeneratedGraphQL/EnumTranslations.ts' => config => targets
  2. Run codegen
  3. Add the translations to the translation file, see instructions in the 
     section that is newly generated in this file

*/



import { AppointmentMeasurementCategory, AppointmentStatus, CareEpisodeInferenceReason, CareEpisodeProviderRelationship, CareEpisodeStatus, CareEpisodeTreatmentTrackStatus, ClinicalAlertValue, CocmBillingAlgorithmRuleCheck, CocmBillingAlgorithmRuleCheckValue, CocmBillingAlgorithmStatus, ConsultMeetingStatus, ConsultRequestReason, CoverageRelationship, DataDumpResource, DataSourceRecordAlgorithm, DataSourceRecordLogError, DataSourceRecordLogStatus, DischargeReason, EnrollmentMonthMinutesTargetStatus, EnrollmentStatus, EntityDecisionAlgorithmStatus, EntityType, Gender, GenderIdentity, ImplementationTargetOnTrack, ImplementationTargetReportGraphDataSeries, ImplementationTargetReportPeriod, ImplementationTargetStatPeriod, ImplementationTargetStatus, ImplementationTargetType, InvitationFormat, InvitationPhase, InvitationStatus, NotificationPreference, OncePerPeriod, PanelTeamMemberFunction, PatientConditionStatus, PatientQualityIssueStatus, PatientQualityIssueType, RecurrenceFrequency, RelatedPersonRelationship, ScaleLocale, ScaleTargeting, ScaleThresholdClass, SeverityCategory, TaskStatus, TreatmentServiceInferenceReason, Trend, TriggeredWorkflowStatus, UserConsentStatus } from "./SchemaAndOperations";
import { exhaustiveGuard } from "type-utils"
import resources from "i18n/Resources"

    
  /* The translation format to get you started
  AppointmentMeasurementCategory: {
  AWAITING_INTERVIEW: {
    title: "AWAITING_INTERVIEW",
    description: "Awaiting Interview",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "Complete",
  },
  ERROR: {
    title: "ERROR",
    description: "Error",
  },
  FAILED: {
    title: "FAILED",
    description: "Failed",
  },
  FUTURE: {
    title: "FUTURE",
    description: "Future",
  },
  IN_PROGRESS: {
    title: "IN_PROGRESS",
    description: "In Progress",
  },
  MEASUREMENT_CANCELED: {
    title: "MEASUREMENT_CANCELED",
    description: "Measurement Canceled",
  },
  NOTIFICATIONS_BLOCKED: {
    title: "NOTIFICATIONS_BLOCKED",
    description: "Notifications Blocked",
  },
  NOT_MEASURED: {
    title: "NOT_MEASURED",
    description: "Not Measured",
  },
  NOT_PARTICIPATING: {
    title: "NOT_PARTICIPATING",
    description: "Not Participating",
  },
  NOT_STARTED: {
    title: "NOT_STARTED",
    description: "Not Started",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function appointmentMeasurementCategoryT(value: AppointmentMeasurementCategory, t: (t: `enums:AppointmentMeasurementCategory.${keyof typeof resources["en"]["enums"]["AppointmentMeasurementCategory"]}.title`) => string):string {
    switch (value) {
      case AppointmentMeasurementCategory.AWAITING_INTERVIEW: return t("enums:AppointmentMeasurementCategory.AWAITING_INTERVIEW.title");
case AppointmentMeasurementCategory.COMPLETE: return t("enums:AppointmentMeasurementCategory.COMPLETE.title");
case AppointmentMeasurementCategory.ERROR: return t("enums:AppointmentMeasurementCategory.ERROR.title");
case AppointmentMeasurementCategory.FAILED: return t("enums:AppointmentMeasurementCategory.FAILED.title");
case AppointmentMeasurementCategory.FUTURE: return t("enums:AppointmentMeasurementCategory.FUTURE.title");
case AppointmentMeasurementCategory.IN_PROGRESS: return t("enums:AppointmentMeasurementCategory.IN_PROGRESS.title");
case AppointmentMeasurementCategory.MEASUREMENT_CANCELED: return t("enums:AppointmentMeasurementCategory.MEASUREMENT_CANCELED.title");
case AppointmentMeasurementCategory.NOTIFICATIONS_BLOCKED: return t("enums:AppointmentMeasurementCategory.NOTIFICATIONS_BLOCKED.title");
case AppointmentMeasurementCategory.NOT_MEASURED: return t("enums:AppointmentMeasurementCategory.NOT_MEASURED.title");
case AppointmentMeasurementCategory.NOT_PARTICIPATING: return t("enums:AppointmentMeasurementCategory.NOT_PARTICIPATING.title");
case AppointmentMeasurementCategory.NOT_STARTED: return t("enums:AppointmentMeasurementCategory.NOT_STARTED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  AppointmentMeasurementCategory: {
  AWAITING_INTERVIEW: {
    title: "AWAITING_INTERVIEW",
    description: "Awaiting Interview",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "Complete",
  },
  ERROR: {
    title: "ERROR",
    description: "Error",
  },
  FAILED: {
    title: "FAILED",
    description: "Failed",
  },
  FUTURE: {
    title: "FUTURE",
    description: "Future",
  },
  IN_PROGRESS: {
    title: "IN_PROGRESS",
    description: "In Progress",
  },
  MEASUREMENT_CANCELED: {
    title: "MEASUREMENT_CANCELED",
    description: "Measurement Canceled",
  },
  NOTIFICATIONS_BLOCKED: {
    title: "NOTIFICATIONS_BLOCKED",
    description: "Notifications Blocked",
  },
  NOT_MEASURED: {
    title: "NOT_MEASURED",
    description: "Not Measured",
  },
  NOT_PARTICIPATING: {
    title: "NOT_PARTICIPATING",
    description: "Not Participating",
  },
  NOT_STARTED: {
    title: "NOT_STARTED",
    description: "Not Started",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function appointmentMeasurementCategoryDescriptionT(value: AppointmentMeasurementCategory, t: (t: `enums:AppointmentMeasurementCategory.${keyof typeof resources["en"]["enums"]["AppointmentMeasurementCategory"]}.description`) => string):string {
    switch (value) {
      case AppointmentMeasurementCategory.AWAITING_INTERVIEW: return t("enums:AppointmentMeasurementCategory.AWAITING_INTERVIEW.description");
case AppointmentMeasurementCategory.COMPLETE: return t("enums:AppointmentMeasurementCategory.COMPLETE.description");
case AppointmentMeasurementCategory.ERROR: return t("enums:AppointmentMeasurementCategory.ERROR.description");
case AppointmentMeasurementCategory.FAILED: return t("enums:AppointmentMeasurementCategory.FAILED.description");
case AppointmentMeasurementCategory.FUTURE: return t("enums:AppointmentMeasurementCategory.FUTURE.description");
case AppointmentMeasurementCategory.IN_PROGRESS: return t("enums:AppointmentMeasurementCategory.IN_PROGRESS.description");
case AppointmentMeasurementCategory.MEASUREMENT_CANCELED: return t("enums:AppointmentMeasurementCategory.MEASUREMENT_CANCELED.description");
case AppointmentMeasurementCategory.NOTIFICATIONS_BLOCKED: return t("enums:AppointmentMeasurementCategory.NOTIFICATIONS_BLOCKED.description");
case AppointmentMeasurementCategory.NOT_MEASURED: return t("enums:AppointmentMeasurementCategory.NOT_MEASURED.description");
case AppointmentMeasurementCategory.NOT_PARTICIPATING: return t("enums:AppointmentMeasurementCategory.NOT_PARTICIPATING.description");
case AppointmentMeasurementCategory.NOT_STARTED: return t("enums:AppointmentMeasurementCategory.NOT_STARTED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  AppointmentStatus: {
  ARRIVED: {
    title: "ARRIVED",
    description: "Arrived",
  },
  BOOKED: {
    title: "BOOKED",
    description: "Booked",
  },
  CANCELED: {
    title: "CANCELED",
    description: "Canceled",
  },
  CHECKED_IN: {
    title: "CHECKED_IN",
    description: "Checked in",
  },
  ENTERED_IN_ERROR: {
    title: "ENTERED_IN_ERROR",
    description: "Entered in error",
  },
  FULFILLED: {
    title: "FULFILLED",
    description: "Fulfilled",
  },
  NOSHOW: {
    title: "NOSHOW",
    description: "No-show",
  },
  PENDING: {
    title: "PENDING",
    description: "Pending",
  },
  PROPOSED: {
    title: "PROPOSED",
    description: "Proposed",
  },
  WAIT_LIST: {
    title: "WAIT_LIST",
    description: "Wait list",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function appointmentStatusT(value: AppointmentStatus, t: (t: `enums:AppointmentStatus.${keyof typeof resources["en"]["enums"]["AppointmentStatus"]}.title`) => string):string {
    switch (value) {
      case AppointmentStatus.ARRIVED: return t("enums:AppointmentStatus.ARRIVED.title");
case AppointmentStatus.BOOKED: return t("enums:AppointmentStatus.BOOKED.title");
case AppointmentStatus.CANCELED: return t("enums:AppointmentStatus.CANCELED.title");
case AppointmentStatus.CHECKED_IN: return t("enums:AppointmentStatus.CHECKED_IN.title");
case AppointmentStatus.ENTERED_IN_ERROR: return t("enums:AppointmentStatus.ENTERED_IN_ERROR.title");
case AppointmentStatus.FULFILLED: return t("enums:AppointmentStatus.FULFILLED.title");
case AppointmentStatus.NOSHOW: return t("enums:AppointmentStatus.NOSHOW.title");
case AppointmentStatus.PENDING: return t("enums:AppointmentStatus.PENDING.title");
case AppointmentStatus.PROPOSED: return t("enums:AppointmentStatus.PROPOSED.title");
case AppointmentStatus.WAIT_LIST: return t("enums:AppointmentStatus.WAIT_LIST.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  AppointmentStatus: {
  ARRIVED: {
    title: "ARRIVED",
    description: "Arrived",
  },
  BOOKED: {
    title: "BOOKED",
    description: "Booked",
  },
  CANCELED: {
    title: "CANCELED",
    description: "Canceled",
  },
  CHECKED_IN: {
    title: "CHECKED_IN",
    description: "Checked in",
  },
  ENTERED_IN_ERROR: {
    title: "ENTERED_IN_ERROR",
    description: "Entered in error",
  },
  FULFILLED: {
    title: "FULFILLED",
    description: "Fulfilled",
  },
  NOSHOW: {
    title: "NOSHOW",
    description: "No-show",
  },
  PENDING: {
    title: "PENDING",
    description: "Pending",
  },
  PROPOSED: {
    title: "PROPOSED",
    description: "Proposed",
  },
  WAIT_LIST: {
    title: "WAIT_LIST",
    description: "Wait list",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function appointmentStatusDescriptionT(value: AppointmentStatus, t: (t: `enums:AppointmentStatus.${keyof typeof resources["en"]["enums"]["AppointmentStatus"]}.description`) => string):string {
    switch (value) {
      case AppointmentStatus.ARRIVED: return t("enums:AppointmentStatus.ARRIVED.description");
case AppointmentStatus.BOOKED: return t("enums:AppointmentStatus.BOOKED.description");
case AppointmentStatus.CANCELED: return t("enums:AppointmentStatus.CANCELED.description");
case AppointmentStatus.CHECKED_IN: return t("enums:AppointmentStatus.CHECKED_IN.description");
case AppointmentStatus.ENTERED_IN_ERROR: return t("enums:AppointmentStatus.ENTERED_IN_ERROR.description");
case AppointmentStatus.FULFILLED: return t("enums:AppointmentStatus.FULFILLED.description");
case AppointmentStatus.NOSHOW: return t("enums:AppointmentStatus.NOSHOW.description");
case AppointmentStatus.PENDING: return t("enums:AppointmentStatus.PENDING.description");
case AppointmentStatus.PROPOSED: return t("enums:AppointmentStatus.PROPOSED.description");
case AppointmentStatus.WAIT_LIST: return t("enums:AppointmentStatus.WAIT_LIST.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  CareEpisodeInferenceReason: {
  APPOINTMENT_NO_TREATMENT_SERVICE: {
    title: "APPOINTMENT_NO_TREATMENT_SERVICE",
    description: "Appointments require a treatment service to be matched and this appointment has no treatment service",
  },
  CARE_EPISODES_MANAGED_EXTERNALLY: {
    title: "CARE_EPISODES_MANAGED_EXTERNALLY",
    description: "Mirah is receiving episodes of care from the EHR and assumes that these should be chosen",
  },
  EXACT_MATCH_ORGANIZATION: {
    title: "EXACT_MATCH_ORGANIZATION",
    description: "There is exactly one episode of care in the same organizations as the provider",
  },
  MATCHED_TREATMENT_SERVICE: {
    title: "MATCHED_TREATMENT_SERVICE",
    description: "An active episode of care with a matching treatment service was found",
  },
  MOST_RECENT_MATCH_ORGANIZATION: {
    title: "MOST_RECENT_MATCH_ORGANIZATION",
    description: "There are multiple episodes of care in the same organization as the provider,
the one with the most recent measurement has been chosen.",
  },
  NO_MATCH_DEFAULT: {
    title: "NO_MATCH_DEFAULT",
    description: "No episodes of care matched the exact criteria, so the most recent active episode of care was chosen",
  },
  NO_MATCH_FOUND: {
    title: "NO_MATCH_FOUND",
    description: "There are no episodes of care suitable to be used",
  },
  PROVIDER_ON_CARE_TEAM: {
    title: "PROVIDER_ON_CARE_TEAM",
    description: "The provider is on the care team given",
  },
  SINGLE_EPISODE_OF_CARE_ALLOWED: {
    title: "SINGLE_EPISODE_OF_CARE_ALLOWED",
    description: "The system is configured to only allow one episode of care at a time, and that episode of care will always be chosen",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function careEpisodeInferenceReasonT(value: CareEpisodeInferenceReason, t: (t: `enums:CareEpisodeInferenceReason.${keyof typeof resources["en"]["enums"]["CareEpisodeInferenceReason"]}.title`) => string):string {
    switch (value) {
      case CareEpisodeInferenceReason.APPOINTMENT_NO_TREATMENT_SERVICE: return t("enums:CareEpisodeInferenceReason.APPOINTMENT_NO_TREATMENT_SERVICE.title");
case CareEpisodeInferenceReason.CARE_EPISODES_MANAGED_EXTERNALLY: return t("enums:CareEpisodeInferenceReason.CARE_EPISODES_MANAGED_EXTERNALLY.title");
case CareEpisodeInferenceReason.EXACT_MATCH_ORGANIZATION: return t("enums:CareEpisodeInferenceReason.EXACT_MATCH_ORGANIZATION.title");
case CareEpisodeInferenceReason.MATCHED_TREATMENT_SERVICE: return t("enums:CareEpisodeInferenceReason.MATCHED_TREATMENT_SERVICE.title");
case CareEpisodeInferenceReason.MOST_RECENT_MATCH_ORGANIZATION: return t("enums:CareEpisodeInferenceReason.MOST_RECENT_MATCH_ORGANIZATION.title");
case CareEpisodeInferenceReason.NO_MATCH_DEFAULT: return t("enums:CareEpisodeInferenceReason.NO_MATCH_DEFAULT.title");
case CareEpisodeInferenceReason.NO_MATCH_FOUND: return t("enums:CareEpisodeInferenceReason.NO_MATCH_FOUND.title");
case CareEpisodeInferenceReason.PROVIDER_ON_CARE_TEAM: return t("enums:CareEpisodeInferenceReason.PROVIDER_ON_CARE_TEAM.title");
case CareEpisodeInferenceReason.SINGLE_EPISODE_OF_CARE_ALLOWED: return t("enums:CareEpisodeInferenceReason.SINGLE_EPISODE_OF_CARE_ALLOWED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  CareEpisodeInferenceReason: {
  APPOINTMENT_NO_TREATMENT_SERVICE: {
    title: "APPOINTMENT_NO_TREATMENT_SERVICE",
    description: "Appointments require a treatment service to be matched and this appointment has no treatment service",
  },
  CARE_EPISODES_MANAGED_EXTERNALLY: {
    title: "CARE_EPISODES_MANAGED_EXTERNALLY",
    description: "Mirah is receiving episodes of care from the EHR and assumes that these should be chosen",
  },
  EXACT_MATCH_ORGANIZATION: {
    title: "EXACT_MATCH_ORGANIZATION",
    description: "There is exactly one episode of care in the same organizations as the provider",
  },
  MATCHED_TREATMENT_SERVICE: {
    title: "MATCHED_TREATMENT_SERVICE",
    description: "An active episode of care with a matching treatment service was found",
  },
  MOST_RECENT_MATCH_ORGANIZATION: {
    title: "MOST_RECENT_MATCH_ORGANIZATION",
    description: "There are multiple episodes of care in the same organization as the provider,
the one with the most recent measurement has been chosen.",
  },
  NO_MATCH_DEFAULT: {
    title: "NO_MATCH_DEFAULT",
    description: "No episodes of care matched the exact criteria, so the most recent active episode of care was chosen",
  },
  NO_MATCH_FOUND: {
    title: "NO_MATCH_FOUND",
    description: "There are no episodes of care suitable to be used",
  },
  PROVIDER_ON_CARE_TEAM: {
    title: "PROVIDER_ON_CARE_TEAM",
    description: "The provider is on the care team given",
  },
  SINGLE_EPISODE_OF_CARE_ALLOWED: {
    title: "SINGLE_EPISODE_OF_CARE_ALLOWED",
    description: "The system is configured to only allow one episode of care at a time, and that episode of care will always be chosen",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function careEpisodeInferenceReasonDescriptionT(value: CareEpisodeInferenceReason, t: (t: `enums:CareEpisodeInferenceReason.${keyof typeof resources["en"]["enums"]["CareEpisodeInferenceReason"]}.description`) => string):string {
    switch (value) {
      case CareEpisodeInferenceReason.APPOINTMENT_NO_TREATMENT_SERVICE: return t("enums:CareEpisodeInferenceReason.APPOINTMENT_NO_TREATMENT_SERVICE.description");
case CareEpisodeInferenceReason.CARE_EPISODES_MANAGED_EXTERNALLY: return t("enums:CareEpisodeInferenceReason.CARE_EPISODES_MANAGED_EXTERNALLY.description");
case CareEpisodeInferenceReason.EXACT_MATCH_ORGANIZATION: return t("enums:CareEpisodeInferenceReason.EXACT_MATCH_ORGANIZATION.description");
case CareEpisodeInferenceReason.MATCHED_TREATMENT_SERVICE: return t("enums:CareEpisodeInferenceReason.MATCHED_TREATMENT_SERVICE.description");
case CareEpisodeInferenceReason.MOST_RECENT_MATCH_ORGANIZATION: return t("enums:CareEpisodeInferenceReason.MOST_RECENT_MATCH_ORGANIZATION.description");
case CareEpisodeInferenceReason.NO_MATCH_DEFAULT: return t("enums:CareEpisodeInferenceReason.NO_MATCH_DEFAULT.description");
case CareEpisodeInferenceReason.NO_MATCH_FOUND: return t("enums:CareEpisodeInferenceReason.NO_MATCH_FOUND.description");
case CareEpisodeInferenceReason.PROVIDER_ON_CARE_TEAM: return t("enums:CareEpisodeInferenceReason.PROVIDER_ON_CARE_TEAM.description");
case CareEpisodeInferenceReason.SINGLE_EPISODE_OF_CARE_ALLOWED: return t("enums:CareEpisodeInferenceReason.SINGLE_EPISODE_OF_CARE_ALLOWED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  CareEpisodeProviderRelationship: {
  ADMINISTRATOR: {
    title: "ADMINISTRATOR",
    description: "Administrator",
  },
  CARE_MANAGER: {
    title: "CARE_MANAGER",
    description: "Care manager",
  },
  CASE_MANAGER: {
    title: "CASE_MANAGER",
    description: "Case manager",
  },
  CLINICIAN: {
    title: "CLINICIAN",
    description: "Clinician",
  },
  COUNSELOR: {
    title: "COUNSELOR",
    description: "Counselor",
  },
  DIRECTOR: {
    title: "DIRECTOR",
    description: "Director",
  },
  EXECUTIVE: {
    title: "EXECUTIVE",
    description: "Executive",
  },
  FRONT_DESK: {
    title: "FRONT_DESK",
    description: "Front Desk",
  },
  PRIMARY_CARE_PHYSICIAN: {
    title: "PRIMARY_CARE_PHYSICIAN",
    description: "Primary care physician",
  },
  PSYCHIATRIST: {
    title: "PSYCHIATRIST",
    description: "Psychiatrist",
  },
  PSYCHOLOGIST: {
    title: "PSYCHOLOGIST",
    description: "Psychologist",
  },
  RESEARCH_ASSISTANT: {
    title: "RESEARCH_ASSISTANT",
    description: "Research assistant",
  },
  SOCIAL_WORKER: {
    title: "SOCIAL_WORKER",
    description: "Social_worker",
  },
  SUPERVISOR: {
    title: "SUPERVISOR",
    description: "Supervisor",
  },
  THERAPIST: {
    title: "THERAPIST",
    description: "Therapist",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function careEpisodeProviderRelationshipT(value: CareEpisodeProviderRelationship, t: (t: `enums:CareEpisodeProviderRelationship.${keyof typeof resources["en"]["enums"]["CareEpisodeProviderRelationship"]}.title`) => string):string {
    switch (value) {
      case CareEpisodeProviderRelationship.ADMINISTRATOR: return t("enums:CareEpisodeProviderRelationship.ADMINISTRATOR.title");
case CareEpisodeProviderRelationship.CARE_MANAGER: return t("enums:CareEpisodeProviderRelationship.CARE_MANAGER.title");
case CareEpisodeProviderRelationship.CASE_MANAGER: return t("enums:CareEpisodeProviderRelationship.CASE_MANAGER.title");
case CareEpisodeProviderRelationship.CLINICIAN: return t("enums:CareEpisodeProviderRelationship.CLINICIAN.title");
case CareEpisodeProviderRelationship.COUNSELOR: return t("enums:CareEpisodeProviderRelationship.COUNSELOR.title");
case CareEpisodeProviderRelationship.DIRECTOR: return t("enums:CareEpisodeProviderRelationship.DIRECTOR.title");
case CareEpisodeProviderRelationship.EXECUTIVE: return t("enums:CareEpisodeProviderRelationship.EXECUTIVE.title");
case CareEpisodeProviderRelationship.FRONT_DESK: return t("enums:CareEpisodeProviderRelationship.FRONT_DESK.title");
case CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN: return t("enums:CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN.title");
case CareEpisodeProviderRelationship.PSYCHIATRIST: return t("enums:CareEpisodeProviderRelationship.PSYCHIATRIST.title");
case CareEpisodeProviderRelationship.PSYCHOLOGIST: return t("enums:CareEpisodeProviderRelationship.PSYCHOLOGIST.title");
case CareEpisodeProviderRelationship.RESEARCH_ASSISTANT: return t("enums:CareEpisodeProviderRelationship.RESEARCH_ASSISTANT.title");
case CareEpisodeProviderRelationship.SOCIAL_WORKER: return t("enums:CareEpisodeProviderRelationship.SOCIAL_WORKER.title");
case CareEpisodeProviderRelationship.SUPERVISOR: return t("enums:CareEpisodeProviderRelationship.SUPERVISOR.title");
case CareEpisodeProviderRelationship.THERAPIST: return t("enums:CareEpisodeProviderRelationship.THERAPIST.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  CareEpisodeProviderRelationship: {
  ADMINISTRATOR: {
    title: "ADMINISTRATOR",
    description: "Administrator",
  },
  CARE_MANAGER: {
    title: "CARE_MANAGER",
    description: "Care manager",
  },
  CASE_MANAGER: {
    title: "CASE_MANAGER",
    description: "Case manager",
  },
  CLINICIAN: {
    title: "CLINICIAN",
    description: "Clinician",
  },
  COUNSELOR: {
    title: "COUNSELOR",
    description: "Counselor",
  },
  DIRECTOR: {
    title: "DIRECTOR",
    description: "Director",
  },
  EXECUTIVE: {
    title: "EXECUTIVE",
    description: "Executive",
  },
  FRONT_DESK: {
    title: "FRONT_DESK",
    description: "Front Desk",
  },
  PRIMARY_CARE_PHYSICIAN: {
    title: "PRIMARY_CARE_PHYSICIAN",
    description: "Primary care physician",
  },
  PSYCHIATRIST: {
    title: "PSYCHIATRIST",
    description: "Psychiatrist",
  },
  PSYCHOLOGIST: {
    title: "PSYCHOLOGIST",
    description: "Psychologist",
  },
  RESEARCH_ASSISTANT: {
    title: "RESEARCH_ASSISTANT",
    description: "Research assistant",
  },
  SOCIAL_WORKER: {
    title: "SOCIAL_WORKER",
    description: "Social_worker",
  },
  SUPERVISOR: {
    title: "SUPERVISOR",
    description: "Supervisor",
  },
  THERAPIST: {
    title: "THERAPIST",
    description: "Therapist",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function careEpisodeProviderRelationshipDescriptionT(value: CareEpisodeProviderRelationship, t: (t: `enums:CareEpisodeProviderRelationship.${keyof typeof resources["en"]["enums"]["CareEpisodeProviderRelationship"]}.description`) => string):string {
    switch (value) {
      case CareEpisodeProviderRelationship.ADMINISTRATOR: return t("enums:CareEpisodeProviderRelationship.ADMINISTRATOR.description");
case CareEpisodeProviderRelationship.CARE_MANAGER: return t("enums:CareEpisodeProviderRelationship.CARE_MANAGER.description");
case CareEpisodeProviderRelationship.CASE_MANAGER: return t("enums:CareEpisodeProviderRelationship.CASE_MANAGER.description");
case CareEpisodeProviderRelationship.CLINICIAN: return t("enums:CareEpisodeProviderRelationship.CLINICIAN.description");
case CareEpisodeProviderRelationship.COUNSELOR: return t("enums:CareEpisodeProviderRelationship.COUNSELOR.description");
case CareEpisodeProviderRelationship.DIRECTOR: return t("enums:CareEpisodeProviderRelationship.DIRECTOR.description");
case CareEpisodeProviderRelationship.EXECUTIVE: return t("enums:CareEpisodeProviderRelationship.EXECUTIVE.description");
case CareEpisodeProviderRelationship.FRONT_DESK: return t("enums:CareEpisodeProviderRelationship.FRONT_DESK.description");
case CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN: return t("enums:CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN.description");
case CareEpisodeProviderRelationship.PSYCHIATRIST: return t("enums:CareEpisodeProviderRelationship.PSYCHIATRIST.description");
case CareEpisodeProviderRelationship.PSYCHOLOGIST: return t("enums:CareEpisodeProviderRelationship.PSYCHOLOGIST.description");
case CareEpisodeProviderRelationship.RESEARCH_ASSISTANT: return t("enums:CareEpisodeProviderRelationship.RESEARCH_ASSISTANT.description");
case CareEpisodeProviderRelationship.SOCIAL_WORKER: return t("enums:CareEpisodeProviderRelationship.SOCIAL_WORKER.description");
case CareEpisodeProviderRelationship.SUPERVISOR: return t("enums:CareEpisodeProviderRelationship.SUPERVISOR.description");
case CareEpisodeProviderRelationship.THERAPIST: return t("enums:CareEpisodeProviderRelationship.THERAPIST.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  CareEpisodeStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Active",
  },
  CANCELED: {
    title: "CANCELED",
    description: "Canceled - currently unused",
  },
  FINISHED: {
    title: "FINISHED",
    description: "Finished",
  },
  ON_HOLD: {
    title: "ON_HOLD",
    description: "On hold - currently unused",
  },
  PLANNED: {
    title: "PLANNED",
    description: "Planned - currently unused",
  },
  WAITLIST: {
    title: "WAITLIST",
    description: "Waitlist - currently unused",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function careEpisodeStatusT(value: CareEpisodeStatus, t: (t: `enums:CareEpisodeStatus.${keyof typeof resources["en"]["enums"]["CareEpisodeStatus"]}.title`) => string):string {
    switch (value) {
      case CareEpisodeStatus.ACTIVE: return t("enums:CareEpisodeStatus.ACTIVE.title");
case CareEpisodeStatus.CANCELED: return t("enums:CareEpisodeStatus.CANCELED.title");
case CareEpisodeStatus.FINISHED: return t("enums:CareEpisodeStatus.FINISHED.title");
case CareEpisodeStatus.ON_HOLD: return t("enums:CareEpisodeStatus.ON_HOLD.title");
case CareEpisodeStatus.PLANNED: return t("enums:CareEpisodeStatus.PLANNED.title");
case CareEpisodeStatus.WAITLIST: return t("enums:CareEpisodeStatus.WAITLIST.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  CareEpisodeStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Active",
  },
  CANCELED: {
    title: "CANCELED",
    description: "Canceled - currently unused",
  },
  FINISHED: {
    title: "FINISHED",
    description: "Finished",
  },
  ON_HOLD: {
    title: "ON_HOLD",
    description: "On hold - currently unused",
  },
  PLANNED: {
    title: "PLANNED",
    description: "Planned - currently unused",
  },
  WAITLIST: {
    title: "WAITLIST",
    description: "Waitlist - currently unused",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function careEpisodeStatusDescriptionT(value: CareEpisodeStatus, t: (t: `enums:CareEpisodeStatus.${keyof typeof resources["en"]["enums"]["CareEpisodeStatus"]}.description`) => string):string {
    switch (value) {
      case CareEpisodeStatus.ACTIVE: return t("enums:CareEpisodeStatus.ACTIVE.description");
case CareEpisodeStatus.CANCELED: return t("enums:CareEpisodeStatus.CANCELED.description");
case CareEpisodeStatus.FINISHED: return t("enums:CareEpisodeStatus.FINISHED.description");
case CareEpisodeStatus.ON_HOLD: return t("enums:CareEpisodeStatus.ON_HOLD.description");
case CareEpisodeStatus.PLANNED: return t("enums:CareEpisodeStatus.PLANNED.description");
case CareEpisodeStatus.WAITLIST: return t("enums:CareEpisodeStatus.WAITLIST.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  CareEpisodeTreatmentTrackStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Treatment track is active for this care episode",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "Treatment track is complete for this episode and no longer active",
  },
  ENTERED_IN_ERROR: {
    title: "ENTERED_IN_ERROR",
    description: "Treatment track was enrolled in error",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function careEpisodeTreatmentTrackStatusT(value: CareEpisodeTreatmentTrackStatus, t: (t: `enums:CareEpisodeTreatmentTrackStatus.${keyof typeof resources["en"]["enums"]["CareEpisodeTreatmentTrackStatus"]}.title`) => string):string {
    switch (value) {
      case CareEpisodeTreatmentTrackStatus.ACTIVE: return t("enums:CareEpisodeTreatmentTrackStatus.ACTIVE.title");
case CareEpisodeTreatmentTrackStatus.COMPLETE: return t("enums:CareEpisodeTreatmentTrackStatus.COMPLETE.title");
case CareEpisodeTreatmentTrackStatus.ENTERED_IN_ERROR: return t("enums:CareEpisodeTreatmentTrackStatus.ENTERED_IN_ERROR.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  CareEpisodeTreatmentTrackStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Treatment track is active for this care episode",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "Treatment track is complete for this episode and no longer active",
  },
  ENTERED_IN_ERROR: {
    title: "ENTERED_IN_ERROR",
    description: "Treatment track was enrolled in error",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function careEpisodeTreatmentTrackStatusDescriptionT(value: CareEpisodeTreatmentTrackStatus, t: (t: `enums:CareEpisodeTreatmentTrackStatus.${keyof typeof resources["en"]["enums"]["CareEpisodeTreatmentTrackStatus"]}.description`) => string):string {
    switch (value) {
      case CareEpisodeTreatmentTrackStatus.ACTIVE: return t("enums:CareEpisodeTreatmentTrackStatus.ACTIVE.description");
case CareEpisodeTreatmentTrackStatus.COMPLETE: return t("enums:CareEpisodeTreatmentTrackStatus.COMPLETE.description");
case CareEpisodeTreatmentTrackStatus.ENTERED_IN_ERROR: return t("enums:CareEpisodeTreatmentTrackStatus.ENTERED_IN_ERROR.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ClinicalAlertValue: {
  ALERT: {
    title: "ALERT",
    description: "The alert triggered for this value",
  },
  NOT_SUPPORTED: {
    title: "NOT_SUPPORTED",
    description: "Alerting is not supported for this CV",
  },
  NO_ALERT: {
    title: "NO_ALERT",
    description: "There was no alert for this value",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function clinicalAlertValueT(value: ClinicalAlertValue, t: (t: `enums:ClinicalAlertValue.${keyof typeof resources["en"]["enums"]["ClinicalAlertValue"]}.title`) => string):string {
    switch (value) {
      case ClinicalAlertValue.ALERT: return t("enums:ClinicalAlertValue.ALERT.title");
case ClinicalAlertValue.NOT_SUPPORTED: return t("enums:ClinicalAlertValue.NOT_SUPPORTED.title");
case ClinicalAlertValue.NO_ALERT: return t("enums:ClinicalAlertValue.NO_ALERT.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ClinicalAlertValue: {
  ALERT: {
    title: "ALERT",
    description: "The alert triggered for this value",
  },
  NOT_SUPPORTED: {
    title: "NOT_SUPPORTED",
    description: "Alerting is not supported for this CV",
  },
  NO_ALERT: {
    title: "NO_ALERT",
    description: "There was no alert for this value",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function clinicalAlertValueDescriptionT(value: ClinicalAlertValue, t: (t: `enums:ClinicalAlertValue.${keyof typeof resources["en"]["enums"]["ClinicalAlertValue"]}.description`) => string):string {
    switch (value) {
      case ClinicalAlertValue.ALERT: return t("enums:ClinicalAlertValue.ALERT.description");
case ClinicalAlertValue.NOT_SUPPORTED: return t("enums:ClinicalAlertValue.NOT_SUPPORTED.description");
case ClinicalAlertValue.NO_ALERT: return t("enums:ClinicalAlertValue.NO_ALERT.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  CocmBillingAlgorithmRuleCheck: {
  ASSESSMENT_COMPLETED: {
    title: "ASSESSMENT_COMPLETED",
    description: "Whether an assessment has been completed",
  },
  MINIMUM_MINUTES: {
    title: "MINIMUM_MINUTES",
    description: "Whether the minimum required minutes have been met",
  },
  MUST_BE_INITIAL_ENCOUNTER: {
    title: "MUST_BE_INITIAL_ENCOUNTER",
    description: "If true, this code can only be billed if no other encounters have been billed",
  },
  MUST_BE_SUBSEQUENT_ENCOUNTER: {
    title: "MUST_BE_SUBSEQUENT_ENCOUNTER",
    description: "If true, this code can only be billed if another encounter marked as initial has been billed",
  },
  NOT_BILLED_99492_OR_99493_ALREADY: {
    title: "NOT_BILLED_99492_OR_99493_ALREADY",
    description: "Whether there have already been 99492 and 99493 codes billed",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function cocmBillingAlgorithmRuleCheckT(value: CocmBillingAlgorithmRuleCheck, t: (t: `enums:CocmBillingAlgorithmRuleCheck.${keyof typeof resources["en"]["enums"]["CocmBillingAlgorithmRuleCheck"]}.title`) => string):string {
    switch (value) {
      case CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED: return t("enums:CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED.title");
case CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES: return t("enums:CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES.title");
case CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER: return t("enums:CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER.title");
case CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER: return t("enums:CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER.title");
case CocmBillingAlgorithmRuleCheck.NOT_BILLED_99492_OR_99493_ALREADY: return t("enums:CocmBillingAlgorithmRuleCheck.NOT_BILLED_99492_OR_99493_ALREADY.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  CocmBillingAlgorithmRuleCheck: {
  ASSESSMENT_COMPLETED: {
    title: "ASSESSMENT_COMPLETED",
    description: "Whether an assessment has been completed",
  },
  MINIMUM_MINUTES: {
    title: "MINIMUM_MINUTES",
    description: "Whether the minimum required minutes have been met",
  },
  MUST_BE_INITIAL_ENCOUNTER: {
    title: "MUST_BE_INITIAL_ENCOUNTER",
    description: "If true, this code can only be billed if no other encounters have been billed",
  },
  MUST_BE_SUBSEQUENT_ENCOUNTER: {
    title: "MUST_BE_SUBSEQUENT_ENCOUNTER",
    description: "If true, this code can only be billed if another encounter marked as initial has been billed",
  },
  NOT_BILLED_99492_OR_99493_ALREADY: {
    title: "NOT_BILLED_99492_OR_99493_ALREADY",
    description: "Whether there have already been 99492 and 99493 codes billed",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function cocmBillingAlgorithmRuleCheckDescriptionT(value: CocmBillingAlgorithmRuleCheck, t: (t: `enums:CocmBillingAlgorithmRuleCheck.${keyof typeof resources["en"]["enums"]["CocmBillingAlgorithmRuleCheck"]}.description`) => string):string {
    switch (value) {
      case CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED: return t("enums:CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED.description");
case CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES: return t("enums:CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES.description");
case CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER: return t("enums:CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER.description");
case CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER: return t("enums:CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER.description");
case CocmBillingAlgorithmRuleCheck.NOT_BILLED_99492_OR_99493_ALREADY: return t("enums:CocmBillingAlgorithmRuleCheck.NOT_BILLED_99492_OR_99493_ALREADY.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  CocmBillingAlgorithmRuleCheckValue: {
  BILLABLE_MINUTES: {
    title: "BILLABLE_MINUTES",
    description: "Measure billable minutes",
  },
  ESTIMATED_RATE: {
    title: "ESTIMATED_RATE",
    description: "Measure billing success on estimated dollars received",
  },
  RVUS: {
    title: "RVUS",
    description: "Measure billing success on RVUs",
  },
  VALUE_UNITS: {
    title: "VALUE_UNITS",
    description: "Measure billing success on Care Units",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function cocmBillingAlgorithmRuleCheckValueT(value: CocmBillingAlgorithmRuleCheckValue, t: (t: `enums:CocmBillingAlgorithmRuleCheckValue.${keyof typeof resources["en"]["enums"]["CocmBillingAlgorithmRuleCheckValue"]}.title`) => string):string {
    switch (value) {
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES: return t("enums:CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES.title");
case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE: return t("enums:CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE.title");
case CocmBillingAlgorithmRuleCheckValue.RVUS: return t("enums:CocmBillingAlgorithmRuleCheckValue.RVUS.title");
case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS: return t("enums:CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  CocmBillingAlgorithmRuleCheckValue: {
  BILLABLE_MINUTES: {
    title: "BILLABLE_MINUTES",
    description: "Measure billable minutes",
  },
  ESTIMATED_RATE: {
    title: "ESTIMATED_RATE",
    description: "Measure billing success on estimated dollars received",
  },
  RVUS: {
    title: "RVUS",
    description: "Measure billing success on RVUs",
  },
  VALUE_UNITS: {
    title: "VALUE_UNITS",
    description: "Measure billing success on Care Units",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function cocmBillingAlgorithmRuleCheckValueDescriptionT(value: CocmBillingAlgorithmRuleCheckValue, t: (t: `enums:CocmBillingAlgorithmRuleCheckValue.${keyof typeof resources["en"]["enums"]["CocmBillingAlgorithmRuleCheckValue"]}.description`) => string):string {
    switch (value) {
      case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES: return t("enums:CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES.description");
case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE: return t("enums:CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE.description");
case CocmBillingAlgorithmRuleCheckValue.RVUS: return t("enums:CocmBillingAlgorithmRuleCheckValue.RVUS.description");
case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS: return t("enums:CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  CocmBillingAlgorithmStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Algorithm is active. Charges will be generated",
  },
  RETIRED: {
    title: "RETIRED",
    description: "Algorithm is retired and will not be used",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function cocmBillingAlgorithmStatusT(value: CocmBillingAlgorithmStatus, t: (t: `enums:CocmBillingAlgorithmStatus.${keyof typeof resources["en"]["enums"]["CocmBillingAlgorithmStatus"]}.title`) => string):string {
    switch (value) {
      case CocmBillingAlgorithmStatus.ACTIVE: return t("enums:CocmBillingAlgorithmStatus.ACTIVE.title");
case CocmBillingAlgorithmStatus.RETIRED: return t("enums:CocmBillingAlgorithmStatus.RETIRED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  CocmBillingAlgorithmStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Algorithm is active. Charges will be generated",
  },
  RETIRED: {
    title: "RETIRED",
    description: "Algorithm is retired and will not be used",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function cocmBillingAlgorithmStatusDescriptionT(value: CocmBillingAlgorithmStatus, t: (t: `enums:CocmBillingAlgorithmStatus.${keyof typeof resources["en"]["enums"]["CocmBillingAlgorithmStatus"]}.description`) => string):string {
    switch (value) {
      case CocmBillingAlgorithmStatus.ACTIVE: return t("enums:CocmBillingAlgorithmStatus.ACTIVE.description");
case CocmBillingAlgorithmStatus.RETIRED: return t("enums:CocmBillingAlgorithmStatus.RETIRED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ConsultMeetingStatus: {
  ENDED: {
    title: "ENDED",
    description: "THis meeting has ended",
  },
  ONGOING: {
    title: "ONGOING",
    description: "This meeting is currently happening",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function consultMeetingStatusT(value: ConsultMeetingStatus, t: (t: `enums:ConsultMeetingStatus.${keyof typeof resources["en"]["enums"]["ConsultMeetingStatus"]}.title`) => string):string {
    switch (value) {
      case ConsultMeetingStatus.ENDED: return t("enums:ConsultMeetingStatus.ENDED.title");
case ConsultMeetingStatus.ONGOING: return t("enums:ConsultMeetingStatus.ONGOING.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ConsultMeetingStatus: {
  ENDED: {
    title: "ENDED",
    description: "THis meeting has ended",
  },
  ONGOING: {
    title: "ONGOING",
    description: "This meeting is currently happening",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function consultMeetingStatusDescriptionT(value: ConsultMeetingStatus, t: (t: `enums:ConsultMeetingStatus.${keyof typeof resources["en"]["enums"]["ConsultMeetingStatus"]}.description`) => string):string {
    switch (value) {
      case ConsultMeetingStatus.ENDED: return t("enums:ConsultMeetingStatus.ENDED.description");
case ConsultMeetingStatus.ONGOING: return t("enums:ConsultMeetingStatus.ONGOING.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ConsultRequestReason: {
  CHANGE_OF_TREATMENT_PLAN: {
    title: "CHANGE_OF_TREATMENT_PLAN",
    description: "Patient has changed to a new course of treatment",
  },
  DIAGNOSTIC_CLARITY: {
    title: "DIAGNOSTIC_CLARITY",
    description: "Providers need help assessing a case",
  },
  DISCHARGE: {
    title: "DISCHARGE",
    description: "Patient may be ready for discharge",
  },
  INCREASED_MEASURE_SEVERITY: {
    title: "INCREASED_MEASURE_SEVERITY",
    description: "Severity of patient's measures has increased recently",
  },
  LACK_OF_CLINICAL_IMPROVEMENT: {
    title: "LACK_OF_CLINICAL_IMPROVEMENT",
    description: "Patient not showing improvement after treatment",
  },
  MEDICAL_COMPLICATIONS: {
    title: "MEDICAL_COMPLICATIONS",
    description: "Patient is experiencing medical complications",
  },
  MEDICATION_MANAGEMENT_QUESTION: {
    title: "MEDICATION_MANAGEMENT_QUESTION",
    description: "Providers need help managing medication for this patient",
  },
  MEDICATION_SIDE_EFFECT: {
    title: "MEDICATION_SIDE_EFFECT",
    description: "Patient is experiencing a side effect from medication",
  },
  NEW_INFORMATION: {
    title: "NEW_INFORMATION",
    description: "New information about a patient may change diagnosis or treatment",
  },
  OTHER: {
    title: "OTHER",
    description: "Reasons not captured in other options",
  },
  QUICK_WIN: {
    title: "QUICK_WIN",
    description: "Good news about a patient for team morale",
  },
  SDOH: {
    title: "SDOH",
    description: "SDOH factors my change a patient's diagnosis or treatment",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function consultRequestReasonT(value: ConsultRequestReason, t: (t: `enums:ConsultRequestReason.${keyof typeof resources["en"]["enums"]["ConsultRequestReason"]}.title`) => string):string {
    switch (value) {
      case ConsultRequestReason.CHANGE_OF_TREATMENT_PLAN: return t("enums:ConsultRequestReason.CHANGE_OF_TREATMENT_PLAN.title");
case ConsultRequestReason.DIAGNOSTIC_CLARITY: return t("enums:ConsultRequestReason.DIAGNOSTIC_CLARITY.title");
case ConsultRequestReason.DISCHARGE: return t("enums:ConsultRequestReason.DISCHARGE.title");
case ConsultRequestReason.INCREASED_MEASURE_SEVERITY: return t("enums:ConsultRequestReason.INCREASED_MEASURE_SEVERITY.title");
case ConsultRequestReason.LACK_OF_CLINICAL_IMPROVEMENT: return t("enums:ConsultRequestReason.LACK_OF_CLINICAL_IMPROVEMENT.title");
case ConsultRequestReason.MEDICAL_COMPLICATIONS: return t("enums:ConsultRequestReason.MEDICAL_COMPLICATIONS.title");
case ConsultRequestReason.MEDICATION_MANAGEMENT_QUESTION: return t("enums:ConsultRequestReason.MEDICATION_MANAGEMENT_QUESTION.title");
case ConsultRequestReason.MEDICATION_SIDE_EFFECT: return t("enums:ConsultRequestReason.MEDICATION_SIDE_EFFECT.title");
case ConsultRequestReason.NEW_INFORMATION: return t("enums:ConsultRequestReason.NEW_INFORMATION.title");
case ConsultRequestReason.OTHER: return t("enums:ConsultRequestReason.OTHER.title");
case ConsultRequestReason.QUICK_WIN: return t("enums:ConsultRequestReason.QUICK_WIN.title");
case ConsultRequestReason.SDOH: return t("enums:ConsultRequestReason.SDOH.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ConsultRequestReason: {
  CHANGE_OF_TREATMENT_PLAN: {
    title: "CHANGE_OF_TREATMENT_PLAN",
    description: "Patient has changed to a new course of treatment",
  },
  DIAGNOSTIC_CLARITY: {
    title: "DIAGNOSTIC_CLARITY",
    description: "Providers need help assessing a case",
  },
  DISCHARGE: {
    title: "DISCHARGE",
    description: "Patient may be ready for discharge",
  },
  INCREASED_MEASURE_SEVERITY: {
    title: "INCREASED_MEASURE_SEVERITY",
    description: "Severity of patient's measures has increased recently",
  },
  LACK_OF_CLINICAL_IMPROVEMENT: {
    title: "LACK_OF_CLINICAL_IMPROVEMENT",
    description: "Patient not showing improvement after treatment",
  },
  MEDICAL_COMPLICATIONS: {
    title: "MEDICAL_COMPLICATIONS",
    description: "Patient is experiencing medical complications",
  },
  MEDICATION_MANAGEMENT_QUESTION: {
    title: "MEDICATION_MANAGEMENT_QUESTION",
    description: "Providers need help managing medication for this patient",
  },
  MEDICATION_SIDE_EFFECT: {
    title: "MEDICATION_SIDE_EFFECT",
    description: "Patient is experiencing a side effect from medication",
  },
  NEW_INFORMATION: {
    title: "NEW_INFORMATION",
    description: "New information about a patient may change diagnosis or treatment",
  },
  OTHER: {
    title: "OTHER",
    description: "Reasons not captured in other options",
  },
  QUICK_WIN: {
    title: "QUICK_WIN",
    description: "Good news about a patient for team morale",
  },
  SDOH: {
    title: "SDOH",
    description: "SDOH factors my change a patient's diagnosis or treatment",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function consultRequestReasonDescriptionT(value: ConsultRequestReason, t: (t: `enums:ConsultRequestReason.${keyof typeof resources["en"]["enums"]["ConsultRequestReason"]}.description`) => string):string {
    switch (value) {
      case ConsultRequestReason.CHANGE_OF_TREATMENT_PLAN: return t("enums:ConsultRequestReason.CHANGE_OF_TREATMENT_PLAN.description");
case ConsultRequestReason.DIAGNOSTIC_CLARITY: return t("enums:ConsultRequestReason.DIAGNOSTIC_CLARITY.description");
case ConsultRequestReason.DISCHARGE: return t("enums:ConsultRequestReason.DISCHARGE.description");
case ConsultRequestReason.INCREASED_MEASURE_SEVERITY: return t("enums:ConsultRequestReason.INCREASED_MEASURE_SEVERITY.description");
case ConsultRequestReason.LACK_OF_CLINICAL_IMPROVEMENT: return t("enums:ConsultRequestReason.LACK_OF_CLINICAL_IMPROVEMENT.description");
case ConsultRequestReason.MEDICAL_COMPLICATIONS: return t("enums:ConsultRequestReason.MEDICAL_COMPLICATIONS.description");
case ConsultRequestReason.MEDICATION_MANAGEMENT_QUESTION: return t("enums:ConsultRequestReason.MEDICATION_MANAGEMENT_QUESTION.description");
case ConsultRequestReason.MEDICATION_SIDE_EFFECT: return t("enums:ConsultRequestReason.MEDICATION_SIDE_EFFECT.description");
case ConsultRequestReason.NEW_INFORMATION: return t("enums:ConsultRequestReason.NEW_INFORMATION.description");
case ConsultRequestReason.OTHER: return t("enums:ConsultRequestReason.OTHER.description");
case ConsultRequestReason.QUICK_WIN: return t("enums:ConsultRequestReason.QUICK_WIN.description");
case ConsultRequestReason.SDOH: return t("enums:ConsultRequestReason.SDOH.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  CoverageRelationship: {
  CHILD: {
    title: "CHILD",
    description: "The Beneficiary is a child of the Subscriber",
  },
  COMMON: {
    title: "COMMON",
    description: "The Beneficiary is a common law spouse of the Subscriber",
  },
  INJURY: {
    title: "INJURY",
    description: "The Beneficiary is covered under insurance of the Subscriber due to an injury.",
  },
  NONE: {
    title: "NONE",
    description: "The Beneficiary has no relationship to the Subscriber",
  },
  OTHER: {
    title: "OTHER",
    description: "The Beneficiary has some other relationship to the Subscriber",
  },
  PARENT: {
    title: "PARENT",
    description: "The Beneficiary is a parent of the Subscriber",
  },
  SELF: {
    title: "SELF",
    description: "The Beneficiary is the Subscriber",
  },
  SPOUSE: {
    title: "SPOUSE",
    description: "The Beneficiary is the spouse of the Subscriber",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function coverageRelationshipT(value: CoverageRelationship, t: (t: `enums:CoverageRelationship.${keyof typeof resources["en"]["enums"]["CoverageRelationship"]}.title`) => string):string {
    switch (value) {
      case CoverageRelationship.CHILD: return t("enums:CoverageRelationship.CHILD.title");
case CoverageRelationship.COMMON: return t("enums:CoverageRelationship.COMMON.title");
case CoverageRelationship.INJURY: return t("enums:CoverageRelationship.INJURY.title");
case CoverageRelationship.NONE: return t("enums:CoverageRelationship.NONE.title");
case CoverageRelationship.OTHER: return t("enums:CoverageRelationship.OTHER.title");
case CoverageRelationship.PARENT: return t("enums:CoverageRelationship.PARENT.title");
case CoverageRelationship.SELF: return t("enums:CoverageRelationship.SELF.title");
case CoverageRelationship.SPOUSE: return t("enums:CoverageRelationship.SPOUSE.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  CoverageRelationship: {
  CHILD: {
    title: "CHILD",
    description: "The Beneficiary is a child of the Subscriber",
  },
  COMMON: {
    title: "COMMON",
    description: "The Beneficiary is a common law spouse of the Subscriber",
  },
  INJURY: {
    title: "INJURY",
    description: "The Beneficiary is covered under insurance of the Subscriber due to an injury.",
  },
  NONE: {
    title: "NONE",
    description: "The Beneficiary has no relationship to the Subscriber",
  },
  OTHER: {
    title: "OTHER",
    description: "The Beneficiary has some other relationship to the Subscriber",
  },
  PARENT: {
    title: "PARENT",
    description: "The Beneficiary is a parent of the Subscriber",
  },
  SELF: {
    title: "SELF",
    description: "The Beneficiary is the Subscriber",
  },
  SPOUSE: {
    title: "SPOUSE",
    description: "The Beneficiary is the spouse of the Subscriber",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function coverageRelationshipDescriptionT(value: CoverageRelationship, t: (t: `enums:CoverageRelationship.${keyof typeof resources["en"]["enums"]["CoverageRelationship"]}.description`) => string):string {
    switch (value) {
      case CoverageRelationship.CHILD: return t("enums:CoverageRelationship.CHILD.description");
case CoverageRelationship.COMMON: return t("enums:CoverageRelationship.COMMON.description");
case CoverageRelationship.INJURY: return t("enums:CoverageRelationship.INJURY.description");
case CoverageRelationship.NONE: return t("enums:CoverageRelationship.NONE.description");
case CoverageRelationship.OTHER: return t("enums:CoverageRelationship.OTHER.description");
case CoverageRelationship.PARENT: return t("enums:CoverageRelationship.PARENT.description");
case CoverageRelationship.SELF: return t("enums:CoverageRelationship.SELF.description");
case CoverageRelationship.SPOUSE: return t("enums:CoverageRelationship.SPOUSE.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  DataDumpResource: {
  ANON_RESEARCH_SET: {
    title: "ANON_RESEARCH_SET",
    description: "Exports anon research set",
  },
  ANSWER: {
    title: "ANSWER",
    description: "Exports answer",
  },
  APPOINTMENT: {
    title: "APPOINTMENT",
    description: "Exports appointment",
  },
  COLLABORATIVE_CARE_CASE_CONSULT: {
    title: "COLLABORATIVE_CARE_CASE_CONSULT",
    description: "Collaborative care case consultation",
  },
  COLLABORATIVE_CARE_CONSULT_MEETING: {
    title: "COLLABORATIVE_CARE_CONSULT_MEETING",
    description: "Collaborative care case consultation meeting",
  },
  COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE: {
    title: "COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE",
    description: "Collaborative care case consultation meeting attendee",
  },
  COLLABORATIVE_CARE_CONSULT_REQUEST: {
    title: "COLLABORATIVE_CARE_CONSULT_REQUEST",
    description: "Collaborative care case consultation request",
  },
  COLLABORATIVE_CARE_ENROLLMENTS: {
    title: "COLLABORATIVE_CARE_ENROLLMENTS",
    description: "Collaborative care enrollments",
  },
  COLLABORATIVE_CARE_NOTE: {
    title: "COLLABORATIVE_CARE_NOTE",
    description: "Collaborative care task note",
  },
  COLLABORATIVE_CARE_PANEL: {
    title: "COLLABORATIVE_CARE_PANEL",
    description: "Collaborative care panel",
  },
  COLLABORATIVE_CARE_PANEL_TEAM_MEMBER: {
    title: "COLLABORATIVE_CARE_PANEL_TEAM_MEMBER",
    description: "Collaborative care panel team member",
  },
  COLLABORATIVE_CARE_TASK: {
    title: "COLLABORATIVE_CARE_TASK",
    description: "Collaborative care tasks",
  },
  COLLABORATIVE_CARE_TIME_ENTRY_LOG: {
    title: "COLLABORATIVE_CARE_TIME_ENTRY_LOG",
    description: "Collaborative care time entry log",
  },
  CONTACT_DETAILS_QUALITY: {
    title: "CONTACT_DETAILS_QUALITY",
    description: "Exports contact details quality",
  },
  COVERAGE: {
    title: "COVERAGE",
    description: "Exports coverage",
  },
  DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM: {
    title: "DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM",
    description: "Decision Support Algorithms",
  },
  DECISION_SUPPORT_PROVIDER_NOTIFICATION: {
    title: "DECISION_SUPPORT_PROVIDER_NOTIFICATION",
    description: "Decision Support Provider Notifications",
  },
  DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM: {
    title: "DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM",
    description: "Decision Support Triggered Algorithms",
  },
  DECISION_SUPPORT_TRIGGERED_WORKFLOW: {
    title: "DECISION_SUPPORT_TRIGGERED_WORKFLOW",
    description: "Decision Support Triggered Workflows",
  },
  DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION: {
    title: "DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION",
    description: "Decision Support Triggered Actions",
  },
  DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG: {
    title: "DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG",
    description: "Decision Support Triggered Action Logs",
  },
  DECISION_SUPPORT_WORKFLOW: {
    title: "DECISION_SUPPORT_WORKFLOW",
    description: "Decision Support Workflows",
  },
  DECISION_SUPPORT_WORKFLOW_ACTION: {
    title: "DECISION_SUPPORT_WORKFLOW_ACTION",
    description: "Decision Support Actions",
  },
  DIAGNOSTIC_REPORT: {
    title: "DIAGNOSTIC_REPORT",
    description: "Exports diagnostic report",
  },
  ENCOUNTER: {
    title: "ENCOUNTER",
    description: "Exports encounter",
  },
  EPISODE_OF_CARE: {
    title: "EPISODE_OF_CARE",
    description: "Exports episode of care",
  },
  GOAL: {
    title: "GOAL",
    description: "Patient Goal",
  },
  GOAL_ANSWER: {
    title: "GOAL_ANSWER",
    description: "Patient Goal Answers",
  },
  OBSERVATION: {
    title: "OBSERVATION",
    description: "Exports observation",
  },
  ORGANIZATION: {
    title: "ORGANIZATION",
    description: "Exports organization",
  },
  OUTCOMES: {
    title: "OUTCOMES",
    description: "Exports a specific outcomes format",
  },
  PATIENT: {
    title: "PATIENT",
    description: "Exports patient",
  },
  PRACTITIONER: {
    title: "PRACTITIONER",
    description: "Exports practitioner",
  },
  QUESTION: {
    title: "QUESTION",
    description: "Exports question",
  },
  QUESTIONNAIRE: {
    title: "QUESTIONNAIRE",
    description: "Exports questionnaire",
  },
  QUESTIONNAIRE_RESPONSE: {
    title: "QUESTIONNAIRE_RESPONSE",
    description: "Exports questionnaire",
  },
  REIMBURSEMENT_LIST: {
    title: "REIMBURSEMENT_LIST",
    description: "Exports reimbursement list",
  },
  RELATED_PERSON: {
    title: "RELATED_PERSON",
    description: "Exports related person",
  },
  REPORT_VIEW: {
    title: "REPORT_VIEW",
    description: "Exporters provider report views",
  },
  RESEARCH_EXPERIMENT: {
    title: "RESEARCH_EXPERIMENT",
    description: "Research Experiment Exporter",
  },
  RESEARCH_PATIENT_SET: {
    title: "RESEARCH_PATIENT_SET",
    description: "Exports research patient set",
  },
  RESEARCH_SET: {
    title: "RESEARCH_SET",
    description: "Exports research set",
  },
  USER_CONSENT: {
    title: "USER_CONSENT",
    description: "Exports user consent",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dataDumpResourceT(value: DataDumpResource, t: (t: `enums:DataDumpResource.${keyof typeof resources["en"]["enums"]["DataDumpResource"]}.title`) => string):string {
    switch (value) {
      case DataDumpResource.ANON_RESEARCH_SET: return t("enums:DataDumpResource.ANON_RESEARCH_SET.title");
case DataDumpResource.ANSWER: return t("enums:DataDumpResource.ANSWER.title");
case DataDumpResource.APPOINTMENT: return t("enums:DataDumpResource.APPOINTMENT.title");
case DataDumpResource.COLLABORATIVE_CARE_CASE_CONSULT: return t("enums:DataDumpResource.COLLABORATIVE_CARE_CASE_CONSULT.title");
case DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING: return t("enums:DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING.title");
case DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE: return t("enums:DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE.title");
case DataDumpResource.COLLABORATIVE_CARE_CONSULT_REQUEST: return t("enums:DataDumpResource.COLLABORATIVE_CARE_CONSULT_REQUEST.title");
case DataDumpResource.COLLABORATIVE_CARE_ENROLLMENTS: return t("enums:DataDumpResource.COLLABORATIVE_CARE_ENROLLMENTS.title");
case DataDumpResource.COLLABORATIVE_CARE_NOTE: return t("enums:DataDumpResource.COLLABORATIVE_CARE_NOTE.title");
case DataDumpResource.COLLABORATIVE_CARE_PANEL: return t("enums:DataDumpResource.COLLABORATIVE_CARE_PANEL.title");
case DataDumpResource.COLLABORATIVE_CARE_PANEL_TEAM_MEMBER: return t("enums:DataDumpResource.COLLABORATIVE_CARE_PANEL_TEAM_MEMBER.title");
case DataDumpResource.COLLABORATIVE_CARE_TASK: return t("enums:DataDumpResource.COLLABORATIVE_CARE_TASK.title");
case DataDumpResource.COLLABORATIVE_CARE_TIME_ENTRY_LOG: return t("enums:DataDumpResource.COLLABORATIVE_CARE_TIME_ENTRY_LOG.title");
case DataDumpResource.CONTACT_DETAILS_QUALITY: return t("enums:DataDumpResource.CONTACT_DETAILS_QUALITY.title");
case DataDumpResource.COVERAGE: return t("enums:DataDumpResource.COVERAGE.title");
case DataDumpResource.DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM: return t("enums:DataDumpResource.DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM.title");
case DataDumpResource.DECISION_SUPPORT_PROVIDER_NOTIFICATION: return t("enums:DataDumpResource.DECISION_SUPPORT_PROVIDER_NOTIFICATION.title");
case DataDumpResource.DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM: return t("enums:DataDumpResource.DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM.title");
case DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW: return t("enums:DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW.title");
case DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION: return t("enums:DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION.title");
case DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG: return t("enums:DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG.title");
case DataDumpResource.DECISION_SUPPORT_WORKFLOW: return t("enums:DataDumpResource.DECISION_SUPPORT_WORKFLOW.title");
case DataDumpResource.DECISION_SUPPORT_WORKFLOW_ACTION: return t("enums:DataDumpResource.DECISION_SUPPORT_WORKFLOW_ACTION.title");
case DataDumpResource.DIAGNOSTIC_REPORT: return t("enums:DataDumpResource.DIAGNOSTIC_REPORT.title");
case DataDumpResource.ENCOUNTER: return t("enums:DataDumpResource.ENCOUNTER.title");
case DataDumpResource.EPISODE_OF_CARE: return t("enums:DataDumpResource.EPISODE_OF_CARE.title");
case DataDumpResource.GOAL: return t("enums:DataDumpResource.GOAL.title");
case DataDumpResource.GOAL_ANSWER: return t("enums:DataDumpResource.GOAL_ANSWER.title");
case DataDumpResource.OBSERVATION: return t("enums:DataDumpResource.OBSERVATION.title");
case DataDumpResource.ORGANIZATION: return t("enums:DataDumpResource.ORGANIZATION.title");
case DataDumpResource.OUTCOMES: return t("enums:DataDumpResource.OUTCOMES.title");
case DataDumpResource.PATIENT: return t("enums:DataDumpResource.PATIENT.title");
case DataDumpResource.PRACTITIONER: return t("enums:DataDumpResource.PRACTITIONER.title");
case DataDumpResource.QUESTION: return t("enums:DataDumpResource.QUESTION.title");
case DataDumpResource.QUESTIONNAIRE: return t("enums:DataDumpResource.QUESTIONNAIRE.title");
case DataDumpResource.QUESTIONNAIRE_RESPONSE: return t("enums:DataDumpResource.QUESTIONNAIRE_RESPONSE.title");
case DataDumpResource.REIMBURSEMENT_LIST: return t("enums:DataDumpResource.REIMBURSEMENT_LIST.title");
case DataDumpResource.RELATED_PERSON: return t("enums:DataDumpResource.RELATED_PERSON.title");
case DataDumpResource.REPORT_VIEW: return t("enums:DataDumpResource.REPORT_VIEW.title");
case DataDumpResource.RESEARCH_EXPERIMENT: return t("enums:DataDumpResource.RESEARCH_EXPERIMENT.title");
case DataDumpResource.RESEARCH_PATIENT_SET: return t("enums:DataDumpResource.RESEARCH_PATIENT_SET.title");
case DataDumpResource.RESEARCH_SET: return t("enums:DataDumpResource.RESEARCH_SET.title");
case DataDumpResource.USER_CONSENT: return t("enums:DataDumpResource.USER_CONSENT.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  DataDumpResource: {
  ANON_RESEARCH_SET: {
    title: "ANON_RESEARCH_SET",
    description: "Exports anon research set",
  },
  ANSWER: {
    title: "ANSWER",
    description: "Exports answer",
  },
  APPOINTMENT: {
    title: "APPOINTMENT",
    description: "Exports appointment",
  },
  COLLABORATIVE_CARE_CASE_CONSULT: {
    title: "COLLABORATIVE_CARE_CASE_CONSULT",
    description: "Collaborative care case consultation",
  },
  COLLABORATIVE_CARE_CONSULT_MEETING: {
    title: "COLLABORATIVE_CARE_CONSULT_MEETING",
    description: "Collaborative care case consultation meeting",
  },
  COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE: {
    title: "COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE",
    description: "Collaborative care case consultation meeting attendee",
  },
  COLLABORATIVE_CARE_CONSULT_REQUEST: {
    title: "COLLABORATIVE_CARE_CONSULT_REQUEST",
    description: "Collaborative care case consultation request",
  },
  COLLABORATIVE_CARE_ENROLLMENTS: {
    title: "COLLABORATIVE_CARE_ENROLLMENTS",
    description: "Collaborative care enrollments",
  },
  COLLABORATIVE_CARE_NOTE: {
    title: "COLLABORATIVE_CARE_NOTE",
    description: "Collaborative care task note",
  },
  COLLABORATIVE_CARE_PANEL: {
    title: "COLLABORATIVE_CARE_PANEL",
    description: "Collaborative care panel",
  },
  COLLABORATIVE_CARE_PANEL_TEAM_MEMBER: {
    title: "COLLABORATIVE_CARE_PANEL_TEAM_MEMBER",
    description: "Collaborative care panel team member",
  },
  COLLABORATIVE_CARE_TASK: {
    title: "COLLABORATIVE_CARE_TASK",
    description: "Collaborative care tasks",
  },
  COLLABORATIVE_CARE_TIME_ENTRY_LOG: {
    title: "COLLABORATIVE_CARE_TIME_ENTRY_LOG",
    description: "Collaborative care time entry log",
  },
  CONTACT_DETAILS_QUALITY: {
    title: "CONTACT_DETAILS_QUALITY",
    description: "Exports contact details quality",
  },
  COVERAGE: {
    title: "COVERAGE",
    description: "Exports coverage",
  },
  DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM: {
    title: "DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM",
    description: "Decision Support Algorithms",
  },
  DECISION_SUPPORT_PROVIDER_NOTIFICATION: {
    title: "DECISION_SUPPORT_PROVIDER_NOTIFICATION",
    description: "Decision Support Provider Notifications",
  },
  DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM: {
    title: "DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM",
    description: "Decision Support Triggered Algorithms",
  },
  DECISION_SUPPORT_TRIGGERED_WORKFLOW: {
    title: "DECISION_SUPPORT_TRIGGERED_WORKFLOW",
    description: "Decision Support Triggered Workflows",
  },
  DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION: {
    title: "DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION",
    description: "Decision Support Triggered Actions",
  },
  DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG: {
    title: "DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG",
    description: "Decision Support Triggered Action Logs",
  },
  DECISION_SUPPORT_WORKFLOW: {
    title: "DECISION_SUPPORT_WORKFLOW",
    description: "Decision Support Workflows",
  },
  DECISION_SUPPORT_WORKFLOW_ACTION: {
    title: "DECISION_SUPPORT_WORKFLOW_ACTION",
    description: "Decision Support Actions",
  },
  DIAGNOSTIC_REPORT: {
    title: "DIAGNOSTIC_REPORT",
    description: "Exports diagnostic report",
  },
  ENCOUNTER: {
    title: "ENCOUNTER",
    description: "Exports encounter",
  },
  EPISODE_OF_CARE: {
    title: "EPISODE_OF_CARE",
    description: "Exports episode of care",
  },
  GOAL: {
    title: "GOAL",
    description: "Patient Goal",
  },
  GOAL_ANSWER: {
    title: "GOAL_ANSWER",
    description: "Patient Goal Answers",
  },
  OBSERVATION: {
    title: "OBSERVATION",
    description: "Exports observation",
  },
  ORGANIZATION: {
    title: "ORGANIZATION",
    description: "Exports organization",
  },
  OUTCOMES: {
    title: "OUTCOMES",
    description: "Exports a specific outcomes format",
  },
  PATIENT: {
    title: "PATIENT",
    description: "Exports patient",
  },
  PRACTITIONER: {
    title: "PRACTITIONER",
    description: "Exports practitioner",
  },
  QUESTION: {
    title: "QUESTION",
    description: "Exports question",
  },
  QUESTIONNAIRE: {
    title: "QUESTIONNAIRE",
    description: "Exports questionnaire",
  },
  QUESTIONNAIRE_RESPONSE: {
    title: "QUESTIONNAIRE_RESPONSE",
    description: "Exports questionnaire",
  },
  REIMBURSEMENT_LIST: {
    title: "REIMBURSEMENT_LIST",
    description: "Exports reimbursement list",
  },
  RELATED_PERSON: {
    title: "RELATED_PERSON",
    description: "Exports related person",
  },
  REPORT_VIEW: {
    title: "REPORT_VIEW",
    description: "Exporters provider report views",
  },
  RESEARCH_EXPERIMENT: {
    title: "RESEARCH_EXPERIMENT",
    description: "Research Experiment Exporter",
  },
  RESEARCH_PATIENT_SET: {
    title: "RESEARCH_PATIENT_SET",
    description: "Exports research patient set",
  },
  RESEARCH_SET: {
    title: "RESEARCH_SET",
    description: "Exports research set",
  },
  USER_CONSENT: {
    title: "USER_CONSENT",
    description: "Exports user consent",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dataDumpResourceDescriptionT(value: DataDumpResource, t: (t: `enums:DataDumpResource.${keyof typeof resources["en"]["enums"]["DataDumpResource"]}.description`) => string):string {
    switch (value) {
      case DataDumpResource.ANON_RESEARCH_SET: return t("enums:DataDumpResource.ANON_RESEARCH_SET.description");
case DataDumpResource.ANSWER: return t("enums:DataDumpResource.ANSWER.description");
case DataDumpResource.APPOINTMENT: return t("enums:DataDumpResource.APPOINTMENT.description");
case DataDumpResource.COLLABORATIVE_CARE_CASE_CONSULT: return t("enums:DataDumpResource.COLLABORATIVE_CARE_CASE_CONSULT.description");
case DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING: return t("enums:DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING.description");
case DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE: return t("enums:DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE.description");
case DataDumpResource.COLLABORATIVE_CARE_CONSULT_REQUEST: return t("enums:DataDumpResource.COLLABORATIVE_CARE_CONSULT_REQUEST.description");
case DataDumpResource.COLLABORATIVE_CARE_ENROLLMENTS: return t("enums:DataDumpResource.COLLABORATIVE_CARE_ENROLLMENTS.description");
case DataDumpResource.COLLABORATIVE_CARE_NOTE: return t("enums:DataDumpResource.COLLABORATIVE_CARE_NOTE.description");
case DataDumpResource.COLLABORATIVE_CARE_PANEL: return t("enums:DataDumpResource.COLLABORATIVE_CARE_PANEL.description");
case DataDumpResource.COLLABORATIVE_CARE_PANEL_TEAM_MEMBER: return t("enums:DataDumpResource.COLLABORATIVE_CARE_PANEL_TEAM_MEMBER.description");
case DataDumpResource.COLLABORATIVE_CARE_TASK: return t("enums:DataDumpResource.COLLABORATIVE_CARE_TASK.description");
case DataDumpResource.COLLABORATIVE_CARE_TIME_ENTRY_LOG: return t("enums:DataDumpResource.COLLABORATIVE_CARE_TIME_ENTRY_LOG.description");
case DataDumpResource.CONTACT_DETAILS_QUALITY: return t("enums:DataDumpResource.CONTACT_DETAILS_QUALITY.description");
case DataDumpResource.COVERAGE: return t("enums:DataDumpResource.COVERAGE.description");
case DataDumpResource.DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM: return t("enums:DataDumpResource.DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM.description");
case DataDumpResource.DECISION_SUPPORT_PROVIDER_NOTIFICATION: return t("enums:DataDumpResource.DECISION_SUPPORT_PROVIDER_NOTIFICATION.description");
case DataDumpResource.DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM: return t("enums:DataDumpResource.DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM.description");
case DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW: return t("enums:DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW.description");
case DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION: return t("enums:DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION.description");
case DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG: return t("enums:DataDumpResource.DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG.description");
case DataDumpResource.DECISION_SUPPORT_WORKFLOW: return t("enums:DataDumpResource.DECISION_SUPPORT_WORKFLOW.description");
case DataDumpResource.DECISION_SUPPORT_WORKFLOW_ACTION: return t("enums:DataDumpResource.DECISION_SUPPORT_WORKFLOW_ACTION.description");
case DataDumpResource.DIAGNOSTIC_REPORT: return t("enums:DataDumpResource.DIAGNOSTIC_REPORT.description");
case DataDumpResource.ENCOUNTER: return t("enums:DataDumpResource.ENCOUNTER.description");
case DataDumpResource.EPISODE_OF_CARE: return t("enums:DataDumpResource.EPISODE_OF_CARE.description");
case DataDumpResource.GOAL: return t("enums:DataDumpResource.GOAL.description");
case DataDumpResource.GOAL_ANSWER: return t("enums:DataDumpResource.GOAL_ANSWER.description");
case DataDumpResource.OBSERVATION: return t("enums:DataDumpResource.OBSERVATION.description");
case DataDumpResource.ORGANIZATION: return t("enums:DataDumpResource.ORGANIZATION.description");
case DataDumpResource.OUTCOMES: return t("enums:DataDumpResource.OUTCOMES.description");
case DataDumpResource.PATIENT: return t("enums:DataDumpResource.PATIENT.description");
case DataDumpResource.PRACTITIONER: return t("enums:DataDumpResource.PRACTITIONER.description");
case DataDumpResource.QUESTION: return t("enums:DataDumpResource.QUESTION.description");
case DataDumpResource.QUESTIONNAIRE: return t("enums:DataDumpResource.QUESTIONNAIRE.description");
case DataDumpResource.QUESTIONNAIRE_RESPONSE: return t("enums:DataDumpResource.QUESTIONNAIRE_RESPONSE.description");
case DataDumpResource.REIMBURSEMENT_LIST: return t("enums:DataDumpResource.REIMBURSEMENT_LIST.description");
case DataDumpResource.RELATED_PERSON: return t("enums:DataDumpResource.RELATED_PERSON.description");
case DataDumpResource.REPORT_VIEW: return t("enums:DataDumpResource.REPORT_VIEW.description");
case DataDumpResource.RESEARCH_EXPERIMENT: return t("enums:DataDumpResource.RESEARCH_EXPERIMENT.description");
case DataDumpResource.RESEARCH_PATIENT_SET: return t("enums:DataDumpResource.RESEARCH_PATIENT_SET.description");
case DataDumpResource.RESEARCH_SET: return t("enums:DataDumpResource.RESEARCH_SET.description");
case DataDumpResource.USER_CONSENT: return t("enums:DataDumpResource.USER_CONSENT.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  DataSourceRecordAlgorithm: {
  APPOINTMENT_CARE_EPISODE_CREATION: {
    title: "APPOINTMENT_CARE_EPISODE_CREATION",
    description: "Creates a new care episode for an appointment",
  },
  APPOINTMENT_CARE_EPISODE_PROVIDER: {
    title: "APPOINTMENT_CARE_EPISODE_PROVIDER",
    description: "Adds a provider to an episode of care",
  },
  APPOINTMENT_CREATE_ATHENA_ENCOUNTER: {
    title: "APPOINTMENT_CREATE_ATHENA_ENCOUNTER",
    description: "Sets the default provider to that of this appointment",
  },
  APPOINTMENT_DEFAULT_PROVIDER: {
    title: "APPOINTMENT_DEFAULT_PROVIDER",
    description: "Sets the default provider to that of this appointment",
  },
  APPOINTMENT_PATIENT_SESSION_CREATION: {
    title: "APPOINTMENT_PATIENT_SESSION_CREATION",
    description: "Creates a new patient session for this appointment",
  },
  EXTERNAL_OBSERVATION_IMPORT: {
    title: "EXTERNAL_OBSERVATION_IMPORT",
    description: "Imports an external observation into the Mirah measurement system",
  },
  PATIENT_COVERAGE: {
    title: "PATIENT_COVERAGE",
    description: "Extracts coverage information from patient details",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dataSourceRecordAlgorithmT(value: DataSourceRecordAlgorithm, t: (t: `enums:DataSourceRecordAlgorithm.${keyof typeof resources["en"]["enums"]["DataSourceRecordAlgorithm"]}.title`) => string):string {
    switch (value) {
      case DataSourceRecordAlgorithm.APPOINTMENT_CARE_EPISODE_CREATION: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_CARE_EPISODE_CREATION.title");
case DataSourceRecordAlgorithm.APPOINTMENT_CARE_EPISODE_PROVIDER: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_CARE_EPISODE_PROVIDER.title");
case DataSourceRecordAlgorithm.APPOINTMENT_CREATE_ATHENA_ENCOUNTER: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_CREATE_ATHENA_ENCOUNTER.title");
case DataSourceRecordAlgorithm.APPOINTMENT_DEFAULT_PROVIDER: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_DEFAULT_PROVIDER.title");
case DataSourceRecordAlgorithm.APPOINTMENT_PATIENT_SESSION_CREATION: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_PATIENT_SESSION_CREATION.title");
case DataSourceRecordAlgorithm.EXTERNAL_OBSERVATION_IMPORT: return t("enums:DataSourceRecordAlgorithm.EXTERNAL_OBSERVATION_IMPORT.title");
case DataSourceRecordAlgorithm.PATIENT_COVERAGE: return t("enums:DataSourceRecordAlgorithm.PATIENT_COVERAGE.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  DataSourceRecordAlgorithm: {
  APPOINTMENT_CARE_EPISODE_CREATION: {
    title: "APPOINTMENT_CARE_EPISODE_CREATION",
    description: "Creates a new care episode for an appointment",
  },
  APPOINTMENT_CARE_EPISODE_PROVIDER: {
    title: "APPOINTMENT_CARE_EPISODE_PROVIDER",
    description: "Adds a provider to an episode of care",
  },
  APPOINTMENT_CREATE_ATHENA_ENCOUNTER: {
    title: "APPOINTMENT_CREATE_ATHENA_ENCOUNTER",
    description: "Sets the default provider to that of this appointment",
  },
  APPOINTMENT_DEFAULT_PROVIDER: {
    title: "APPOINTMENT_DEFAULT_PROVIDER",
    description: "Sets the default provider to that of this appointment",
  },
  APPOINTMENT_PATIENT_SESSION_CREATION: {
    title: "APPOINTMENT_PATIENT_SESSION_CREATION",
    description: "Creates a new patient session for this appointment",
  },
  EXTERNAL_OBSERVATION_IMPORT: {
    title: "EXTERNAL_OBSERVATION_IMPORT",
    description: "Imports an external observation into the Mirah measurement system",
  },
  PATIENT_COVERAGE: {
    title: "PATIENT_COVERAGE",
    description: "Extracts coverage information from patient details",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dataSourceRecordAlgorithmDescriptionT(value: DataSourceRecordAlgorithm, t: (t: `enums:DataSourceRecordAlgorithm.${keyof typeof resources["en"]["enums"]["DataSourceRecordAlgorithm"]}.description`) => string):string {
    switch (value) {
      case DataSourceRecordAlgorithm.APPOINTMENT_CARE_EPISODE_CREATION: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_CARE_EPISODE_CREATION.description");
case DataSourceRecordAlgorithm.APPOINTMENT_CARE_EPISODE_PROVIDER: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_CARE_EPISODE_PROVIDER.description");
case DataSourceRecordAlgorithm.APPOINTMENT_CREATE_ATHENA_ENCOUNTER: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_CREATE_ATHENA_ENCOUNTER.description");
case DataSourceRecordAlgorithm.APPOINTMENT_DEFAULT_PROVIDER: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_DEFAULT_PROVIDER.description");
case DataSourceRecordAlgorithm.APPOINTMENT_PATIENT_SESSION_CREATION: return t("enums:DataSourceRecordAlgorithm.APPOINTMENT_PATIENT_SESSION_CREATION.description");
case DataSourceRecordAlgorithm.EXTERNAL_OBSERVATION_IMPORT: return t("enums:DataSourceRecordAlgorithm.EXTERNAL_OBSERVATION_IMPORT.description");
case DataSourceRecordAlgorithm.PATIENT_COVERAGE: return t("enums:DataSourceRecordAlgorithm.PATIENT_COVERAGE.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  DataSourceRecordLogError: {
  ASSOCIATION_MISSING: {
    title: "ASSOCIATION_MISSING",
    description: "No record for the association was found",
  },
  ASSOCIATION_NOT_IMPORTED: {
    title: "ASSOCIATION_NOT_IMPORTED",
    description: "We have not imported the record for the association",
  },
  BLANK_NOT_ALLOWED: {
    title: "BLANK_NOT_ALLOWED",
    description: "A blank value for this field is not permitted",
  },
  INVALID_COLUMN: {
    title: "INVALID_COLUMN",
    description: "The column given is not part of our system",
  },
  INVALID_FORMAT: {
    title: "INVALID_FORMAT",
    description: "The value was in an invalid format",
  },
  INVALID_TYPE: {
    title: "INVALID_TYPE",
    description: "The column data has not be typed correctly (ie a date field marked as a string)",
  },
  MISSING_COLUMN: {
    title: "MISSING_COLUMN",
    description: "The column requested was not found in the source document",
  },
  NOT_PRESENT: {
    title: "NOT_PRESENT",
    description: "The value was required but no value was found",
  },
  UNKNOWN_ERROR: {
    title: "UNKNOWN_ERROR",
    description: "An unknown error occurred",
  },
  UNMAPPED: {
    title: "UNMAPPED",
    description: "A value was supplied that has not been mapped to a Mirah value",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dataSourceRecordLogErrorT(value: DataSourceRecordLogError, t: (t: `enums:DataSourceRecordLogError.${keyof typeof resources["en"]["enums"]["DataSourceRecordLogError"]}.title`) => string):string {
    switch (value) {
      case DataSourceRecordLogError.ASSOCIATION_MISSING: return t("enums:DataSourceRecordLogError.ASSOCIATION_MISSING.title");
case DataSourceRecordLogError.ASSOCIATION_NOT_IMPORTED: return t("enums:DataSourceRecordLogError.ASSOCIATION_NOT_IMPORTED.title");
case DataSourceRecordLogError.BLANK_NOT_ALLOWED: return t("enums:DataSourceRecordLogError.BLANK_NOT_ALLOWED.title");
case DataSourceRecordLogError.INVALID_COLUMN: return t("enums:DataSourceRecordLogError.INVALID_COLUMN.title");
case DataSourceRecordLogError.INVALID_FORMAT: return t("enums:DataSourceRecordLogError.INVALID_FORMAT.title");
case DataSourceRecordLogError.INVALID_TYPE: return t("enums:DataSourceRecordLogError.INVALID_TYPE.title");
case DataSourceRecordLogError.MISSING_COLUMN: return t("enums:DataSourceRecordLogError.MISSING_COLUMN.title");
case DataSourceRecordLogError.NOT_PRESENT: return t("enums:DataSourceRecordLogError.NOT_PRESENT.title");
case DataSourceRecordLogError.UNKNOWN_ERROR: return t("enums:DataSourceRecordLogError.UNKNOWN_ERROR.title");
case DataSourceRecordLogError.UNMAPPED: return t("enums:DataSourceRecordLogError.UNMAPPED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  DataSourceRecordLogError: {
  ASSOCIATION_MISSING: {
    title: "ASSOCIATION_MISSING",
    description: "No record for the association was found",
  },
  ASSOCIATION_NOT_IMPORTED: {
    title: "ASSOCIATION_NOT_IMPORTED",
    description: "We have not imported the record for the association",
  },
  BLANK_NOT_ALLOWED: {
    title: "BLANK_NOT_ALLOWED",
    description: "A blank value for this field is not permitted",
  },
  INVALID_COLUMN: {
    title: "INVALID_COLUMN",
    description: "The column given is not part of our system",
  },
  INVALID_FORMAT: {
    title: "INVALID_FORMAT",
    description: "The value was in an invalid format",
  },
  INVALID_TYPE: {
    title: "INVALID_TYPE",
    description: "The column data has not be typed correctly (ie a date field marked as a string)",
  },
  MISSING_COLUMN: {
    title: "MISSING_COLUMN",
    description: "The column requested was not found in the source document",
  },
  NOT_PRESENT: {
    title: "NOT_PRESENT",
    description: "The value was required but no value was found",
  },
  UNKNOWN_ERROR: {
    title: "UNKNOWN_ERROR",
    description: "An unknown error occurred",
  },
  UNMAPPED: {
    title: "UNMAPPED",
    description: "A value was supplied that has not been mapped to a Mirah value",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dataSourceRecordLogErrorDescriptionT(value: DataSourceRecordLogError, t: (t: `enums:DataSourceRecordLogError.${keyof typeof resources["en"]["enums"]["DataSourceRecordLogError"]}.description`) => string):string {
    switch (value) {
      case DataSourceRecordLogError.ASSOCIATION_MISSING: return t("enums:DataSourceRecordLogError.ASSOCIATION_MISSING.description");
case DataSourceRecordLogError.ASSOCIATION_NOT_IMPORTED: return t("enums:DataSourceRecordLogError.ASSOCIATION_NOT_IMPORTED.description");
case DataSourceRecordLogError.BLANK_NOT_ALLOWED: return t("enums:DataSourceRecordLogError.BLANK_NOT_ALLOWED.description");
case DataSourceRecordLogError.INVALID_COLUMN: return t("enums:DataSourceRecordLogError.INVALID_COLUMN.description");
case DataSourceRecordLogError.INVALID_FORMAT: return t("enums:DataSourceRecordLogError.INVALID_FORMAT.description");
case DataSourceRecordLogError.INVALID_TYPE: return t("enums:DataSourceRecordLogError.INVALID_TYPE.description");
case DataSourceRecordLogError.MISSING_COLUMN: return t("enums:DataSourceRecordLogError.MISSING_COLUMN.description");
case DataSourceRecordLogError.NOT_PRESENT: return t("enums:DataSourceRecordLogError.NOT_PRESENT.description");
case DataSourceRecordLogError.UNKNOWN_ERROR: return t("enums:DataSourceRecordLogError.UNKNOWN_ERROR.description");
case DataSourceRecordLogError.UNMAPPED: return t("enums:DataSourceRecordLogError.UNMAPPED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  DataSourceRecordLogStatus: {
  AWAITING_PROCESSING: {
    title: "AWAITING_PROCESSING",
    description: "Record is awaiting processing",
  },
  CREATED: {
    title: "CREATED",
    description: "A record was created",
  },
  ID_ERROR: {
    title: "ID_ERROR",
    description: "The record's id could not be understood",
  },
  INVALID: {
    title: "INVALID",
    description: "The data was invalid and could not be saved",
  },
  LOAD_ERROR: {
    title: "LOAD_ERROR",
    description: "An error occurred while loading the data",
  },
  PROCESSING: {
    title: "PROCESSING",
    description: "Record is currently processing",
  },
  SKIPPED: {
    title: "SKIPPED",
    description: "This Record was skipped",
  },
  STALE: {
    title: "STALE",
    description: "The row is stale and was ignored",
  },
  SYSTEM_ERROR: {
    title: "SYSTEM_ERROR",
    description: "A system error occurred during processing",
  },
  UNCHANGED: {
    title: "UNCHANGED",
    description: "A record was left unchanged",
  },
  UNKNOWN_ERROR: {
    title: "UNKNOWN_ERROR",
    description: "An unknown error occurred",
  },
  UPDATED: {
    title: "UPDATED",
    description: "A record was updated",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dataSourceRecordLogStatusT(value: DataSourceRecordLogStatus, t: (t: `enums:DataSourceRecordLogStatus.${keyof typeof resources["en"]["enums"]["DataSourceRecordLogStatus"]}.title`) => string):string {
    switch (value) {
      case DataSourceRecordLogStatus.AWAITING_PROCESSING: return t("enums:DataSourceRecordLogStatus.AWAITING_PROCESSING.title");
case DataSourceRecordLogStatus.CREATED: return t("enums:DataSourceRecordLogStatus.CREATED.title");
case DataSourceRecordLogStatus.ID_ERROR: return t("enums:DataSourceRecordLogStatus.ID_ERROR.title");
case DataSourceRecordLogStatus.INVALID: return t("enums:DataSourceRecordLogStatus.INVALID.title");
case DataSourceRecordLogStatus.LOAD_ERROR: return t("enums:DataSourceRecordLogStatus.LOAD_ERROR.title");
case DataSourceRecordLogStatus.PROCESSING: return t("enums:DataSourceRecordLogStatus.PROCESSING.title");
case DataSourceRecordLogStatus.SKIPPED: return t("enums:DataSourceRecordLogStatus.SKIPPED.title");
case DataSourceRecordLogStatus.STALE: return t("enums:DataSourceRecordLogStatus.STALE.title");
case DataSourceRecordLogStatus.SYSTEM_ERROR: return t("enums:DataSourceRecordLogStatus.SYSTEM_ERROR.title");
case DataSourceRecordLogStatus.UNCHANGED: return t("enums:DataSourceRecordLogStatus.UNCHANGED.title");
case DataSourceRecordLogStatus.UNKNOWN_ERROR: return t("enums:DataSourceRecordLogStatus.UNKNOWN_ERROR.title");
case DataSourceRecordLogStatus.UPDATED: return t("enums:DataSourceRecordLogStatus.UPDATED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  DataSourceRecordLogStatus: {
  AWAITING_PROCESSING: {
    title: "AWAITING_PROCESSING",
    description: "Record is awaiting processing",
  },
  CREATED: {
    title: "CREATED",
    description: "A record was created",
  },
  ID_ERROR: {
    title: "ID_ERROR",
    description: "The record's id could not be understood",
  },
  INVALID: {
    title: "INVALID",
    description: "The data was invalid and could not be saved",
  },
  LOAD_ERROR: {
    title: "LOAD_ERROR",
    description: "An error occurred while loading the data",
  },
  PROCESSING: {
    title: "PROCESSING",
    description: "Record is currently processing",
  },
  SKIPPED: {
    title: "SKIPPED",
    description: "This Record was skipped",
  },
  STALE: {
    title: "STALE",
    description: "The row is stale and was ignored",
  },
  SYSTEM_ERROR: {
    title: "SYSTEM_ERROR",
    description: "A system error occurred during processing",
  },
  UNCHANGED: {
    title: "UNCHANGED",
    description: "A record was left unchanged",
  },
  UNKNOWN_ERROR: {
    title: "UNKNOWN_ERROR",
    description: "An unknown error occurred",
  },
  UPDATED: {
    title: "UPDATED",
    description: "A record was updated",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dataSourceRecordLogStatusDescriptionT(value: DataSourceRecordLogStatus, t: (t: `enums:DataSourceRecordLogStatus.${keyof typeof resources["en"]["enums"]["DataSourceRecordLogStatus"]}.description`) => string):string {
    switch (value) {
      case DataSourceRecordLogStatus.AWAITING_PROCESSING: return t("enums:DataSourceRecordLogStatus.AWAITING_PROCESSING.description");
case DataSourceRecordLogStatus.CREATED: return t("enums:DataSourceRecordLogStatus.CREATED.description");
case DataSourceRecordLogStatus.ID_ERROR: return t("enums:DataSourceRecordLogStatus.ID_ERROR.description");
case DataSourceRecordLogStatus.INVALID: return t("enums:DataSourceRecordLogStatus.INVALID.description");
case DataSourceRecordLogStatus.LOAD_ERROR: return t("enums:DataSourceRecordLogStatus.LOAD_ERROR.description");
case DataSourceRecordLogStatus.PROCESSING: return t("enums:DataSourceRecordLogStatus.PROCESSING.description");
case DataSourceRecordLogStatus.SKIPPED: return t("enums:DataSourceRecordLogStatus.SKIPPED.description");
case DataSourceRecordLogStatus.STALE: return t("enums:DataSourceRecordLogStatus.STALE.description");
case DataSourceRecordLogStatus.SYSTEM_ERROR: return t("enums:DataSourceRecordLogStatus.SYSTEM_ERROR.description");
case DataSourceRecordLogStatus.UNCHANGED: return t("enums:DataSourceRecordLogStatus.UNCHANGED.description");
case DataSourceRecordLogStatus.UNKNOWN_ERROR: return t("enums:DataSourceRecordLogStatus.UNKNOWN_ERROR.description");
case DataSourceRecordLogStatus.UPDATED: return t("enums:DataSourceRecordLogStatus.UPDATED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  DischargeReason: {
  CHANGED_PROVIDER: {
    title: "CHANGED_PROVIDER",
    description: "The patient has changed their care provider",
  },
  COMPLETED: {
    title: "COMPLETED",
    description: "The patient successfully completed the program",
  },
  DROPPED_OUT: {
    title: "DROPPED_OUT",
    description: "The patient dropped out or otherwise declined to participate",
  },
  GRADUATED: {
    title: "GRADUATED",
    description: "The patient has graduated from care",
  },
  LOST_CONTACT: {
    title: "LOST_CONTACT",
    description: "The patient was in contact for a time before losing contact",
  },
  NO_CONTACT: {
    title: "NO_CONTACT",
    description: "The patient was never able to be contacted",
  },
  OTHER: {
    title: "OTHER",
    description: "Other",
  },
  REFERRAL_TO_CARE: {
    title: "REFERRAL_TO_CARE",
    description: "The patient has been referred to further care",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dischargeReasonT(value: DischargeReason, t: (t: `enums:DischargeReason.${keyof typeof resources["en"]["enums"]["DischargeReason"]}.title`) => string):string {
    switch (value) {
      case DischargeReason.CHANGED_PROVIDER: return t("enums:DischargeReason.CHANGED_PROVIDER.title");
case DischargeReason.COMPLETED: return t("enums:DischargeReason.COMPLETED.title");
case DischargeReason.DROPPED_OUT: return t("enums:DischargeReason.DROPPED_OUT.title");
case DischargeReason.GRADUATED: return t("enums:DischargeReason.GRADUATED.title");
case DischargeReason.LOST_CONTACT: return t("enums:DischargeReason.LOST_CONTACT.title");
case DischargeReason.NO_CONTACT: return t("enums:DischargeReason.NO_CONTACT.title");
case DischargeReason.OTHER: return t("enums:DischargeReason.OTHER.title");
case DischargeReason.REFERRAL_TO_CARE: return t("enums:DischargeReason.REFERRAL_TO_CARE.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  DischargeReason: {
  CHANGED_PROVIDER: {
    title: "CHANGED_PROVIDER",
    description: "The patient has changed their care provider",
  },
  COMPLETED: {
    title: "COMPLETED",
    description: "The patient successfully completed the program",
  },
  DROPPED_OUT: {
    title: "DROPPED_OUT",
    description: "The patient dropped out or otherwise declined to participate",
  },
  GRADUATED: {
    title: "GRADUATED",
    description: "The patient has graduated from care",
  },
  LOST_CONTACT: {
    title: "LOST_CONTACT",
    description: "The patient was in contact for a time before losing contact",
  },
  NO_CONTACT: {
    title: "NO_CONTACT",
    description: "The patient was never able to be contacted",
  },
  OTHER: {
    title: "OTHER",
    description: "Other",
  },
  REFERRAL_TO_CARE: {
    title: "REFERRAL_TO_CARE",
    description: "The patient has been referred to further care",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function dischargeReasonDescriptionT(value: DischargeReason, t: (t: `enums:DischargeReason.${keyof typeof resources["en"]["enums"]["DischargeReason"]}.description`) => string):string {
    switch (value) {
      case DischargeReason.CHANGED_PROVIDER: return t("enums:DischargeReason.CHANGED_PROVIDER.description");
case DischargeReason.COMPLETED: return t("enums:DischargeReason.COMPLETED.description");
case DischargeReason.DROPPED_OUT: return t("enums:DischargeReason.DROPPED_OUT.description");
case DischargeReason.GRADUATED: return t("enums:DischargeReason.GRADUATED.description");
case DischargeReason.LOST_CONTACT: return t("enums:DischargeReason.LOST_CONTACT.description");
case DischargeReason.NO_CONTACT: return t("enums:DischargeReason.NO_CONTACT.description");
case DischargeReason.OTHER: return t("enums:DischargeReason.OTHER.description");
case DischargeReason.REFERRAL_TO_CARE: return t("enums:DischargeReason.REFERRAL_TO_CARE.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  EnrollmentMonthMinutesTargetStatus: {
  ON_TARGET: {
    title: "ON_TARGET",
    description: "The month is on target for minutes for the month",
  },
  OVER_TARGET: {
    title: "OVER_TARGET",
    description: "The month is over target minutes for the month",
  },
  UNDER_TARGET: {
    title: "UNDER_TARGET",
    description: "The month is under target minutes for the month",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function enrollmentMonthMinutesTargetStatusT(value: EnrollmentMonthMinutesTargetStatus, t: (t: `enums:EnrollmentMonthMinutesTargetStatus.${keyof typeof resources["en"]["enums"]["EnrollmentMonthMinutesTargetStatus"]}.title`) => string):string {
    switch (value) {
      case EnrollmentMonthMinutesTargetStatus.ON_TARGET: return t("enums:EnrollmentMonthMinutesTargetStatus.ON_TARGET.title");
case EnrollmentMonthMinutesTargetStatus.OVER_TARGET: return t("enums:EnrollmentMonthMinutesTargetStatus.OVER_TARGET.title");
case EnrollmentMonthMinutesTargetStatus.UNDER_TARGET: return t("enums:EnrollmentMonthMinutesTargetStatus.UNDER_TARGET.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  EnrollmentMonthMinutesTargetStatus: {
  ON_TARGET: {
    title: "ON_TARGET",
    description: "The month is on target for minutes for the month",
  },
  OVER_TARGET: {
    title: "OVER_TARGET",
    description: "The month is over target minutes for the month",
  },
  UNDER_TARGET: {
    title: "UNDER_TARGET",
    description: "The month is under target minutes for the month",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function enrollmentMonthMinutesTargetStatusDescriptionT(value: EnrollmentMonthMinutesTargetStatus, t: (t: `enums:EnrollmentMonthMinutesTargetStatus.${keyof typeof resources["en"]["enums"]["EnrollmentMonthMinutesTargetStatus"]}.description`) => string):string {
    switch (value) {
      case EnrollmentMonthMinutesTargetStatus.ON_TARGET: return t("enums:EnrollmentMonthMinutesTargetStatus.ON_TARGET.description");
case EnrollmentMonthMinutesTargetStatus.OVER_TARGET: return t("enums:EnrollmentMonthMinutesTargetStatus.OVER_TARGET.description");
case EnrollmentMonthMinutesTargetStatus.UNDER_TARGET: return t("enums:EnrollmentMonthMinutesTargetStatus.UNDER_TARGET.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  EnrollmentStatus: {
  DISCHARGED: {
    title: "DISCHARGED",
    description: "This care episode's collaborative care is finished",
  },
  ENROLLED: {
    title: "ENROLLED",
    description: "This care episode is enrolled in collaborative care",
  },
  UNENROLLED: {
    title: "UNENROLLED",
    description: "This care episode is not currently enrolled in collaborative care",
  },
  UNENROLLED_NEVER_ENROLLED: {
    title: "UNENROLLED_NEVER_ENROLLED",
    description: "This care episode's collaborative care was never started",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function enrollmentStatusT(value: EnrollmentStatus, t: (t: `enums:EnrollmentStatus.${keyof typeof resources["en"]["enums"]["EnrollmentStatus"]}.title`) => string):string {
    switch (value) {
      case EnrollmentStatus.DISCHARGED: return t("enums:EnrollmentStatus.DISCHARGED.title");
case EnrollmentStatus.ENROLLED: return t("enums:EnrollmentStatus.ENROLLED.title");
case EnrollmentStatus.UNENROLLED: return t("enums:EnrollmentStatus.UNENROLLED.title");
case EnrollmentStatus.UNENROLLED_NEVER_ENROLLED: return t("enums:EnrollmentStatus.UNENROLLED_NEVER_ENROLLED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  EnrollmentStatus: {
  DISCHARGED: {
    title: "DISCHARGED",
    description: "This care episode's collaborative care is finished",
  },
  ENROLLED: {
    title: "ENROLLED",
    description: "This care episode is enrolled in collaborative care",
  },
  UNENROLLED: {
    title: "UNENROLLED",
    description: "This care episode is not currently enrolled in collaborative care",
  },
  UNENROLLED_NEVER_ENROLLED: {
    title: "UNENROLLED_NEVER_ENROLLED",
    description: "This care episode's collaborative care was never started",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function enrollmentStatusDescriptionT(value: EnrollmentStatus, t: (t: `enums:EnrollmentStatus.${keyof typeof resources["en"]["enums"]["EnrollmentStatus"]}.description`) => string):string {
    switch (value) {
      case EnrollmentStatus.DISCHARGED: return t("enums:EnrollmentStatus.DISCHARGED.description");
case EnrollmentStatus.ENROLLED: return t("enums:EnrollmentStatus.ENROLLED.description");
case EnrollmentStatus.UNENROLLED: return t("enums:EnrollmentStatus.UNENROLLED.description");
case EnrollmentStatus.UNENROLLED_NEVER_ENROLLED: return t("enums:EnrollmentStatus.UNENROLLED_NEVER_ENROLLED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  EntityDecisionAlgorithmStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Algorithm is active. Alerts will be created and displayed",
  },
  DISABLED: {
    title: "DISABLED",
    description: "Algorithm will not trigger, and alerts are hidden",
  },
  HIDDEN: {
    title: "HIDDEN",
    description: "Algorithm continues to trigger, ",
  },
  PAUSED: {
    title: "PAUSED",
    description: "Algorithm will not trigger, but alerts for existing triggers will be seen",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function entityDecisionAlgorithmStatusT(value: EntityDecisionAlgorithmStatus, t: (t: `enums:EntityDecisionAlgorithmStatus.${keyof typeof resources["en"]["enums"]["EntityDecisionAlgorithmStatus"]}.title`) => string):string {
    switch (value) {
      case EntityDecisionAlgorithmStatus.ACTIVE: return t("enums:EntityDecisionAlgorithmStatus.ACTIVE.title");
case EntityDecisionAlgorithmStatus.DISABLED: return t("enums:EntityDecisionAlgorithmStatus.DISABLED.title");
case EntityDecisionAlgorithmStatus.HIDDEN: return t("enums:EntityDecisionAlgorithmStatus.HIDDEN.title");
case EntityDecisionAlgorithmStatus.PAUSED: return t("enums:EntityDecisionAlgorithmStatus.PAUSED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  EntityDecisionAlgorithmStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Algorithm is active. Alerts will be created and displayed",
  },
  DISABLED: {
    title: "DISABLED",
    description: "Algorithm will not trigger, and alerts are hidden",
  },
  HIDDEN: {
    title: "HIDDEN",
    description: "Algorithm continues to trigger, ",
  },
  PAUSED: {
    title: "PAUSED",
    description: "Algorithm will not trigger, but alerts for existing triggers will be seen",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function entityDecisionAlgorithmStatusDescriptionT(value: EntityDecisionAlgorithmStatus, t: (t: `enums:EntityDecisionAlgorithmStatus.${keyof typeof resources["en"]["enums"]["EntityDecisionAlgorithmStatus"]}.description`) => string):string {
    switch (value) {
      case EntityDecisionAlgorithmStatus.ACTIVE: return t("enums:EntityDecisionAlgorithmStatus.ACTIVE.description");
case EntityDecisionAlgorithmStatus.DISABLED: return t("enums:EntityDecisionAlgorithmStatus.DISABLED.description");
case EntityDecisionAlgorithmStatus.HIDDEN: return t("enums:EntityDecisionAlgorithmStatus.HIDDEN.description");
case EntityDecisionAlgorithmStatus.PAUSED: return t("enums:EntityDecisionAlgorithmStatus.PAUSED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  EntityType: {
  CARE_EPISODE: {
    title: "CARE_EPISODE",
    description: "An episode of care",
  },
  CARE_MANAGER: {
    title: "CARE_MANAGER",
    description: "A CoCM care manager",
  },
  CARE_UNIT_TYPE: {
    title: "CARE_UNIT_TYPE",
    description: "The type of patient",
  },
  ENCOUNTER: {
    title: "ENCOUNTER",
    description: "An encounter",
  },
  ENTITY_DECISION_ALGORITHM: {
    title: "ENTITY_DECISION_ALGORITHM",
    description: "An instance of a decision support algorithm",
  },
  EXPERIMENT: {
    title: "EXPERIMENT",
    description: "An experiment",
  },
  EXPERIMENT_VARIANT: {
    title: "EXPERIMENT_VARIANT",
    description: "The variant of an experiment",
  },
  INSTITUTE: {
    title: "INSTITUTE",
    description: "A top level institute",
  },
  INSTITUTE_CONDITION: {
    title: "INSTITUTE_CONDITION",
    description: "A diagnosis as specified by the institute EHR",
  },
  INSTITUTE_GROUP: {
    title: "INSTITUTE_GROUP",
    description: "A group of institutes",
  },
  INSTITUTE_PAYOR: {
    title: "INSTITUTE_PAYOR",
    description: "An insurance company",
  },
  LOCATION: {
    title: "LOCATION",
    description: "A physical location",
  },
  ORGANIZATION: {
    title: "ORGANIZATION",
    description: "An organization within an institute",
  },
  PANEL: {
    title: "PANEL",
    description: "A collaborative care panel",
  },
  PATIENT: {
    title: "PATIENT",
    description: "A patient",
  },
  PATIENT_SESSION: {
    title: "PATIENT_SESSION",
    description: "A measurement of a patient",
  },
  PRIMARY_CARE_PHYSICIAN: {
    title: "PRIMARY_CARE_PHYSICIAN",
    description: "A primary care physician",
  },
  PROVIDER: {
    title: "PROVIDER",
    description: "A clinician/provider",
  },
  PROVIDER_RELATIONSHIP: {
    title: "PROVIDER_RELATIONSHIP",
    description: "A provider in a given treatment role",
  },
  ROOT: {
    title: "ROOT",
    description: "Top level root entity",
  },
  TREATMENT_SERVICE: {
    title: "TREATMENT_SERVICE",
    description: "A treatment service",
  },
  TREATMENT_TRACK: {
    title: "TREATMENT_TRACK",
    description: "A treatment track",
  },
  USER: {
    title: "USER",
    description: "A user of the system. Could be a patient, provider, related person",
  },
  WORKFLOW: {
    title: "WORKFLOW",
    description: "A workflow as part of a decision support algorithm",
  },
  WORKFLOW_ACTION: {
    title: "WORKFLOW_ACTION",
    description: "An action taken as part of a decision support algorithm",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function entityTypeT(value: EntityType, t: (t: `enums:EntityType.${keyof typeof resources["en"]["enums"]["EntityType"]}.title`) => string):string {
    switch (value) {
      case EntityType.CARE_EPISODE: return t("enums:EntityType.CARE_EPISODE.title");
case EntityType.CARE_MANAGER: return t("enums:EntityType.CARE_MANAGER.title");
case EntityType.CARE_UNIT_TYPE: return t("enums:EntityType.CARE_UNIT_TYPE.title");
case EntityType.ENCOUNTER: return t("enums:EntityType.ENCOUNTER.title");
case EntityType.ENTITY_DECISION_ALGORITHM: return t("enums:EntityType.ENTITY_DECISION_ALGORITHM.title");
case EntityType.EXPERIMENT: return t("enums:EntityType.EXPERIMENT.title");
case EntityType.EXPERIMENT_VARIANT: return t("enums:EntityType.EXPERIMENT_VARIANT.title");
case EntityType.INSTITUTE: return t("enums:EntityType.INSTITUTE.title");
case EntityType.INSTITUTE_CONDITION: return t("enums:EntityType.INSTITUTE_CONDITION.title");
case EntityType.INSTITUTE_GROUP: return t("enums:EntityType.INSTITUTE_GROUP.title");
case EntityType.INSTITUTE_PAYOR: return t("enums:EntityType.INSTITUTE_PAYOR.title");
case EntityType.LOCATION: return t("enums:EntityType.LOCATION.title");
case EntityType.ORGANIZATION: return t("enums:EntityType.ORGANIZATION.title");
case EntityType.PANEL: return t("enums:EntityType.PANEL.title");
case EntityType.PATIENT: return t("enums:EntityType.PATIENT.title");
case EntityType.PATIENT_SESSION: return t("enums:EntityType.PATIENT_SESSION.title");
case EntityType.PRIMARY_CARE_PHYSICIAN: return t("enums:EntityType.PRIMARY_CARE_PHYSICIAN.title");
case EntityType.PROVIDER: return t("enums:EntityType.PROVIDER.title");
case EntityType.PROVIDER_RELATIONSHIP: return t("enums:EntityType.PROVIDER_RELATIONSHIP.title");
case EntityType.ROOT: return t("enums:EntityType.ROOT.title");
case EntityType.TREATMENT_SERVICE: return t("enums:EntityType.TREATMENT_SERVICE.title");
case EntityType.TREATMENT_TRACK: return t("enums:EntityType.TREATMENT_TRACK.title");
case EntityType.USER: return t("enums:EntityType.USER.title");
case EntityType.WORKFLOW: return t("enums:EntityType.WORKFLOW.title");
case EntityType.WORKFLOW_ACTION: return t("enums:EntityType.WORKFLOW_ACTION.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  EntityType: {
  CARE_EPISODE: {
    title: "CARE_EPISODE",
    description: "An episode of care",
  },
  CARE_MANAGER: {
    title: "CARE_MANAGER",
    description: "A CoCM care manager",
  },
  CARE_UNIT_TYPE: {
    title: "CARE_UNIT_TYPE",
    description: "The type of patient",
  },
  ENCOUNTER: {
    title: "ENCOUNTER",
    description: "An encounter",
  },
  ENTITY_DECISION_ALGORITHM: {
    title: "ENTITY_DECISION_ALGORITHM",
    description: "An instance of a decision support algorithm",
  },
  EXPERIMENT: {
    title: "EXPERIMENT",
    description: "An experiment",
  },
  EXPERIMENT_VARIANT: {
    title: "EXPERIMENT_VARIANT",
    description: "The variant of an experiment",
  },
  INSTITUTE: {
    title: "INSTITUTE",
    description: "A top level institute",
  },
  INSTITUTE_CONDITION: {
    title: "INSTITUTE_CONDITION",
    description: "A diagnosis as specified by the institute EHR",
  },
  INSTITUTE_GROUP: {
    title: "INSTITUTE_GROUP",
    description: "A group of institutes",
  },
  INSTITUTE_PAYOR: {
    title: "INSTITUTE_PAYOR",
    description: "An insurance company",
  },
  LOCATION: {
    title: "LOCATION",
    description: "A physical location",
  },
  ORGANIZATION: {
    title: "ORGANIZATION",
    description: "An organization within an institute",
  },
  PANEL: {
    title: "PANEL",
    description: "A collaborative care panel",
  },
  PATIENT: {
    title: "PATIENT",
    description: "A patient",
  },
  PATIENT_SESSION: {
    title: "PATIENT_SESSION",
    description: "A measurement of a patient",
  },
  PRIMARY_CARE_PHYSICIAN: {
    title: "PRIMARY_CARE_PHYSICIAN",
    description: "A primary care physician",
  },
  PROVIDER: {
    title: "PROVIDER",
    description: "A clinician/provider",
  },
  PROVIDER_RELATIONSHIP: {
    title: "PROVIDER_RELATIONSHIP",
    description: "A provider in a given treatment role",
  },
  ROOT: {
    title: "ROOT",
    description: "Top level root entity",
  },
  TREATMENT_SERVICE: {
    title: "TREATMENT_SERVICE",
    description: "A treatment service",
  },
  TREATMENT_TRACK: {
    title: "TREATMENT_TRACK",
    description: "A treatment track",
  },
  USER: {
    title: "USER",
    description: "A user of the system. Could be a patient, provider, related person",
  },
  WORKFLOW: {
    title: "WORKFLOW",
    description: "A workflow as part of a decision support algorithm",
  },
  WORKFLOW_ACTION: {
    title: "WORKFLOW_ACTION",
    description: "An action taken as part of a decision support algorithm",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function entityTypeDescriptionT(value: EntityType, t: (t: `enums:EntityType.${keyof typeof resources["en"]["enums"]["EntityType"]}.description`) => string):string {
    switch (value) {
      case EntityType.CARE_EPISODE: return t("enums:EntityType.CARE_EPISODE.description");
case EntityType.CARE_MANAGER: return t("enums:EntityType.CARE_MANAGER.description");
case EntityType.CARE_UNIT_TYPE: return t("enums:EntityType.CARE_UNIT_TYPE.description");
case EntityType.ENCOUNTER: return t("enums:EntityType.ENCOUNTER.description");
case EntityType.ENTITY_DECISION_ALGORITHM: return t("enums:EntityType.ENTITY_DECISION_ALGORITHM.description");
case EntityType.EXPERIMENT: return t("enums:EntityType.EXPERIMENT.description");
case EntityType.EXPERIMENT_VARIANT: return t("enums:EntityType.EXPERIMENT_VARIANT.description");
case EntityType.INSTITUTE: return t("enums:EntityType.INSTITUTE.description");
case EntityType.INSTITUTE_CONDITION: return t("enums:EntityType.INSTITUTE_CONDITION.description");
case EntityType.INSTITUTE_GROUP: return t("enums:EntityType.INSTITUTE_GROUP.description");
case EntityType.INSTITUTE_PAYOR: return t("enums:EntityType.INSTITUTE_PAYOR.description");
case EntityType.LOCATION: return t("enums:EntityType.LOCATION.description");
case EntityType.ORGANIZATION: return t("enums:EntityType.ORGANIZATION.description");
case EntityType.PANEL: return t("enums:EntityType.PANEL.description");
case EntityType.PATIENT: return t("enums:EntityType.PATIENT.description");
case EntityType.PATIENT_SESSION: return t("enums:EntityType.PATIENT_SESSION.description");
case EntityType.PRIMARY_CARE_PHYSICIAN: return t("enums:EntityType.PRIMARY_CARE_PHYSICIAN.description");
case EntityType.PROVIDER: return t("enums:EntityType.PROVIDER.description");
case EntityType.PROVIDER_RELATIONSHIP: return t("enums:EntityType.PROVIDER_RELATIONSHIP.description");
case EntityType.ROOT: return t("enums:EntityType.ROOT.description");
case EntityType.TREATMENT_SERVICE: return t("enums:EntityType.TREATMENT_SERVICE.description");
case EntityType.TREATMENT_TRACK: return t("enums:EntityType.TREATMENT_TRACK.description");
case EntityType.USER: return t("enums:EntityType.USER.description");
case EntityType.WORKFLOW: return t("enums:EntityType.WORKFLOW.description");
case EntityType.WORKFLOW_ACTION: return t("enums:EntityType.WORKFLOW_ACTION.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  Gender: {
  FEMALE: {
    title: "FEMALE",
    description: "Female",
  },
  MALE: {
    title: "MALE",
    description: "Male",
  },
  OTHER: {
    title: "OTHER",
    description: "Other",
  },
  UNKNOWN: {
    title: "UNKNOWN",
    description: "Unknown",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function genderT(value: Gender, t: (t: `enums:Gender.${keyof typeof resources["en"]["enums"]["Gender"]}.title`) => string):string {
    switch (value) {
      case Gender.FEMALE: return t("enums:Gender.FEMALE.title");
case Gender.MALE: return t("enums:Gender.MALE.title");
case Gender.OTHER: return t("enums:Gender.OTHER.title");
case Gender.UNKNOWN: return t("enums:Gender.UNKNOWN.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  Gender: {
  FEMALE: {
    title: "FEMALE",
    description: "Female",
  },
  MALE: {
    title: "MALE",
    description: "Male",
  },
  OTHER: {
    title: "OTHER",
    description: "Other",
  },
  UNKNOWN: {
    title: "UNKNOWN",
    description: "Unknown",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function genderDescriptionT(value: Gender, t: (t: `enums:Gender.${keyof typeof resources["en"]["enums"]["Gender"]}.description`) => string):string {
    switch (value) {
      case Gender.FEMALE: return t("enums:Gender.FEMALE.description");
case Gender.MALE: return t("enums:Gender.MALE.description");
case Gender.OTHER: return t("enums:Gender.OTHER.description");
case Gender.UNKNOWN: return t("enums:Gender.UNKNOWN.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  GenderIdentity: {
  FEMALE: {
    title: "FEMALE",
    description: "The patient identifies as female",
  },
  MALE: {
    title: "MALE",
    description: "The patient identifies as male",
  },
  NON_BINARY: {
    title: "NON_BINARY",
    description: "The patient identifies with neither/both female and male",
  },
  NON_DISCLOSE: {
    title: "NON_DISCLOSE",
    description: "The patient does not wish to disclose his gender identity",
  },
  OTHER: {
    title: "OTHER",
    description: "Other gender identity",
  },
  TRANSGENDER_FEMALE: {
    title: "TRANSGENDER_FEMALE",
    description: "The patient identifies as transgender male-to-female",
  },
  TRANSGENDER_MALE: {
    title: "TRANSGENDER_MALE",
    description: "The patient identifies as transgender female-to-male",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function genderIdentityT(value: GenderIdentity, t: (t: `enums:GenderIdentity.${keyof typeof resources["en"]["enums"]["GenderIdentity"]}.title`) => string):string {
    switch (value) {
      case GenderIdentity.FEMALE: return t("enums:GenderIdentity.FEMALE.title");
case GenderIdentity.MALE: return t("enums:GenderIdentity.MALE.title");
case GenderIdentity.NON_BINARY: return t("enums:GenderIdentity.NON_BINARY.title");
case GenderIdentity.NON_DISCLOSE: return t("enums:GenderIdentity.NON_DISCLOSE.title");
case GenderIdentity.OTHER: return t("enums:GenderIdentity.OTHER.title");
case GenderIdentity.TRANSGENDER_FEMALE: return t("enums:GenderIdentity.TRANSGENDER_FEMALE.title");
case GenderIdentity.TRANSGENDER_MALE: return t("enums:GenderIdentity.TRANSGENDER_MALE.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  GenderIdentity: {
  FEMALE: {
    title: "FEMALE",
    description: "The patient identifies as female",
  },
  MALE: {
    title: "MALE",
    description: "The patient identifies as male",
  },
  NON_BINARY: {
    title: "NON_BINARY",
    description: "The patient identifies with neither/both female and male",
  },
  NON_DISCLOSE: {
    title: "NON_DISCLOSE",
    description: "The patient does not wish to disclose his gender identity",
  },
  OTHER: {
    title: "OTHER",
    description: "Other gender identity",
  },
  TRANSGENDER_FEMALE: {
    title: "TRANSGENDER_FEMALE",
    description: "The patient identifies as transgender male-to-female",
  },
  TRANSGENDER_MALE: {
    title: "TRANSGENDER_MALE",
    description: "The patient identifies as transgender female-to-male",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function genderIdentityDescriptionT(value: GenderIdentity, t: (t: `enums:GenderIdentity.${keyof typeof resources["en"]["enums"]["GenderIdentity"]}.description`) => string):string {
    switch (value) {
      case GenderIdentity.FEMALE: return t("enums:GenderIdentity.FEMALE.description");
case GenderIdentity.MALE: return t("enums:GenderIdentity.MALE.description");
case GenderIdentity.NON_BINARY: return t("enums:GenderIdentity.NON_BINARY.description");
case GenderIdentity.NON_DISCLOSE: return t("enums:GenderIdentity.NON_DISCLOSE.description");
case GenderIdentity.OTHER: return t("enums:GenderIdentity.OTHER.description");
case GenderIdentity.TRANSGENDER_FEMALE: return t("enums:GenderIdentity.TRANSGENDER_FEMALE.description");
case GenderIdentity.TRANSGENDER_MALE: return t("enums:GenderIdentity.TRANSGENDER_MALE.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ImplementationTargetOnTrack: {
  OFF_TRACK: {
    title: "OFF_TRACK",
    description: "The target is on track",
  },
  ON_TRACK: {
    title: "ON_TRACK",
    description: "The target is on track",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetOnTrackT(value: ImplementationTargetOnTrack, t: (t: `enums:ImplementationTargetOnTrack.${keyof typeof resources["en"]["enums"]["ImplementationTargetOnTrack"]}.title`) => string):string {
    switch (value) {
      case ImplementationTargetOnTrack.OFF_TRACK: return t("enums:ImplementationTargetOnTrack.OFF_TRACK.title");
case ImplementationTargetOnTrack.ON_TRACK: return t("enums:ImplementationTargetOnTrack.ON_TRACK.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ImplementationTargetOnTrack: {
  OFF_TRACK: {
    title: "OFF_TRACK",
    description: "The target is on track",
  },
  ON_TRACK: {
    title: "ON_TRACK",
    description: "The target is on track",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetOnTrackDescriptionT(value: ImplementationTargetOnTrack, t: (t: `enums:ImplementationTargetOnTrack.${keyof typeof resources["en"]["enums"]["ImplementationTargetOnTrack"]}.description`) => string):string {
    switch (value) {
      case ImplementationTargetOnTrack.OFF_TRACK: return t("enums:ImplementationTargetOnTrack.OFF_TRACK.description");
case ImplementationTargetOnTrack.ON_TRACK: return t("enums:ImplementationTargetOnTrack.ON_TRACK.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ImplementationTargetReportGraphDataSeries: {
  DATA: {
    title: "DATA",
    description: "This series is the actual data",
  },
  TARGET: {
    title: "TARGET",
    description: "This series is the target line",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetReportGraphDataSeriesT(value: ImplementationTargetReportGraphDataSeries, t: (t: `enums:ImplementationTargetReportGraphDataSeries.${keyof typeof resources["en"]["enums"]["ImplementationTargetReportGraphDataSeries"]}.title`) => string):string {
    switch (value) {
      case ImplementationTargetReportGraphDataSeries.DATA: return t("enums:ImplementationTargetReportGraphDataSeries.DATA.title");
case ImplementationTargetReportGraphDataSeries.TARGET: return t("enums:ImplementationTargetReportGraphDataSeries.TARGET.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ImplementationTargetReportGraphDataSeries: {
  DATA: {
    title: "DATA",
    description: "This series is the actual data",
  },
  TARGET: {
    title: "TARGET",
    description: "This series is the target line",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetReportGraphDataSeriesDescriptionT(value: ImplementationTargetReportGraphDataSeries, t: (t: `enums:ImplementationTargetReportGraphDataSeries.${keyof typeof resources["en"]["enums"]["ImplementationTargetReportGraphDataSeries"]}.description`) => string):string {
    switch (value) {
      case ImplementationTargetReportGraphDataSeries.DATA: return t("enums:ImplementationTargetReportGraphDataSeries.DATA.description");
case ImplementationTargetReportGraphDataSeries.TARGET: return t("enums:ImplementationTargetReportGraphDataSeries.TARGET.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ImplementationTargetReportPeriod: {
  MONTH: {
    title: "MONTH",
    description: "The report applies to a calendar month",
  },
  WEEK: {
    title: "WEEK",
    description: "The report applies to a 7-day week",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetReportPeriodT(value: ImplementationTargetReportPeriod, t: (t: `enums:ImplementationTargetReportPeriod.${keyof typeof resources["en"]["enums"]["ImplementationTargetReportPeriod"]}.title`) => string):string {
    switch (value) {
      case ImplementationTargetReportPeriod.MONTH: return t("enums:ImplementationTargetReportPeriod.MONTH.title");
case ImplementationTargetReportPeriod.WEEK: return t("enums:ImplementationTargetReportPeriod.WEEK.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ImplementationTargetReportPeriod: {
  MONTH: {
    title: "MONTH",
    description: "The report applies to a calendar month",
  },
  WEEK: {
    title: "WEEK",
    description: "The report applies to a 7-day week",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetReportPeriodDescriptionT(value: ImplementationTargetReportPeriod, t: (t: `enums:ImplementationTargetReportPeriod.${keyof typeof resources["en"]["enums"]["ImplementationTargetReportPeriod"]}.description`) => string):string {
    switch (value) {
      case ImplementationTargetReportPeriod.MONTH: return t("enums:ImplementationTargetReportPeriod.MONTH.description");
case ImplementationTargetReportPeriod.WEEK: return t("enums:ImplementationTargetReportPeriod.WEEK.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ImplementationTargetStatPeriod: {
  BEFORE_CURRENT_REPORT: {
    title: "BEFORE_CURRENT_REPORT",
    description: "The stat applies from the start of the goal but does not include the current value",
  },
  LAST_7_DAYS: {
    title: "LAST_7_DAYS",
    description: "The stat applies to the last 7 days",
  },
  LAST_30_DAYS: {
    title: "LAST_30_DAYS",
    description: "The stat applies to the last 30 days",
  },
  LAST_MONTH: {
    title: "LAST_MONTH",
    description: "The stat applies to the previous month",
  },
  SINCE_GOAL_START: {
    title: "SINCE_GOAL_START",
    description: "The stat applies since the start of the goal",
  },
  THIS_MONTH: {
    title: "THIS_MONTH",
    description: "The stat applies to the current month",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetStatPeriodT(value: ImplementationTargetStatPeriod, t: (t: `enums:ImplementationTargetStatPeriod.${keyof typeof resources["en"]["enums"]["ImplementationTargetStatPeriod"]}.title`) => string):string {
    switch (value) {
      case ImplementationTargetStatPeriod.BEFORE_CURRENT_REPORT: return t("enums:ImplementationTargetStatPeriod.BEFORE_CURRENT_REPORT.title");
case ImplementationTargetStatPeriod.LAST_7_DAYS: return t("enums:ImplementationTargetStatPeriod.LAST_7_DAYS.title");
case ImplementationTargetStatPeriod.LAST_30_DAYS: return t("enums:ImplementationTargetStatPeriod.LAST_30_DAYS.title");
case ImplementationTargetStatPeriod.LAST_MONTH: return t("enums:ImplementationTargetStatPeriod.LAST_MONTH.title");
case ImplementationTargetStatPeriod.SINCE_GOAL_START: return t("enums:ImplementationTargetStatPeriod.SINCE_GOAL_START.title");
case ImplementationTargetStatPeriod.THIS_MONTH: return t("enums:ImplementationTargetStatPeriod.THIS_MONTH.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ImplementationTargetStatPeriod: {
  BEFORE_CURRENT_REPORT: {
    title: "BEFORE_CURRENT_REPORT",
    description: "The stat applies from the start of the goal but does not include the current value",
  },
  LAST_7_DAYS: {
    title: "LAST_7_DAYS",
    description: "The stat applies to the last 7 days",
  },
  LAST_30_DAYS: {
    title: "LAST_30_DAYS",
    description: "The stat applies to the last 30 days",
  },
  LAST_MONTH: {
    title: "LAST_MONTH",
    description: "The stat applies to the previous month",
  },
  SINCE_GOAL_START: {
    title: "SINCE_GOAL_START",
    description: "The stat applies since the start of the goal",
  },
  THIS_MONTH: {
    title: "THIS_MONTH",
    description: "The stat applies to the current month",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetStatPeriodDescriptionT(value: ImplementationTargetStatPeriod, t: (t: `enums:ImplementationTargetStatPeriod.${keyof typeof resources["en"]["enums"]["ImplementationTargetStatPeriod"]}.description`) => string):string {
    switch (value) {
      case ImplementationTargetStatPeriod.BEFORE_CURRENT_REPORT: return t("enums:ImplementationTargetStatPeriod.BEFORE_CURRENT_REPORT.description");
case ImplementationTargetStatPeriod.LAST_7_DAYS: return t("enums:ImplementationTargetStatPeriod.LAST_7_DAYS.description");
case ImplementationTargetStatPeriod.LAST_30_DAYS: return t("enums:ImplementationTargetStatPeriod.LAST_30_DAYS.description");
case ImplementationTargetStatPeriod.LAST_MONTH: return t("enums:ImplementationTargetStatPeriod.LAST_MONTH.description");
case ImplementationTargetStatPeriod.SINCE_GOAL_START: return t("enums:ImplementationTargetStatPeriod.SINCE_GOAL_START.description");
case ImplementationTargetStatPeriod.THIS_MONTH: return t("enums:ImplementationTargetStatPeriod.THIS_MONTH.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ImplementationTargetStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "This target is active",
  },
  RETIRED: {
    title: "RETIRED",
    description: "This target is no longer active and has been retired",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetStatusT(value: ImplementationTargetStatus, t: (t: `enums:ImplementationTargetStatus.${keyof typeof resources["en"]["enums"]["ImplementationTargetStatus"]}.title`) => string):string {
    switch (value) {
      case ImplementationTargetStatus.ACTIVE: return t("enums:ImplementationTargetStatus.ACTIVE.title");
case ImplementationTargetStatus.RETIRED: return t("enums:ImplementationTargetStatus.RETIRED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ImplementationTargetStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "This target is active",
  },
  RETIRED: {
    title: "RETIRED",
    description: "This target is no longer active and has been retired",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetStatusDescriptionT(value: ImplementationTargetStatus, t: (t: `enums:ImplementationTargetStatus.${keyof typeof resources["en"]["enums"]["ImplementationTargetStatus"]}.description`) => string):string {
    switch (value) {
      case ImplementationTargetStatus.ACTIVE: return t("enums:ImplementationTargetStatus.ACTIVE.description");
case ImplementationTargetStatus.RETIRED: return t("enums:ImplementationTargetStatus.RETIRED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ImplementationTargetType: {
  COCM_MONTHLY_BILLED_MINUTES: {
    title: "COCM_MONTHLY_BILLED_MINUTES",
    description: "This target concerns how many minutes are being billed per month",
  },
  COCM_MONTHLY_BILLING_EFFICIENCY: {
    title: "COCM_MONTHLY_BILLING_EFFICIENCY",
    description: "This target the efficiency of billing, defined as minutes required to hit targets / minutes spent",
  },
  COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES: {
    title: "COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES",
    description: "This target concerns the sum of time code minutes",
  },
  COCM_MONTHLY_EXPECTED_BILLING: {
    title: "COCM_MONTHLY_EXPECTED_BILLING",
    description: "This target concerns how much revenue is being billed per month",
  },
  COCM_MONTHLY_EXPECTED_RVUS: {
    title: "COCM_MONTHLY_EXPECTED_RVUS",
    description: "This target concerns how many RVUs are being billed per month",
  },
  COCM_MONTHLY_EXPECTED_VALUE_UNITS: {
    title: "COCM_MONTHLY_EXPECTED_VALUE_UNITS",
    description: "This target concerns how many value units are being billed per month",
  },
  COCM_NEW_ENROLLMENTS: {
    title: "COCM_NEW_ENROLLMENTS",
    description: "This target concerns new CoCM enrollments",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetTypeT(value: ImplementationTargetType, t: (t: `enums:ImplementationTargetType.${keyof typeof resources["en"]["enums"]["ImplementationTargetType"]}.title`) => string):string {
    switch (value) {
      case ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES: return t("enums:ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES.title");
case ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY: return t("enums:ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY.title");
case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES: return t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES.title");
case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING: return t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING.title");
case ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS: return t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS.title");
case ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS: return t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS.title");
case ImplementationTargetType.COCM_NEW_ENROLLMENTS: return t("enums:ImplementationTargetType.COCM_NEW_ENROLLMENTS.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ImplementationTargetType: {
  COCM_MONTHLY_BILLED_MINUTES: {
    title: "COCM_MONTHLY_BILLED_MINUTES",
    description: "This target concerns how many minutes are being billed per month",
  },
  COCM_MONTHLY_BILLING_EFFICIENCY: {
    title: "COCM_MONTHLY_BILLING_EFFICIENCY",
    description: "This target the efficiency of billing, defined as minutes required to hit targets / minutes spent",
  },
  COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES: {
    title: "COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES",
    description: "This target concerns the sum of time code minutes",
  },
  COCM_MONTHLY_EXPECTED_BILLING: {
    title: "COCM_MONTHLY_EXPECTED_BILLING",
    description: "This target concerns how much revenue is being billed per month",
  },
  COCM_MONTHLY_EXPECTED_RVUS: {
    title: "COCM_MONTHLY_EXPECTED_RVUS",
    description: "This target concerns how many RVUs are being billed per month",
  },
  COCM_MONTHLY_EXPECTED_VALUE_UNITS: {
    title: "COCM_MONTHLY_EXPECTED_VALUE_UNITS",
    description: "This target concerns how many value units are being billed per month",
  },
  COCM_NEW_ENROLLMENTS: {
    title: "COCM_NEW_ENROLLMENTS",
    description: "This target concerns new CoCM enrollments",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function implementationTargetTypeDescriptionT(value: ImplementationTargetType, t: (t: `enums:ImplementationTargetType.${keyof typeof resources["en"]["enums"]["ImplementationTargetType"]}.description`) => string):string {
    switch (value) {
      case ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES: return t("enums:ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES.description");
case ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY: return t("enums:ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY.description");
case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES: return t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES.description");
case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING: return t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING.description");
case ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS: return t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS.description");
case ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS: return t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS.description");
case ImplementationTargetType.COCM_NEW_ENROLLMENTS: return t("enums:ImplementationTargetType.COCM_NEW_ENROLLMENTS.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  InvitationFormat: {
  INTERVIEW: {
    title: "INTERVIEW",
    description: "Interview, i.e. questionnaire is asked by provider",
  },
  SELF_REPORT: {
    title: "SELF_REPORT",
    description: "Self report, i.e. completed by the participant themselves",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function invitationFormatT(value: InvitationFormat, t: (t: `enums:InvitationFormat.${keyof typeof resources["en"]["enums"]["InvitationFormat"]}.title`) => string):string {
    switch (value) {
      case InvitationFormat.INTERVIEW: return t("enums:InvitationFormat.INTERVIEW.title");
case InvitationFormat.SELF_REPORT: return t("enums:InvitationFormat.SELF_REPORT.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  InvitationFormat: {
  INTERVIEW: {
    title: "INTERVIEW",
    description: "Interview, i.e. questionnaire is asked by provider",
  },
  SELF_REPORT: {
    title: "SELF_REPORT",
    description: "Self report, i.e. completed by the participant themselves",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function invitationFormatDescriptionT(value: InvitationFormat, t: (t: `enums:InvitationFormat.${keyof typeof resources["en"]["enums"]["InvitationFormat"]}.description`) => string):string {
    switch (value) {
      case InvitationFormat.INTERVIEW: return t("enums:InvitationFormat.INTERVIEW.description");
case InvitationFormat.SELF_REPORT: return t("enums:InvitationFormat.SELF_REPORT.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  InvitationPhase: {
  AFTER: {
    title: "AFTER",
    description: "After the target date",
  },
  BEFORE: {
    title: "BEFORE",
    description: "Before the target date",
  },
  DURING: {
    title: "DURING",
    description: "During the target date",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function invitationPhaseT(value: InvitationPhase, t: (t: `enums:InvitationPhase.${keyof typeof resources["en"]["enums"]["InvitationPhase"]}.title`) => string):string {
    switch (value) {
      case InvitationPhase.AFTER: return t("enums:InvitationPhase.AFTER.title");
case InvitationPhase.BEFORE: return t("enums:InvitationPhase.BEFORE.title");
case InvitationPhase.DURING: return t("enums:InvitationPhase.DURING.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  InvitationPhase: {
  AFTER: {
    title: "AFTER",
    description: "After the target date",
  },
  BEFORE: {
    title: "BEFORE",
    description: "Before the target date",
  },
  DURING: {
    title: "DURING",
    description: "During the target date",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function invitationPhaseDescriptionT(value: InvitationPhase, t: (t: `enums:InvitationPhase.${keyof typeof resources["en"]["enums"]["InvitationPhase"]}.description`) => string):string {
    switch (value) {
      case InvitationPhase.AFTER: return t("enums:InvitationPhase.AFTER.description");
case InvitationPhase.BEFORE: return t("enums:InvitationPhase.BEFORE.description");
case InvitationPhase.DURING: return t("enums:InvitationPhase.DURING.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  InvitationStatus: {
  BEGUN: {
    title: "BEGUN",
    description: "The invitation has had at least one question answered by the participant",
  },
  CANCELED: {
    title: "CANCELED",
    description: "The invitation was canceled",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "The invitation has been completed by the participant",
  },
  EXPIRED: {
    title: "EXPIRED",
    description: "The invitation was not started and has expired",
  },
  FUTURE: {
    title: "FUTURE",
    description: "The invitation is for the future and cannot be used yet",
  },
  LOCKED_OUT: {
    title: "LOCKED_OUT",
    description: "The invitation has been locked due to failed login attempts.",
  },
  MANUALLY_CANCELED: {
    title: "MANUALLY_CANCELED",
    description: "The invitation was canceled manually by a care manager",
  },
  NEW: {
    title: "NEW",
    description: "The invitation is open but is not configured to send any notifications",
  },
  NOT_REQUIRED: {
    title: "NOT_REQUIRED",
    description: "The invitation was not required",
  },
  OPENED: {
    title: "OPENED",
    description: "The invitation has been opened by the participant",
  },
  PARTIAL: {
    title: "PARTIAL",
    description: "The invitation was started but not completed and has now expired",
  },
  SENT: {
    title: "SENT",
    description: "The invitation is open for feedback and notification have been sent",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function invitationStatusT(value: InvitationStatus, t: (t: `enums:InvitationStatus.${keyof typeof resources["en"]["enums"]["InvitationStatus"]}.title`) => string):string {
    switch (value) {
      case InvitationStatus.BEGUN: return t("enums:InvitationStatus.BEGUN.title");
case InvitationStatus.CANCELED: return t("enums:InvitationStatus.CANCELED.title");
case InvitationStatus.COMPLETE: return t("enums:InvitationStatus.COMPLETE.title");
case InvitationStatus.EXPIRED: return t("enums:InvitationStatus.EXPIRED.title");
case InvitationStatus.FUTURE: return t("enums:InvitationStatus.FUTURE.title");
case InvitationStatus.LOCKED_OUT: return t("enums:InvitationStatus.LOCKED_OUT.title");
case InvitationStatus.MANUALLY_CANCELED: return t("enums:InvitationStatus.MANUALLY_CANCELED.title");
case InvitationStatus.NEW: return t("enums:InvitationStatus.NEW.title");
case InvitationStatus.NOT_REQUIRED: return t("enums:InvitationStatus.NOT_REQUIRED.title");
case InvitationStatus.OPENED: return t("enums:InvitationStatus.OPENED.title");
case InvitationStatus.PARTIAL: return t("enums:InvitationStatus.PARTIAL.title");
case InvitationStatus.SENT: return t("enums:InvitationStatus.SENT.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  InvitationStatus: {
  BEGUN: {
    title: "BEGUN",
    description: "The invitation has had at least one question answered by the participant",
  },
  CANCELED: {
    title: "CANCELED",
    description: "The invitation was canceled",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "The invitation has been completed by the participant",
  },
  EXPIRED: {
    title: "EXPIRED",
    description: "The invitation was not started and has expired",
  },
  FUTURE: {
    title: "FUTURE",
    description: "The invitation is for the future and cannot be used yet",
  },
  LOCKED_OUT: {
    title: "LOCKED_OUT",
    description: "The invitation has been locked due to failed login attempts.",
  },
  MANUALLY_CANCELED: {
    title: "MANUALLY_CANCELED",
    description: "The invitation was canceled manually by a care manager",
  },
  NEW: {
    title: "NEW",
    description: "The invitation is open but is not configured to send any notifications",
  },
  NOT_REQUIRED: {
    title: "NOT_REQUIRED",
    description: "The invitation was not required",
  },
  OPENED: {
    title: "OPENED",
    description: "The invitation has been opened by the participant",
  },
  PARTIAL: {
    title: "PARTIAL",
    description: "The invitation was started but not completed and has now expired",
  },
  SENT: {
    title: "SENT",
    description: "The invitation is open for feedback and notification have been sent",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function invitationStatusDescriptionT(value: InvitationStatus, t: (t: `enums:InvitationStatus.${keyof typeof resources["en"]["enums"]["InvitationStatus"]}.description`) => string):string {
    switch (value) {
      case InvitationStatus.BEGUN: return t("enums:InvitationStatus.BEGUN.description");
case InvitationStatus.CANCELED: return t("enums:InvitationStatus.CANCELED.description");
case InvitationStatus.COMPLETE: return t("enums:InvitationStatus.COMPLETE.description");
case InvitationStatus.EXPIRED: return t("enums:InvitationStatus.EXPIRED.description");
case InvitationStatus.FUTURE: return t("enums:InvitationStatus.FUTURE.description");
case InvitationStatus.LOCKED_OUT: return t("enums:InvitationStatus.LOCKED_OUT.description");
case InvitationStatus.MANUALLY_CANCELED: return t("enums:InvitationStatus.MANUALLY_CANCELED.description");
case InvitationStatus.NEW: return t("enums:InvitationStatus.NEW.description");
case InvitationStatus.NOT_REQUIRED: return t("enums:InvitationStatus.NOT_REQUIRED.description");
case InvitationStatus.OPENED: return t("enums:InvitationStatus.OPENED.description");
case InvitationStatus.PARTIAL: return t("enums:InvitationStatus.PARTIAL.description");
case InvitationStatus.SENT: return t("enums:InvitationStatus.SENT.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  NotificationPreference: {
  BOTH: {
    title: "BOTH",
    description: "Send both text message and email notifications",
  },
  EMAIL: {
    title: "EMAIL",
    description: "Only send email notifications",
  },
  NONE: {
    title: "NONE",
    description: "Do not send any notifications",
  },
  TEXT: {
    title: "TEXT",
    description: "Only send text message notifications",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function notificationPreferenceT(value: NotificationPreference, t: (t: `enums:NotificationPreference.${keyof typeof resources["en"]["enums"]["NotificationPreference"]}.title`) => string):string {
    switch (value) {
      case NotificationPreference.BOTH: return t("enums:NotificationPreference.BOTH.title");
case NotificationPreference.EMAIL: return t("enums:NotificationPreference.EMAIL.title");
case NotificationPreference.NONE: return t("enums:NotificationPreference.NONE.title");
case NotificationPreference.TEXT: return t("enums:NotificationPreference.TEXT.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  NotificationPreference: {
  BOTH: {
    title: "BOTH",
    description: "Send both text message and email notifications",
  },
  EMAIL: {
    title: "EMAIL",
    description: "Only send email notifications",
  },
  NONE: {
    title: "NONE",
    description: "Do not send any notifications",
  },
  TEXT: {
    title: "TEXT",
    description: "Only send text message notifications",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function notificationPreferenceDescriptionT(value: NotificationPreference, t: (t: `enums:NotificationPreference.${keyof typeof resources["en"]["enums"]["NotificationPreference"]}.description`) => string):string {
    switch (value) {
      case NotificationPreference.BOTH: return t("enums:NotificationPreference.BOTH.description");
case NotificationPreference.EMAIL: return t("enums:NotificationPreference.EMAIL.description");
case NotificationPreference.NONE: return t("enums:NotificationPreference.NONE.description");
case NotificationPreference.TEXT: return t("enums:NotificationPreference.TEXT.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  OncePerPeriod: {
  CALENDAR_MONTH: {
    title: "CALENDAR_MONTH",
    description: "Administer only once per calendar month",
  },
  CALENDAR_QUARTER: {
    title: "CALENDAR_QUARTER",
    description: "Administer only once per calendar quarter",
  },
  CALENDAR_YEAR: {
    title: "CALENDAR_YEAR",
    description: "Administer only once per calendar year",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function oncePerPeriodT(value: OncePerPeriod, t: (t: `enums:OncePerPeriod.${keyof typeof resources["en"]["enums"]["OncePerPeriod"]}.title`) => string):string {
    switch (value) {
      case OncePerPeriod.CALENDAR_MONTH: return t("enums:OncePerPeriod.CALENDAR_MONTH.title");
case OncePerPeriod.CALENDAR_QUARTER: return t("enums:OncePerPeriod.CALENDAR_QUARTER.title");
case OncePerPeriod.CALENDAR_YEAR: return t("enums:OncePerPeriod.CALENDAR_YEAR.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  OncePerPeriod: {
  CALENDAR_MONTH: {
    title: "CALENDAR_MONTH",
    description: "Administer only once per calendar month",
  },
  CALENDAR_QUARTER: {
    title: "CALENDAR_QUARTER",
    description: "Administer only once per calendar quarter",
  },
  CALENDAR_YEAR: {
    title: "CALENDAR_YEAR",
    description: "Administer only once per calendar year",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function oncePerPeriodDescriptionT(value: OncePerPeriod, t: (t: `enums:OncePerPeriod.${keyof typeof resources["en"]["enums"]["OncePerPeriod"]}.description`) => string):string {
    switch (value) {
      case OncePerPeriod.CALENDAR_MONTH: return t("enums:OncePerPeriod.CALENDAR_MONTH.description");
case OncePerPeriod.CALENDAR_QUARTER: return t("enums:OncePerPeriod.CALENDAR_QUARTER.description");
case OncePerPeriod.CALENDAR_YEAR: return t("enums:OncePerPeriod.CALENDAR_YEAR.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  PanelTeamMemberFunction: {
  BEHAVIOR_HEALTH_CONSULTANT: {
    title: "BEHAVIOR_HEALTH_CONSULTANT",
    description: "Behavioral Health Consultant",
  },
  CARE_MANAGER: {
    title: "CARE_MANAGER",
    description: "Care Manager",
  },
  CONSULTING_PSYCH: {
    title: "CONSULTING_PSYCH",
    description: "Consulting Psychologist",
  },
  PRIMARY_CARE: {
    title: "PRIMARY_CARE",
    description: "Primary Care Provider",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function panelTeamMemberFunctionT(value: PanelTeamMemberFunction, t: (t: `enums:PanelTeamMemberFunction.${keyof typeof resources["en"]["enums"]["PanelTeamMemberFunction"]}.title`) => string):string {
    switch (value) {
      case PanelTeamMemberFunction.BEHAVIOR_HEALTH_CONSULTANT: return t("enums:PanelTeamMemberFunction.BEHAVIOR_HEALTH_CONSULTANT.title");
case PanelTeamMemberFunction.CARE_MANAGER: return t("enums:PanelTeamMemberFunction.CARE_MANAGER.title");
case PanelTeamMemberFunction.CONSULTING_PSYCH: return t("enums:PanelTeamMemberFunction.CONSULTING_PSYCH.title");
case PanelTeamMemberFunction.PRIMARY_CARE: return t("enums:PanelTeamMemberFunction.PRIMARY_CARE.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  PanelTeamMemberFunction: {
  BEHAVIOR_HEALTH_CONSULTANT: {
    title: "BEHAVIOR_HEALTH_CONSULTANT",
    description: "Behavioral Health Consultant",
  },
  CARE_MANAGER: {
    title: "CARE_MANAGER",
    description: "Care Manager",
  },
  CONSULTING_PSYCH: {
    title: "CONSULTING_PSYCH",
    description: "Consulting Psychologist",
  },
  PRIMARY_CARE: {
    title: "PRIMARY_CARE",
    description: "Primary Care Provider",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function panelTeamMemberFunctionDescriptionT(value: PanelTeamMemberFunction, t: (t: `enums:PanelTeamMemberFunction.${keyof typeof resources["en"]["enums"]["PanelTeamMemberFunction"]}.description`) => string):string {
    switch (value) {
      case PanelTeamMemberFunction.BEHAVIOR_HEALTH_CONSULTANT: return t("enums:PanelTeamMemberFunction.BEHAVIOR_HEALTH_CONSULTANT.description");
case PanelTeamMemberFunction.CARE_MANAGER: return t("enums:PanelTeamMemberFunction.CARE_MANAGER.description");
case PanelTeamMemberFunction.CONSULTING_PSYCH: return t("enums:PanelTeamMemberFunction.CONSULTING_PSYCH.description");
case PanelTeamMemberFunction.PRIMARY_CARE: return t("enums:PanelTeamMemberFunction.PRIMARY_CARE.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  PatientConditionStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Condition is active",
  },
  INACTIVE: {
    title: "INACTIVE",
    description: "Condition is inactive",
  },
  RECURRENCE: {
    title: "RECURRENCE",
    description: "Condition is active and has recurred",
  },
  REMISSION: {
    title: "REMISSION",
    description: "Condition is in remission",
  },
  RESOLVED: {
    title: "RESOLVED",
    description: "Condition is resolved",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function patientConditionStatusT(value: PatientConditionStatus, t: (t: `enums:PatientConditionStatus.${keyof typeof resources["en"]["enums"]["PatientConditionStatus"]}.title`) => string):string {
    switch (value) {
      case PatientConditionStatus.ACTIVE: return t("enums:PatientConditionStatus.ACTIVE.title");
case PatientConditionStatus.INACTIVE: return t("enums:PatientConditionStatus.INACTIVE.title");
case PatientConditionStatus.RECURRENCE: return t("enums:PatientConditionStatus.RECURRENCE.title");
case PatientConditionStatus.REMISSION: return t("enums:PatientConditionStatus.REMISSION.title");
case PatientConditionStatus.RESOLVED: return t("enums:PatientConditionStatus.RESOLVED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  PatientConditionStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Condition is active",
  },
  INACTIVE: {
    title: "INACTIVE",
    description: "Condition is inactive",
  },
  RECURRENCE: {
    title: "RECURRENCE",
    description: "Condition is active and has recurred",
  },
  REMISSION: {
    title: "REMISSION",
    description: "Condition is in remission",
  },
  RESOLVED: {
    title: "RESOLVED",
    description: "Condition is resolved",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function patientConditionStatusDescriptionT(value: PatientConditionStatus, t: (t: `enums:PatientConditionStatus.${keyof typeof resources["en"]["enums"]["PatientConditionStatus"]}.description`) => string):string {
    switch (value) {
      case PatientConditionStatus.ACTIVE: return t("enums:PatientConditionStatus.ACTIVE.description");
case PatientConditionStatus.INACTIVE: return t("enums:PatientConditionStatus.INACTIVE.description");
case PatientConditionStatus.RECURRENCE: return t("enums:PatientConditionStatus.RECURRENCE.description");
case PatientConditionStatus.REMISSION: return t("enums:PatientConditionStatus.REMISSION.description");
case PatientConditionStatus.RESOLVED: return t("enums:PatientConditionStatus.RESOLVED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  PatientQualityIssueStatus: {
  FIXED_IN_EHR: {
    title: "FIXED_IN_EHR",
    description: "It's been marked as fixed in the EHR, but hasn't come through to us yet.",
  },
  NOT_AN_ISSUE: {
    title: "NOT_AN_ISSUE",
    description: "This is not an issue",
  },
  OUT_OF_TREATMENT: {
    title: "OUT_OF_TREATMENT",
    description: "This patient is not in treatment and therefore the flag was cleared",
  },
  RESOLVED: {
    title: "RESOLVED",
    description: "All is fixed",
  },
  SNOOZE: {
    title: "SNOOZE",
    description: "Not resolved but come back later",
  },
  UNRESOLVED: {
    title: "UNRESOLVED",
    description: "It hasn't been looked at",
  },
  WONT_FIX: {
    title: "WONT_FIX",
    description: "This IS an issue, but it's too difficult to fix",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function patientQualityIssueStatusT(value: PatientQualityIssueStatus, t: (t: `enums:PatientQualityIssueStatus.${keyof typeof resources["en"]["enums"]["PatientQualityIssueStatus"]}.title`) => string):string {
    switch (value) {
      case PatientQualityIssueStatus.FIXED_IN_EHR: return t("enums:PatientQualityIssueStatus.FIXED_IN_EHR.title");
case PatientQualityIssueStatus.NOT_AN_ISSUE: return t("enums:PatientQualityIssueStatus.NOT_AN_ISSUE.title");
case PatientQualityIssueStatus.OUT_OF_TREATMENT: return t("enums:PatientQualityIssueStatus.OUT_OF_TREATMENT.title");
case PatientQualityIssueStatus.RESOLVED: return t("enums:PatientQualityIssueStatus.RESOLVED.title");
case PatientQualityIssueStatus.SNOOZE: return t("enums:PatientQualityIssueStatus.SNOOZE.title");
case PatientQualityIssueStatus.UNRESOLVED: return t("enums:PatientQualityIssueStatus.UNRESOLVED.title");
case PatientQualityIssueStatus.WONT_FIX: return t("enums:PatientQualityIssueStatus.WONT_FIX.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  PatientQualityIssueStatus: {
  FIXED_IN_EHR: {
    title: "FIXED_IN_EHR",
    description: "It's been marked as fixed in the EHR, but hasn't come through to us yet.",
  },
  NOT_AN_ISSUE: {
    title: "NOT_AN_ISSUE",
    description: "This is not an issue",
  },
  OUT_OF_TREATMENT: {
    title: "OUT_OF_TREATMENT",
    description: "This patient is not in treatment and therefore the flag was cleared",
  },
  RESOLVED: {
    title: "RESOLVED",
    description: "All is fixed",
  },
  SNOOZE: {
    title: "SNOOZE",
    description: "Not resolved but come back later",
  },
  UNRESOLVED: {
    title: "UNRESOLVED",
    description: "It hasn't been looked at",
  },
  WONT_FIX: {
    title: "WONT_FIX",
    description: "This IS an issue, but it's too difficult to fix",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function patientQualityIssueStatusDescriptionT(value: PatientQualityIssueStatus, t: (t: `enums:PatientQualityIssueStatus.${keyof typeof resources["en"]["enums"]["PatientQualityIssueStatus"]}.description`) => string):string {
    switch (value) {
      case PatientQualityIssueStatus.FIXED_IN_EHR: return t("enums:PatientQualityIssueStatus.FIXED_IN_EHR.description");
case PatientQualityIssueStatus.NOT_AN_ISSUE: return t("enums:PatientQualityIssueStatus.NOT_AN_ISSUE.description");
case PatientQualityIssueStatus.OUT_OF_TREATMENT: return t("enums:PatientQualityIssueStatus.OUT_OF_TREATMENT.description");
case PatientQualityIssueStatus.RESOLVED: return t("enums:PatientQualityIssueStatus.RESOLVED.description");
case PatientQualityIssueStatus.SNOOZE: return t("enums:PatientQualityIssueStatus.SNOOZE.description");
case PatientQualityIssueStatus.UNRESOLVED: return t("enums:PatientQualityIssueStatus.UNRESOLVED.description");
case PatientQualityIssueStatus.WONT_FIX: return t("enums:PatientQualityIssueStatus.WONT_FIX.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  PatientQualityIssueType: {
  AGE_INCORRECT: {
    title: "AGE_INCORRECT",
    description: "Age may be incorrect",
  },
  CAREGIVER_CONTACT_FORBIDDEN: {
    title: "CAREGIVER_CONTACT_FORBIDDEN",
    description: "Caregiver has forbidden contact",
  },
  CAREGIVER_NEVER_OPENED: {
    title: "CAREGIVER_NEVER_OPENED",
    description: "Caregiver has never opened an assessment",
  },
  CAREGIVER_NO_CONTACT_INFO: {
    title: "CAREGIVER_NO_CONTACT_INFO",
    description: "Caregiver has no contact info",
  },
  CAREGIVER_SHARES_CONTACT_INFO: {
    title: "CAREGIVER_SHARES_CONTACT_INFO",
    description: "Patient and caregiver share contact info",
  },
  NOT_MEASURED_FOR_EXTENDED_PERIOD: {
    title: "NOT_MEASURED_FOR_EXTENDED_PERIOD",
    description: "No assessments have been taken in a while",
  },
  PATIENT_NEVER_OPENED: {
    title: "PATIENT_NEVER_OPENED",
    description: "Patient has never opened an assessment",
  },
  PATIENT_OVER_11_CONTACT_FORBIDDEN: {
    title: "PATIENT_OVER_11_CONTACT_FORBIDDEN",
    description: "Patient has forbidden contact",
  },
  PATIENT_OVER_11_NO_CONTACT_INFO: {
    title: "PATIENT_OVER_11_NO_CONTACT_INFO",
    description: "Patient has no contact info",
  },
  PATIENT_UNDER_18_NO_CAREGIVER: {
    title: "PATIENT_UNDER_18_NO_CAREGIVER",
    description: "Child patient has no caregiver",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function patientQualityIssueTypeT(value: PatientQualityIssueType, t: (t: `enums:PatientQualityIssueType.${keyof typeof resources["en"]["enums"]["PatientQualityIssueType"]}.title`) => string):string {
    switch (value) {
      case PatientQualityIssueType.AGE_INCORRECT: return t("enums:PatientQualityIssueType.AGE_INCORRECT.title");
case PatientQualityIssueType.CAREGIVER_CONTACT_FORBIDDEN: return t("enums:PatientQualityIssueType.CAREGIVER_CONTACT_FORBIDDEN.title");
case PatientQualityIssueType.CAREGIVER_NEVER_OPENED: return t("enums:PatientQualityIssueType.CAREGIVER_NEVER_OPENED.title");
case PatientQualityIssueType.CAREGIVER_NO_CONTACT_INFO: return t("enums:PatientQualityIssueType.CAREGIVER_NO_CONTACT_INFO.title");
case PatientQualityIssueType.CAREGIVER_SHARES_CONTACT_INFO: return t("enums:PatientQualityIssueType.CAREGIVER_SHARES_CONTACT_INFO.title");
case PatientQualityIssueType.NOT_MEASURED_FOR_EXTENDED_PERIOD: return t("enums:PatientQualityIssueType.NOT_MEASURED_FOR_EXTENDED_PERIOD.title");
case PatientQualityIssueType.PATIENT_NEVER_OPENED: return t("enums:PatientQualityIssueType.PATIENT_NEVER_OPENED.title");
case PatientQualityIssueType.PATIENT_OVER_11_CONTACT_FORBIDDEN: return t("enums:PatientQualityIssueType.PATIENT_OVER_11_CONTACT_FORBIDDEN.title");
case PatientQualityIssueType.PATIENT_OVER_11_NO_CONTACT_INFO: return t("enums:PatientQualityIssueType.PATIENT_OVER_11_NO_CONTACT_INFO.title");
case PatientQualityIssueType.PATIENT_UNDER_18_NO_CAREGIVER: return t("enums:PatientQualityIssueType.PATIENT_UNDER_18_NO_CAREGIVER.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  PatientQualityIssueType: {
  AGE_INCORRECT: {
    title: "AGE_INCORRECT",
    description: "Age may be incorrect",
  },
  CAREGIVER_CONTACT_FORBIDDEN: {
    title: "CAREGIVER_CONTACT_FORBIDDEN",
    description: "Caregiver has forbidden contact",
  },
  CAREGIVER_NEVER_OPENED: {
    title: "CAREGIVER_NEVER_OPENED",
    description: "Caregiver has never opened an assessment",
  },
  CAREGIVER_NO_CONTACT_INFO: {
    title: "CAREGIVER_NO_CONTACT_INFO",
    description: "Caregiver has no contact info",
  },
  CAREGIVER_SHARES_CONTACT_INFO: {
    title: "CAREGIVER_SHARES_CONTACT_INFO",
    description: "Patient and caregiver share contact info",
  },
  NOT_MEASURED_FOR_EXTENDED_PERIOD: {
    title: "NOT_MEASURED_FOR_EXTENDED_PERIOD",
    description: "No assessments have been taken in a while",
  },
  PATIENT_NEVER_OPENED: {
    title: "PATIENT_NEVER_OPENED",
    description: "Patient has never opened an assessment",
  },
  PATIENT_OVER_11_CONTACT_FORBIDDEN: {
    title: "PATIENT_OVER_11_CONTACT_FORBIDDEN",
    description: "Patient has forbidden contact",
  },
  PATIENT_OVER_11_NO_CONTACT_INFO: {
    title: "PATIENT_OVER_11_NO_CONTACT_INFO",
    description: "Patient has no contact info",
  },
  PATIENT_UNDER_18_NO_CAREGIVER: {
    title: "PATIENT_UNDER_18_NO_CAREGIVER",
    description: "Child patient has no caregiver",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function patientQualityIssueTypeDescriptionT(value: PatientQualityIssueType, t: (t: `enums:PatientQualityIssueType.${keyof typeof resources["en"]["enums"]["PatientQualityIssueType"]}.description`) => string):string {
    switch (value) {
      case PatientQualityIssueType.AGE_INCORRECT: return t("enums:PatientQualityIssueType.AGE_INCORRECT.description");
case PatientQualityIssueType.CAREGIVER_CONTACT_FORBIDDEN: return t("enums:PatientQualityIssueType.CAREGIVER_CONTACT_FORBIDDEN.description");
case PatientQualityIssueType.CAREGIVER_NEVER_OPENED: return t("enums:PatientQualityIssueType.CAREGIVER_NEVER_OPENED.description");
case PatientQualityIssueType.CAREGIVER_NO_CONTACT_INFO: return t("enums:PatientQualityIssueType.CAREGIVER_NO_CONTACT_INFO.description");
case PatientQualityIssueType.CAREGIVER_SHARES_CONTACT_INFO: return t("enums:PatientQualityIssueType.CAREGIVER_SHARES_CONTACT_INFO.description");
case PatientQualityIssueType.NOT_MEASURED_FOR_EXTENDED_PERIOD: return t("enums:PatientQualityIssueType.NOT_MEASURED_FOR_EXTENDED_PERIOD.description");
case PatientQualityIssueType.PATIENT_NEVER_OPENED: return t("enums:PatientQualityIssueType.PATIENT_NEVER_OPENED.description");
case PatientQualityIssueType.PATIENT_OVER_11_CONTACT_FORBIDDEN: return t("enums:PatientQualityIssueType.PATIENT_OVER_11_CONTACT_FORBIDDEN.description");
case PatientQualityIssueType.PATIENT_OVER_11_NO_CONTACT_INFO: return t("enums:PatientQualityIssueType.PATIENT_OVER_11_NO_CONTACT_INFO.description");
case PatientQualityIssueType.PATIENT_UNDER_18_NO_CAREGIVER: return t("enums:PatientQualityIssueType.PATIENT_UNDER_18_NO_CAREGIVER.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  RecurrenceFrequency: {
  ANNUALLY: {
    title: "ANNUALLY",
    description: "The appointment repeats anually",
  },
  BIANNUALLY: {
    title: "BIANNUALLY",
    description: "The appointment repeats twice per year",
  },
  BIMONTHLY: {
    title: "BIMONTHLY",
    description: "The appointment repeats every two months",
  },
  BIWEEKLY: {
    title: "BIWEEKLY",
    description: "The appointment repeats every two weeks",
  },
  DAILY: {
    title: "DAILY",
    description: "The appointment repeats every day",
  },
  MONTHLY: {
    title: "MONTHLY",
    description: "The appointment repeats monthly",
  },
  NONE: {
    title: "NONE",
    description: "The appointment does not repeat",
  },
  QUARTERLY: {
    title: "QUARTERLY",
    description: "The appointment repeats once per quarter",
  },
  WEEKDAYS: {
    title: "WEEKDAYS",
    description: "The appointment repeats every weekday",
  },
  WEEKLY: {
    title: "WEEKLY",
    description: "The appointment repeats once per week",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function recurrenceFrequencyT(value: RecurrenceFrequency, t: (t: `enums:RecurrenceFrequency.${keyof typeof resources["en"]["enums"]["RecurrenceFrequency"]}.title`) => string):string {
    switch (value) {
      case RecurrenceFrequency.ANNUALLY: return t("enums:RecurrenceFrequency.ANNUALLY.title");
case RecurrenceFrequency.BIANNUALLY: return t("enums:RecurrenceFrequency.BIANNUALLY.title");
case RecurrenceFrequency.BIMONTHLY: return t("enums:RecurrenceFrequency.BIMONTHLY.title");
case RecurrenceFrequency.BIWEEKLY: return t("enums:RecurrenceFrequency.BIWEEKLY.title");
case RecurrenceFrequency.DAILY: return t("enums:RecurrenceFrequency.DAILY.title");
case RecurrenceFrequency.MONTHLY: return t("enums:RecurrenceFrequency.MONTHLY.title");
case RecurrenceFrequency.NONE: return t("enums:RecurrenceFrequency.NONE.title");
case RecurrenceFrequency.QUARTERLY: return t("enums:RecurrenceFrequency.QUARTERLY.title");
case RecurrenceFrequency.WEEKDAYS: return t("enums:RecurrenceFrequency.WEEKDAYS.title");
case RecurrenceFrequency.WEEKLY: return t("enums:RecurrenceFrequency.WEEKLY.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  RecurrenceFrequency: {
  ANNUALLY: {
    title: "ANNUALLY",
    description: "The appointment repeats anually",
  },
  BIANNUALLY: {
    title: "BIANNUALLY",
    description: "The appointment repeats twice per year",
  },
  BIMONTHLY: {
    title: "BIMONTHLY",
    description: "The appointment repeats every two months",
  },
  BIWEEKLY: {
    title: "BIWEEKLY",
    description: "The appointment repeats every two weeks",
  },
  DAILY: {
    title: "DAILY",
    description: "The appointment repeats every day",
  },
  MONTHLY: {
    title: "MONTHLY",
    description: "The appointment repeats monthly",
  },
  NONE: {
    title: "NONE",
    description: "The appointment does not repeat",
  },
  QUARTERLY: {
    title: "QUARTERLY",
    description: "The appointment repeats once per quarter",
  },
  WEEKDAYS: {
    title: "WEEKDAYS",
    description: "The appointment repeats every weekday",
  },
  WEEKLY: {
    title: "WEEKLY",
    description: "The appointment repeats once per week",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function recurrenceFrequencyDescriptionT(value: RecurrenceFrequency, t: (t: `enums:RecurrenceFrequency.${keyof typeof resources["en"]["enums"]["RecurrenceFrequency"]}.description`) => string):string {
    switch (value) {
      case RecurrenceFrequency.ANNUALLY: return t("enums:RecurrenceFrequency.ANNUALLY.description");
case RecurrenceFrequency.BIANNUALLY: return t("enums:RecurrenceFrequency.BIANNUALLY.description");
case RecurrenceFrequency.BIMONTHLY: return t("enums:RecurrenceFrequency.BIMONTHLY.description");
case RecurrenceFrequency.BIWEEKLY: return t("enums:RecurrenceFrequency.BIWEEKLY.description");
case RecurrenceFrequency.DAILY: return t("enums:RecurrenceFrequency.DAILY.description");
case RecurrenceFrequency.MONTHLY: return t("enums:RecurrenceFrequency.MONTHLY.description");
case RecurrenceFrequency.NONE: return t("enums:RecurrenceFrequency.NONE.description");
case RecurrenceFrequency.QUARTERLY: return t("enums:RecurrenceFrequency.QUARTERLY.description");
case RecurrenceFrequency.WEEKDAYS: return t("enums:RecurrenceFrequency.WEEKDAYS.description");
case RecurrenceFrequency.WEEKLY: return t("enums:RecurrenceFrequency.WEEKLY.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  RelatedPersonRelationship: {
  CHILD: {
    title: "CHILD",
    description: "Child",
  },
  FATHER: {
    title: "FATHER",
    description: "Father",
  },
  GUARDIAN: {
    title: "GUARDIAN",
    description: "Guardian/caregiver",
  },
  MOTHER: {
    title: "MOTHER",
    description: "Mother",
  },
  OTHER: {
    title: "OTHER",
    description: "Other",
  },
  TEACHER: {
    title: "TEACHER",
    description: "Teacher",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function relatedPersonRelationshipT(value: RelatedPersonRelationship, t: (t: `enums:RelatedPersonRelationship.${keyof typeof resources["en"]["enums"]["RelatedPersonRelationship"]}.title`) => string):string {
    switch (value) {
      case RelatedPersonRelationship.CHILD: return t("enums:RelatedPersonRelationship.CHILD.title");
case RelatedPersonRelationship.FATHER: return t("enums:RelatedPersonRelationship.FATHER.title");
case RelatedPersonRelationship.GUARDIAN: return t("enums:RelatedPersonRelationship.GUARDIAN.title");
case RelatedPersonRelationship.MOTHER: return t("enums:RelatedPersonRelationship.MOTHER.title");
case RelatedPersonRelationship.OTHER: return t("enums:RelatedPersonRelationship.OTHER.title");
case RelatedPersonRelationship.TEACHER: return t("enums:RelatedPersonRelationship.TEACHER.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  RelatedPersonRelationship: {
  CHILD: {
    title: "CHILD",
    description: "Child",
  },
  FATHER: {
    title: "FATHER",
    description: "Father",
  },
  GUARDIAN: {
    title: "GUARDIAN",
    description: "Guardian/caregiver",
  },
  MOTHER: {
    title: "MOTHER",
    description: "Mother",
  },
  OTHER: {
    title: "OTHER",
    description: "Other",
  },
  TEACHER: {
    title: "TEACHER",
    description: "Teacher",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function relatedPersonRelationshipDescriptionT(value: RelatedPersonRelationship, t: (t: `enums:RelatedPersonRelationship.${keyof typeof resources["en"]["enums"]["RelatedPersonRelationship"]}.description`) => string):string {
    switch (value) {
      case RelatedPersonRelationship.CHILD: return t("enums:RelatedPersonRelationship.CHILD.description");
case RelatedPersonRelationship.FATHER: return t("enums:RelatedPersonRelationship.FATHER.description");
case RelatedPersonRelationship.GUARDIAN: return t("enums:RelatedPersonRelationship.GUARDIAN.description");
case RelatedPersonRelationship.MOTHER: return t("enums:RelatedPersonRelationship.MOTHER.description");
case RelatedPersonRelationship.OTHER: return t("enums:RelatedPersonRelationship.OTHER.description");
case RelatedPersonRelationship.TEACHER: return t("enums:RelatedPersonRelationship.TEACHER.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ScaleLocale: {
  EN: {
    title: "EN",
    description: "English",
  },
  ES: {
    title: "ES",
    description: "Spanish",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function scaleLocaleT(value: ScaleLocale, t: (t: `enums:ScaleLocale.${keyof typeof resources["en"]["enums"]["ScaleLocale"]}.title`) => string):string {
    switch (value) {
      case ScaleLocale.EN: return t("enums:ScaleLocale.EN.title");
case ScaleLocale.ES: return t("enums:ScaleLocale.ES.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ScaleLocale: {
  EN: {
    title: "EN",
    description: "English",
  },
  ES: {
    title: "ES",
    description: "Spanish",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function scaleLocaleDescriptionT(value: ScaleLocale, t: (t: `enums:ScaleLocale.${keyof typeof resources["en"]["enums"]["ScaleLocale"]}.description`) => string):string {
    switch (value) {
      case ScaleLocale.EN: return t("enums:ScaleLocale.EN.description");
case ScaleLocale.ES: return t("enums:ScaleLocale.ES.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ScaleTargeting: {
  GENERAL_FUNCTIONING: {
    title: "GENERAL_FUNCTIONING",
    description: "This scale measures general functioning",
  },
  HEADLINE: {
    title: "HEADLINE",
    description: "This scale is single most important target. Only one per care episode",
  },
  NOT_TARGETED: {
    title: "NOT_TARGETED",
    description: "This scale is not the target of treatment",
  },
  SUBSCALE_OF_TARGET: {
    title: "SUBSCALE_OF_TARGET",
    description: "This scale is a subscale of a targeted scale",
  },
  TARGETED: {
    title: "TARGETED",
    description: "This scale is the target of treatment",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function scaleTargetingT(value: ScaleTargeting, t: (t: `enums:ScaleTargeting.${keyof typeof resources["en"]["enums"]["ScaleTargeting"]}.title`) => string):string {
    switch (value) {
      case ScaleTargeting.GENERAL_FUNCTIONING: return t("enums:ScaleTargeting.GENERAL_FUNCTIONING.title");
case ScaleTargeting.HEADLINE: return t("enums:ScaleTargeting.HEADLINE.title");
case ScaleTargeting.NOT_TARGETED: return t("enums:ScaleTargeting.NOT_TARGETED.title");
case ScaleTargeting.SUBSCALE_OF_TARGET: return t("enums:ScaleTargeting.SUBSCALE_OF_TARGET.title");
case ScaleTargeting.TARGETED: return t("enums:ScaleTargeting.TARGETED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ScaleTargeting: {
  GENERAL_FUNCTIONING: {
    title: "GENERAL_FUNCTIONING",
    description: "This scale measures general functioning",
  },
  HEADLINE: {
    title: "HEADLINE",
    description: "This scale is single most important target. Only one per care episode",
  },
  NOT_TARGETED: {
    title: "NOT_TARGETED",
    description: "This scale is not the target of treatment",
  },
  SUBSCALE_OF_TARGET: {
    title: "SUBSCALE_OF_TARGET",
    description: "This scale is a subscale of a targeted scale",
  },
  TARGETED: {
    title: "TARGETED",
    description: "This scale is the target of treatment",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function scaleTargetingDescriptionT(value: ScaleTargeting, t: (t: `enums:ScaleTargeting.${keyof typeof resources["en"]["enums"]["ScaleTargeting"]}.description`) => string):string {
    switch (value) {
      case ScaleTargeting.GENERAL_FUNCTIONING: return t("enums:ScaleTargeting.GENERAL_FUNCTIONING.description");
case ScaleTargeting.HEADLINE: return t("enums:ScaleTargeting.HEADLINE.description");
case ScaleTargeting.NOT_TARGETED: return t("enums:ScaleTargeting.NOT_TARGETED.description");
case ScaleTargeting.SUBSCALE_OF_TARGET: return t("enums:ScaleTargeting.SUBSCALE_OF_TARGET.description");
case ScaleTargeting.TARGETED: return t("enums:ScaleTargeting.TARGETED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  ScaleThresholdClass: {
  CONTINUOUS: {
    title: "CONTINUOUS",
    description: "Continuous",
  },
  LOW: {
    title: "LOW",
    description: "low",
  },
  MILD: {
    title: "MILD",
    description: "mild",
  },
  MODERATE: {
    title: "MODERATE",
    description: "Moderate ",
  },
  MOD_SEVERE: {
    title: "MOD_SEVERE",
    description: "Moderately Severe",
  },
  NONE: {
    title: "NONE",
    description: "Not present if a symptom, strong if a strength",
  },
  SEVERE: {
    title: "SEVERE",
    description: "Severe ",
  },
  UNANSWERED: {
    title: "UNANSWERED",
    description: "Unanswered",
  },
  UNKNOWN: {
    title: "UNKNOWN",
    description: "Unknown",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function scaleThresholdClassT(value: ScaleThresholdClass, t: (t: `enums:ScaleThresholdClass.${keyof typeof resources["en"]["enums"]["ScaleThresholdClass"]}.title`) => string):string {
    switch (value) {
      case ScaleThresholdClass.CONTINUOUS: return t("enums:ScaleThresholdClass.CONTINUOUS.title");
case ScaleThresholdClass.LOW: return t("enums:ScaleThresholdClass.LOW.title");
case ScaleThresholdClass.MILD: return t("enums:ScaleThresholdClass.MILD.title");
case ScaleThresholdClass.MODERATE: return t("enums:ScaleThresholdClass.MODERATE.title");
case ScaleThresholdClass.MOD_SEVERE: return t("enums:ScaleThresholdClass.MOD_SEVERE.title");
case ScaleThresholdClass.NONE: return t("enums:ScaleThresholdClass.NONE.title");
case ScaleThresholdClass.SEVERE: return t("enums:ScaleThresholdClass.SEVERE.title");
case ScaleThresholdClass.UNANSWERED: return t("enums:ScaleThresholdClass.UNANSWERED.title");
case ScaleThresholdClass.UNKNOWN: return t("enums:ScaleThresholdClass.UNKNOWN.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  ScaleThresholdClass: {
  CONTINUOUS: {
    title: "CONTINUOUS",
    description: "Continuous",
  },
  LOW: {
    title: "LOW",
    description: "low",
  },
  MILD: {
    title: "MILD",
    description: "mild",
  },
  MODERATE: {
    title: "MODERATE",
    description: "Moderate ",
  },
  MOD_SEVERE: {
    title: "MOD_SEVERE",
    description: "Moderately Severe",
  },
  NONE: {
    title: "NONE",
    description: "Not present if a symptom, strong if a strength",
  },
  SEVERE: {
    title: "SEVERE",
    description: "Severe ",
  },
  UNANSWERED: {
    title: "UNANSWERED",
    description: "Unanswered",
  },
  UNKNOWN: {
    title: "UNKNOWN",
    description: "Unknown",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function scaleThresholdClassDescriptionT(value: ScaleThresholdClass, t: (t: `enums:ScaleThresholdClass.${keyof typeof resources["en"]["enums"]["ScaleThresholdClass"]}.description`) => string):string {
    switch (value) {
      case ScaleThresholdClass.CONTINUOUS: return t("enums:ScaleThresholdClass.CONTINUOUS.description");
case ScaleThresholdClass.LOW: return t("enums:ScaleThresholdClass.LOW.description");
case ScaleThresholdClass.MILD: return t("enums:ScaleThresholdClass.MILD.description");
case ScaleThresholdClass.MODERATE: return t("enums:ScaleThresholdClass.MODERATE.description");
case ScaleThresholdClass.MOD_SEVERE: return t("enums:ScaleThresholdClass.MOD_SEVERE.description");
case ScaleThresholdClass.NONE: return t("enums:ScaleThresholdClass.NONE.description");
case ScaleThresholdClass.SEVERE: return t("enums:ScaleThresholdClass.SEVERE.description");
case ScaleThresholdClass.UNANSWERED: return t("enums:ScaleThresholdClass.UNANSWERED.description");
case ScaleThresholdClass.UNKNOWN: return t("enums:ScaleThresholdClass.UNKNOWN.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  SeverityCategory: {
  INVALID: {
    title: "INVALID",
    description: "The result was not valid and could not be used",
  },
  MILD: {
    title: "MILD",
    description: "Mild",
  },
  MODERATE: {
    title: "MODERATE",
    description: "Moderate",
  },
  NONE: {
    title: "NONE",
    description: "Non-clinical",
  },
  NOT_SPECIFIED: {
    title: "NOT_SPECIFIED",
    description: "There is not enough data or the algorithm chose not to evaluate",
  },
  NOT_SUPPORTED: {
    title: "NOT_SUPPORTED",
    description: "Acute change is not supported",
  },
  SEVERE: {
    title: "SEVERE",
    description: "Severe",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function severityCategoryT(value: SeverityCategory, t: (t: `enums:SeverityCategory.${keyof typeof resources["en"]["enums"]["SeverityCategory"]}.title`) => string):string {
    switch (value) {
      case SeverityCategory.INVALID: return t("enums:SeverityCategory.INVALID.title");
case SeverityCategory.MILD: return t("enums:SeverityCategory.MILD.title");
case SeverityCategory.MODERATE: return t("enums:SeverityCategory.MODERATE.title");
case SeverityCategory.NONE: return t("enums:SeverityCategory.NONE.title");
case SeverityCategory.NOT_SPECIFIED: return t("enums:SeverityCategory.NOT_SPECIFIED.title");
case SeverityCategory.NOT_SUPPORTED: return t("enums:SeverityCategory.NOT_SUPPORTED.title");
case SeverityCategory.SEVERE: return t("enums:SeverityCategory.SEVERE.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  SeverityCategory: {
  INVALID: {
    title: "INVALID",
    description: "The result was not valid and could not be used",
  },
  MILD: {
    title: "MILD",
    description: "Mild",
  },
  MODERATE: {
    title: "MODERATE",
    description: "Moderate",
  },
  NONE: {
    title: "NONE",
    description: "Non-clinical",
  },
  NOT_SPECIFIED: {
    title: "NOT_SPECIFIED",
    description: "There is not enough data or the algorithm chose not to evaluate",
  },
  NOT_SUPPORTED: {
    title: "NOT_SUPPORTED",
    description: "Acute change is not supported",
  },
  SEVERE: {
    title: "SEVERE",
    description: "Severe",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function severityCategoryDescriptionT(value: SeverityCategory, t: (t: `enums:SeverityCategory.${keyof typeof resources["en"]["enums"]["SeverityCategory"]}.description`) => string):string {
    switch (value) {
      case SeverityCategory.INVALID: return t("enums:SeverityCategory.INVALID.description");
case SeverityCategory.MILD: return t("enums:SeverityCategory.MILD.description");
case SeverityCategory.MODERATE: return t("enums:SeverityCategory.MODERATE.description");
case SeverityCategory.NONE: return t("enums:SeverityCategory.NONE.description");
case SeverityCategory.NOT_SPECIFIED: return t("enums:SeverityCategory.NOT_SPECIFIED.description");
case SeverityCategory.NOT_SUPPORTED: return t("enums:SeverityCategory.NOT_SUPPORTED.description");
case SeverityCategory.SEVERE: return t("enums:SeverityCategory.SEVERE.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  TaskStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "This task has open work to do",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "This task has been completed",
  },
  WONTDO: {
    title: "WONTDO",
    description: "This task has been marked as wont do",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function taskStatusT(value: TaskStatus, t: (t: `enums:TaskStatus.${keyof typeof resources["en"]["enums"]["TaskStatus"]}.title`) => string):string {
    switch (value) {
      case TaskStatus.ACTIVE: return t("enums:TaskStatus.ACTIVE.title");
case TaskStatus.COMPLETE: return t("enums:TaskStatus.COMPLETE.title");
case TaskStatus.WONTDO: return t("enums:TaskStatus.WONTDO.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  TaskStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "This task has open work to do",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "This task has been completed",
  },
  WONTDO: {
    title: "WONTDO",
    description: "This task has been marked as wont do",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function taskStatusDescriptionT(value: TaskStatus, t: (t: `enums:TaskStatus.${keyof typeof resources["en"]["enums"]["TaskStatus"]}.description`) => string):string {
    switch (value) {
      case TaskStatus.ACTIVE: return t("enums:TaskStatus.ACTIVE.description");
case TaskStatus.COMPLETE: return t("enums:TaskStatus.COMPLETE.description");
case TaskStatus.WONTDO: return t("enums:TaskStatus.WONTDO.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  TreatmentServiceInferenceReason: {
  INSUFFICIENT_DATA_TO_MATCH: {
    title: "INSUFFICIENT_DATA_TO_MATCH",
    description: "Not enough data was supplied to match a treatment service",
  },
  MATCHED_ORGANIZATION: {
    title: "MATCHED_ORGANIZATION",
    description: "A treatment service with the same organization was found",
  },
  MATCHED_PATIENT_CONDITION: {
    title: "MATCHED_PATIENT_CONDITION",
    description: "A treatment service with the same patient diagnosis was found",
  },
  MATCHED_PROVIDER: {
    title: "MATCHED_PROVIDER",
    description: "A treatment service with the same provider was found",
  },
  MATCHED_SERVICE_TYPE: {
    title: "MATCHED_SERVICE_TYPE",
    description: "A treatment service with the same service type was found",
  },
  NO_MATCHES_FOUND: {
    title: "NO_MATCHES_FOUND",
    description: "No treatment services were found matching",
  },
  TREATMENT_SERVICES_DISABLED: {
    title: "TREATMENT_SERVICES_DISABLED",
    description: "Treatment services are disabled for this institute",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function treatmentServiceInferenceReasonT(value: TreatmentServiceInferenceReason, t: (t: `enums:TreatmentServiceInferenceReason.${keyof typeof resources["en"]["enums"]["TreatmentServiceInferenceReason"]}.title`) => string):string {
    switch (value) {
      case TreatmentServiceInferenceReason.INSUFFICIENT_DATA_TO_MATCH: return t("enums:TreatmentServiceInferenceReason.INSUFFICIENT_DATA_TO_MATCH.title");
case TreatmentServiceInferenceReason.MATCHED_ORGANIZATION: return t("enums:TreatmentServiceInferenceReason.MATCHED_ORGANIZATION.title");
case TreatmentServiceInferenceReason.MATCHED_PATIENT_CONDITION: return t("enums:TreatmentServiceInferenceReason.MATCHED_PATIENT_CONDITION.title");
case TreatmentServiceInferenceReason.MATCHED_PROVIDER: return t("enums:TreatmentServiceInferenceReason.MATCHED_PROVIDER.title");
case TreatmentServiceInferenceReason.MATCHED_SERVICE_TYPE: return t("enums:TreatmentServiceInferenceReason.MATCHED_SERVICE_TYPE.title");
case TreatmentServiceInferenceReason.NO_MATCHES_FOUND: return t("enums:TreatmentServiceInferenceReason.NO_MATCHES_FOUND.title");
case TreatmentServiceInferenceReason.TREATMENT_SERVICES_DISABLED: return t("enums:TreatmentServiceInferenceReason.TREATMENT_SERVICES_DISABLED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  TreatmentServiceInferenceReason: {
  INSUFFICIENT_DATA_TO_MATCH: {
    title: "INSUFFICIENT_DATA_TO_MATCH",
    description: "Not enough data was supplied to match a treatment service",
  },
  MATCHED_ORGANIZATION: {
    title: "MATCHED_ORGANIZATION",
    description: "A treatment service with the same organization was found",
  },
  MATCHED_PATIENT_CONDITION: {
    title: "MATCHED_PATIENT_CONDITION",
    description: "A treatment service with the same patient diagnosis was found",
  },
  MATCHED_PROVIDER: {
    title: "MATCHED_PROVIDER",
    description: "A treatment service with the same provider was found",
  },
  MATCHED_SERVICE_TYPE: {
    title: "MATCHED_SERVICE_TYPE",
    description: "A treatment service with the same service type was found",
  },
  NO_MATCHES_FOUND: {
    title: "NO_MATCHES_FOUND",
    description: "No treatment services were found matching",
  },
  TREATMENT_SERVICES_DISABLED: {
    title: "TREATMENT_SERVICES_DISABLED",
    description: "Treatment services are disabled for this institute",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function treatmentServiceInferenceReasonDescriptionT(value: TreatmentServiceInferenceReason, t: (t: `enums:TreatmentServiceInferenceReason.${keyof typeof resources["en"]["enums"]["TreatmentServiceInferenceReason"]}.description`) => string):string {
    switch (value) {
      case TreatmentServiceInferenceReason.INSUFFICIENT_DATA_TO_MATCH: return t("enums:TreatmentServiceInferenceReason.INSUFFICIENT_DATA_TO_MATCH.description");
case TreatmentServiceInferenceReason.MATCHED_ORGANIZATION: return t("enums:TreatmentServiceInferenceReason.MATCHED_ORGANIZATION.description");
case TreatmentServiceInferenceReason.MATCHED_PATIENT_CONDITION: return t("enums:TreatmentServiceInferenceReason.MATCHED_PATIENT_CONDITION.description");
case TreatmentServiceInferenceReason.MATCHED_PROVIDER: return t("enums:TreatmentServiceInferenceReason.MATCHED_PROVIDER.description");
case TreatmentServiceInferenceReason.MATCHED_SERVICE_TYPE: return t("enums:TreatmentServiceInferenceReason.MATCHED_SERVICE_TYPE.description");
case TreatmentServiceInferenceReason.NO_MATCHES_FOUND: return t("enums:TreatmentServiceInferenceReason.NO_MATCHES_FOUND.description");
case TreatmentServiceInferenceReason.TREATMENT_SERVICES_DISABLED: return t("enums:TreatmentServiceInferenceReason.TREATMENT_SERVICES_DISABLED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  Trend: {
  DECLINING: {
    title: "DECLINING",
    description: "There was a clinically significant worsening",
  },
  IMPROVING: {
    title: "IMPROVING",
    description: "There was a clinically significant improvement",
  },
  INVALID: {
    title: "INVALID",
    description: "The result was not valid and could not be used",
  },
  NOT_SPECIFIED: {
    title: "NOT_SPECIFIED",
    description: "There is not enough data or the algorithm chose not to evaluate",
  },
  NOT_SUPPORTED: {
    title: "NOT_SUPPORTED",
    description: "Acute change is not supported",
  },
  STABLE: {
    title: "STABLE",
    description: "There was no clinically significant change",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function trendT(value: Trend, t: (t: `enums:Trend.${keyof typeof resources["en"]["enums"]["Trend"]}.title`) => string):string {
    switch (value) {
      case Trend.DECLINING: return t("enums:Trend.DECLINING.title");
case Trend.IMPROVING: return t("enums:Trend.IMPROVING.title");
case Trend.INVALID: return t("enums:Trend.INVALID.title");
case Trend.NOT_SPECIFIED: return t("enums:Trend.NOT_SPECIFIED.title");
case Trend.NOT_SUPPORTED: return t("enums:Trend.NOT_SUPPORTED.title");
case Trend.STABLE: return t("enums:Trend.STABLE.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  Trend: {
  DECLINING: {
    title: "DECLINING",
    description: "There was a clinically significant worsening",
  },
  IMPROVING: {
    title: "IMPROVING",
    description: "There was a clinically significant improvement",
  },
  INVALID: {
    title: "INVALID",
    description: "The result was not valid and could not be used",
  },
  NOT_SPECIFIED: {
    title: "NOT_SPECIFIED",
    description: "There is not enough data or the algorithm chose not to evaluate",
  },
  NOT_SUPPORTED: {
    title: "NOT_SUPPORTED",
    description: "Acute change is not supported",
  },
  STABLE: {
    title: "STABLE",
    description: "There was no clinically significant change",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function trendDescriptionT(value: Trend, t: (t: `enums:Trend.${keyof typeof resources["en"]["enums"]["Trend"]}.description`) => string):string {
    switch (value) {
      case Trend.DECLINING: return t("enums:Trend.DECLINING.description");
case Trend.IMPROVING: return t("enums:Trend.IMPROVING.description");
case Trend.INVALID: return t("enums:Trend.INVALID.description");
case Trend.NOT_SPECIFIED: return t("enums:Trend.NOT_SPECIFIED.description");
case Trend.NOT_SUPPORTED: return t("enums:Trend.NOT_SUPPORTED.description");
case Trend.STABLE: return t("enums:Trend.STABLE.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  TriggeredWorkflowStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Workflow is triggered, shows as active in the UI, can be interacted with",
  },
  CANCELED: {
    title: "CANCELED",
    description: "Workflow is no longer active because the underlying conditions have changed",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "Everything users need to do related to the workflow has happened",
  },
  SNOOZED: {
    title: "SNOOZED",
    description: "Workflow is temporarily paused until the target time is reached",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function triggeredWorkflowStatusT(value: TriggeredWorkflowStatus, t: (t: `enums:TriggeredWorkflowStatus.${keyof typeof resources["en"]["enums"]["TriggeredWorkflowStatus"]}.title`) => string):string {
    switch (value) {
      case TriggeredWorkflowStatus.ACTIVE: return t("enums:TriggeredWorkflowStatus.ACTIVE.title");
case TriggeredWorkflowStatus.CANCELED: return t("enums:TriggeredWorkflowStatus.CANCELED.title");
case TriggeredWorkflowStatus.COMPLETE: return t("enums:TriggeredWorkflowStatus.COMPLETE.title");
case TriggeredWorkflowStatus.SNOOZED: return t("enums:TriggeredWorkflowStatus.SNOOZED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  TriggeredWorkflowStatus: {
  ACTIVE: {
    title: "ACTIVE",
    description: "Workflow is triggered, shows as active in the UI, can be interacted with",
  },
  CANCELED: {
    title: "CANCELED",
    description: "Workflow is no longer active because the underlying conditions have changed",
  },
  COMPLETE: {
    title: "COMPLETE",
    description: "Everything users need to do related to the workflow has happened",
  },
  SNOOZED: {
    title: "SNOOZED",
    description: "Workflow is temporarily paused until the target time is reached",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function triggeredWorkflowStatusDescriptionT(value: TriggeredWorkflowStatus, t: (t: `enums:TriggeredWorkflowStatus.${keyof typeof resources["en"]["enums"]["TriggeredWorkflowStatus"]}.description`) => string):string {
    switch (value) {
      case TriggeredWorkflowStatus.ACTIVE: return t("enums:TriggeredWorkflowStatus.ACTIVE.description");
case TriggeredWorkflowStatus.CANCELED: return t("enums:TriggeredWorkflowStatus.CANCELED.description");
case TriggeredWorkflowStatus.COMPLETE: return t("enums:TriggeredWorkflowStatus.COMPLETE.description");
case TriggeredWorkflowStatus.SNOOZED: return t("enums:TriggeredWorkflowStatus.SNOOZED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 

    
  /* The translation format to get you started
  UserConsentStatus: {
  ACCEPTED: {
    title: "ACCEPTED",
    description: "The user accepted consent",
  },
  DECLINED: {
    title: "DECLINED",
    description: "The user declined consent",
  },
  DEFERRED: {
    title: "DEFERRED",
    description: "The user did not make a decision about consent and deferred",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function userConsentStatusT(value: UserConsentStatus, t: (t: `enums:UserConsentStatus.${keyof typeof resources["en"]["enums"]["UserConsentStatus"]}.title`) => string):string {
    switch (value) {
      case UserConsentStatus.ACCEPTED: return t("enums:UserConsentStatus.ACCEPTED.title");
case UserConsentStatus.DECLINED: return t("enums:UserConsentStatus.DECLINED.title");
case UserConsentStatus.DEFERRED: return t("enums:UserConsentStatus.DEFERRED.title");
      default:
        return exhaustiveGuard(value);
    }
  }
  

    
  /* The translation format to get you started
  UserConsentStatus: {
  ACCEPTED: {
    title: "ACCEPTED",
    description: "The user accepted consent",
  },
  DECLINED: {
    title: "DECLINED",
    description: "The user declined consent",
  },
  DEFERRED: {
    title: "DEFERRED",
    description: "The user did not make a decision about consent and deferred",
  }

  If this is a new enum or a new value, you can add the above hash to
  src/i18n/Resources/en/enums.ts to get started on translations.
*/
  export function userConsentStatusDescriptionT(value: UserConsentStatus, t: (t: `enums:UserConsentStatus.${keyof typeof resources["en"]["enums"]["UserConsentStatus"]}.description`) => string):string {
    switch (value) {
      case UserConsentStatus.ACCEPTED: return t("enums:UserConsentStatus.ACCEPTED.description");
case UserConsentStatus.DECLINED: return t("enums:UserConsentStatus.DECLINED.description");
case UserConsentStatus.DEFERRED: return t("enums:UserConsentStatus.DEFERRED.description");
      default:
        return exhaustiveGuard(value);
    }
  }
  
 