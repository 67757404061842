import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  DialogContent,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CocmBillingAlgorithmCode,
  CocmBillingAlgorithmRule,
  CocmBillingAlgorithmRuleCheck,
  CocmBillingAlgorithmRuleCode,
  Enrollment,
  EnrollmentMonth,
  EnrollmentMonthBillingRuleResult,
  EnrollmentMonthBillingRuleResultStatus,
  EnrollmentMonthBillingRuleResultWinner,
  Patient,
  useBillingPredictionWinnerDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollmentMonthBillingRuleResultId, EnrollmentMonthBillingRuleResultWinnerId } from "Lib/Ids";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import ErrorMessage from "Shared/ErrorMessage";
import NotFound from "Shared/NotFound";
import Spinner from "Shared/Spinner";
import React from "react";
import { useTranslation } from "react-i18next";
import { BillableUnitExplanation, FailReasonsExplanation } from "./BillingRuleHelpers";
import DecisionTable, { AlgorithmDecisionResult } from "Shared/DecisionTable";
import { TFunction } from "i18next";
import { exhaustiveGuard } from "type-utils";
import InfoSidebarCard, { LabelAndInfo, LabelAndRichInfo } from "Shared/InfoSidebarCard";
import { MoneyDetails, formatMoney } from "Shared/Money";
import { usePatientLinkUrl } from "FeedbackReport/Demographics/DemographicInfo";
import { CopyMonthlySummaryButton } from "CollaborativeCare/PatientDetails/CopyMonthlySummaryButton";
import { formatMinutes } from "Shared/MinutesLabel";

type EnrollmentSummary = Pick<Enrollment, "id" | "firstValidatedMeasureCompletedAt" | "enrolledAt"> & {
  careEpisode: { patient: Pick<Patient, "id" | "nameLastFirst" | "mrn" | "dob"> };
};

type EnrollmentMonthSummaryDetails = Pick<
  EnrollmentMonth,
  | "id"
  | "billableMinutes"
  | "month"
  | "validatedMeasureCompleted"
  | "firstValidatedMeasureCompletedAt"
  | "lastValidatedMeasureCompletedAt"
  | "beginningOfMonth"
> & { enrollment: EnrollmentSummary };

function BillingWinnerDetailsModalInner(props: {
  winner: Pick<EnrollmentMonthBillingRuleResultWinner, "id"> & {
    results: ReadonlyArray<RuleResultDetails>;
    winningResult: RuleResultDetails | null;
    nextResult: RuleResultDetails | null;
    enrollmentMonth: EnrollmentMonthSummaryDetails;
  };
}) {
  const { t } = useTranslation(["billing"]);
  return (
    <Stack direction="column" spacing={1}>
      <PatientSummary patient={props.winner.enrollmentMonth.enrollment.careEpisode.patient} />
      <EnrollmentMonthSummary enrollmentMonth={props.winner.enrollmentMonth} />
      <BillableMinutesSummary enrollmentMonth={props.winner.enrollmentMonth} />
      <RuleSummary
        result={props.winner.winningResult}
        title={t("billing:predictionWinner.currentWinner.title")}
        missingMessage={t("billing:predictionWinner.currentWinner.missing")}
        resultType="active"
      />
      <RuleSummary
        result={props.winner.nextResult}
        title={t("billing:predictionWinner.nextUp.title")}
        missingMessage={t("billing:predictionWinner.nextUp.missing")}
        resultType="next"
      />
      <Card>
        <CardHeader title={t("billing:predictionWinner.algorithmDetails")} id="trace" />
        <CardContent>
          <Stack direction="column" spacing={1}>
            <Typography>{t("billing:predictionWinner.algorithmExplanation")}</Typography>
            <AlgorithmTrace
              ruleResults={props.winner.results}
              winningPriority={props.winner.winningResult?.priority || null}
              winningResultId={props.winner.winningResult?.id}
              nextResultId={props.winner.nextResult?.id}
              currentMinutes={props.winner.enrollmentMonth.billableMinutes}
            />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

function BillableMinutesSummary(props: {
  enrollmentMonth: Pick<EnrollmentMonth, "beginningOfMonth"> & {
    enrollment: Pick<Enrollment, "id"> & { careEpisode: { patient: Pick<Patient, "id"> } };
  };
}) {
  return (
    <Card>
      <CardHeader id="minutes" title={"Minutes"} />
      <CardContent>
        <CopyMonthlySummaryButton
          filters={{
            enrollmentId: props.enrollmentMonth.enrollment.id,
            date: props.enrollmentMonth.beginningOfMonth,
            status: null,
            billableMinutesGreaterThanZero: false,
          }}
          patientId={props.enrollmentMonth.enrollment.careEpisode.patient.id}
        />
      </CardContent>
    </Card>
  );
}

type RuleSummaryProps = {
  result: RuleResultDetails | null;
  title: string;
  missingMessage: string;
  resultType: ResultType;
};

function RuleSummary(props: RuleSummaryProps) {
  const { result, title, missingMessage, resultType } = props;
  const { t } = useTranslation(["billing", "common"]);
  const theme = useTheme();

  if (!result) {
    return (
      <Card>
        <CardHeader title={title} />
        <CardContent>{missingMessage}</CardContent>
      </Card>
    );
  }

  const background =
    resultType === "active"
      ? theme.palette.billing.currentBillable
      : resultType === "next"
      ? theme.palette.billing.nextBillable
      : undefined;

  const nextSteps =
    resultType === "next" ? (
      <LabelAndRichInfo
        label={t("billing:predictionWinner.nextUp.steps")}
        data={
          <FailReasonsExplanation
            minutesShort={result.minutesDiffVsCurrentWinner}
            failReasonCodes={result.failReasonCodes}
            disqualifyReasonCodes={result.disqualifyReasonCodes}
          />
        }
      />
    ) : null;

  return (
    <InfoSidebarCard title={title} backgroundColor={background}>
      <LabelAndRichInfo
        label={t("billing:predictionWinner.nextUp.codes")}
        data={<BillableUnitExplanation rule={result.rule} />}
      />
      <LabelAndInfo
        label={t("billing:predictionWinner.nextUp.expectedRate")}
        data={formatMoney(result.expectedRate)}
      />
      <LabelAndInfo label={t("billing:predictionWinner.nextUp.rvus")} data={result.rvus?.toFixed() || "-"} />
      <LabelAndInfo
        label={t("billing:predictionWinner.nextUp.valueUnits")}
        data={result.valueUnits?.toFixed() || "-"}
      />
      <LabelAndInfo
        label={t("billing:predictionWinner.nextUp.billableMinutes")}
        data={result.billableMinutes ? formatMinutes(result.billableMinutes) : "-"}
      />
      <LabelAndRichInfo
        label={t("billing:predictionWinner.nextUp.algorithm")}
        data={<Link href={`#${result.id}-header`}>{t("billing:predictionWinner.nextUp.showAlgorithm")}</Link>}
      />
      {nextSteps}
    </InfoSidebarCard>
  );
}

function PatientSummary(props: { patient: Pick<Patient, "id" | "mrn" | "nameLastFirst" | "dob"> }) {
  const { t } = useTranslation(["billing", "common"]);
  const { patient } = props;
  const url = usePatientLinkUrl(patient.id);
  return (
    <InfoSidebarCard title={t("billing:predictionWinner.patientSummary.title")}>
      <LabelAndRichInfo
        label={t("billing:predictionWinner.patientSummary.patientName")}
        data={<Link href={url}>{patient.nameLastFirst}</Link>}
      />
      <LabelAndInfo
        label={t("billing:predictionWinner.patientSummary.dob")}
        data={t("common:date.tiny", { date: patient.dob })}
      />
      <LabelAndInfo label={t("billing:predictionWinner.patientSummary.mrn")} data={patient.mrn} />
    </InfoSidebarCard>
  );
}

function EnrollmentMonthSummary(props: { enrollmentMonth: EnrollmentMonthSummaryDetails }) {
  const { t } = useTranslation(["billing", "common"]);
  const { enrollmentMonth } = props;
  const { enrollment } = enrollmentMonth;
  return (
    <InfoSidebarCard title={t("billing:predictionWinner.summary.title")}>
      <LabelAndInfo
        label={t("billing:predictionWinner.summary.enrolledAt")}
        data={t("common:date.tiny", { date: enrollment.enrolledAt })}
      />
      <LabelAndInfo
        label={t("billing:predictionWinner.summary.monthOfTreatment")}
        data={enrollmentMonth.month.toFixed()}
      />
      <LabelAndRichInfo
        label={t("billing:predictionWinner.summary.minutesMonth", { date: enrollmentMonth.beginningOfMonth })}
        data={
          <Typography>
            {enrollmentMonth.billableMinutes.toFixed()} <Link href="#minutes">Details</Link>
          </Typography>
        }
      />
      <LabelAndInfo
        label={t("billing:predictionWinner.summary.firstValidatedAssessmentCompleted")}
        data={
          enrollmentMonth.enrollment.firstValidatedMeasureCompletedAt
            ? t("common:date.tiny", { date: enrollmentMonth.enrollment.firstValidatedMeasureCompletedAt })
            : "-"
        }
      />
      <LabelAndInfo
        label={t("billing:predictionWinner.summary.lastValidatedAssessmentCompletedThisMonth", {
          date: enrollmentMonth.beginningOfMonth,
        })}
        data={
          enrollmentMonth.lastValidatedMeasureCompletedAt
            ? t("common:date.tiny", { date: enrollmentMonth.lastValidatedMeasureCompletedAt })
            : "-"
        }
      />
    </InfoSidebarCard>
  );
}

function resultTypeFromResult(
  ruleResult: RuleResultDetails,
  winningPriority: number | null,
  nextResultId: EnrollmentMonthBillingRuleResultId | null | undefined
): ResultType {
  if (ruleResult.id === nextResultId) {
    return "next";
  }

  if (ruleResult.status === EnrollmentMonthBillingRuleResultStatus.DISQUALIFIED) {
    return "disqualified";
  }

  if (ruleResult.status === EnrollmentMonthBillingRuleResultStatus.BILLABLE) {
    if (ruleResult.priority === winningPriority) {
      return "active";
    } else {
      return "bettered";
    }
  }

  // Rule Result is requirements not met
  if (winningPriority && ruleResult.priority > winningPriority) {
    return "skipped";
  } else {
    return "requirementsNotMet";
  }
}

type RuleCodeDetails = Pick<CocmBillingAlgorithmRuleCode, "units"> & {
  code: Pick<CocmBillingAlgorithmCode, "code">;
};

export type RuleResultDetails = Pick<
  EnrollmentMonthBillingRuleResult,
  | "id"
  | "status"
  | "failReasonCodes"
  | "priority"
  | "minutesShort"
  | "disqualifyReasonCodes"
  | "rvus"
  | "valueUnits"
  | "billableMinutes"
  | "minutesDiffVsCurrentWinner"
> & {
  expectedRate: MoneyDetails;
  rule: Pick<
    CocmBillingAlgorithmRule,
    "anyAssessmentCompleted" | "minimumMinutes" | "mustBeSubsequentEncounter" | "mustBeInitialEncounter"
  > & { ruleCodes: ReadonlyArray<RuleCodeDetails> };
};

// We ideally would get this out of the backend but we don't actually do this
// final step and we have all the results here.
type ResultType = "active" | "requirementsNotMet" | "disqualified" | "bettered" | "skipped" | "next";

function AlgorithmTrace(props: {
  winningResultId: EnrollmentMonthBillingRuleResultId | null | undefined;
  winningPriority: number | null;
  nextResultId: EnrollmentMonthBillingRuleResultId | null | undefined;
  ruleResults: ReadonlyArray<RuleResultDetails>;
  currentMinutes: number;
}) {
  return props.ruleResults.map((ruleResult, index) => {
    return (
      <AlgorithmTraceRule
        ruleResult={ruleResult}
        key={index}
        currentMinutes={props.currentMinutes}
        resultType={resultTypeFromResult(ruleResult, props.winningPriority, props.nextResultId)}
      />
    );
  });
}

function ruleCheckSuccessText(
  value: CocmBillingAlgorithmRuleCheck,
  minimumMinutes: number | null,
  currentMinutes: number | null,
  t: TFunction<["billing"]>
): string {
  switch (value) {
    case CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED:
      return t("billing:rules.anyAssessmentCompleted.pass");
    case CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES:
      return t("billing:rules.minimumMinutes.pass", { minimumMinutes, currentMinutes });
    case CocmBillingAlgorithmRuleCheck.NOT_BILLED_99492_OR_99493_ALREADY:
      return t("billing:rules.notBilled99492Or99493Already.pass");
    case CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER:
      return t("billing:rules.mustBeInitialEncounter.pass");
    case CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER:
      return t("billing:rules.mustBeSubsequentEncounter.pass");
    default:
      return exhaustiveGuard(value);
  }
}

function ruleCheckFailureText(
  value: CocmBillingAlgorithmRuleCheck,
  minimumMinutes: number | null,
  currentMinutes: number | null,
  t: TFunction<["billing"]>
): string {
  switch (value) {
    case CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED:
      return t("billing:rules.anyAssessmentCompleted.fail");
    case CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES:
      return t("billing:rules.minimumMinutes.fail", { minimumMinutes, currentMinutes });
    case CocmBillingAlgorithmRuleCheck.NOT_BILLED_99492_OR_99493_ALREADY:
      return t("billing:rules.notBilled99492Or99493Already.fail");
    case CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER:
      return t("billing:rules.mustBeInitialEncounter.fail");
    case CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER:
      return t("billing:rules.mustBeSubsequentEncounter.fail");
    default:
      return exhaustiveGuard(value);
  }
}

function ruleCheckIgnoreText(value: CocmBillingAlgorithmRuleCheck, t: TFunction<["billing"]>): string {
  switch (value) {
    case CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED:
      return t("billing:rules.anyAssessmentCompleted.ignore");
    case CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES:
      return t("billing:rules.minimumMinutes.ignore");
    case CocmBillingAlgorithmRuleCheck.NOT_BILLED_99492_OR_99493_ALREADY:
      return t("billing:rules.notBilled99492Or99493Already.ignore");
    case CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER:
      return t("billing:rules.mustBeInitialEncounter.ignore");
    case CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER:
      return t("billing:rules.mustBeSubsequentEncounter.ignore");
    default:
      return exhaustiveGuard(value);
  }
}

function rowToDecisionTableRow(
  check: CocmBillingAlgorithmRuleCheck,
  minimumMinutes: number | null,
  currentMinutes: number | null,
  skipped: boolean,
  failed: boolean,
  disqualified: boolean,
  t: TFunction<["billing"]>
): { description: string; result: AlgorithmDecisionResult } {
  if (skipped) {
    return {
      description: ruleCheckIgnoreText(check, t),
      result: AlgorithmDecisionResult.SUCCESS,
    };
  }
  if (failed) {
    return {
      description: ruleCheckFailureText(check, minimumMinutes, currentMinutes, t),
      result: AlgorithmDecisionResult.FAILURE,
    };
  } else if (disqualified) {
    return {
      description: ruleCheckFailureText(check, minimumMinutes, currentMinutes, t),
      result: AlgorithmDecisionResult.FAILURE,
    };
  } else {
    return {
      description: ruleCheckSuccessText(check, minimumMinutes, currentMinutes, t),
      result: AlgorithmDecisionResult.SUCCESS,
    };
  }
}

function algorithmResults(
  failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck>,
  disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck>,
  rule: Pick<
    CocmBillingAlgorithmRule,
    "minimumMinutes" | "anyAssessmentCompleted" | "mustBeInitialEncounter" | "mustBeSubsequentEncounter"
  >,
  currentMinutes: number | null,
  t: TFunction<["billing"]>
) {
  return [
    rowToDecisionTableRow(
      CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED,
      rule.minimumMinutes,
      currentMinutes,
      !rule.anyAssessmentCompleted,
      failReasonCodes.includes(CocmBillingAlgorithmRuleCheck.ASSESSMENT_COMPLETED),
      false,
      t
    ),
    rowToDecisionTableRow(
      CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES,
      rule.minimumMinutes,
      currentMinutes,
      typeof rule.minimumMinutes !== "number",
      failReasonCodes.includes(CocmBillingAlgorithmRuleCheck.MINIMUM_MINUTES),
      false,
      t
    ),
    rowToDecisionTableRow(
      CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER,
      rule.minimumMinutes,
      currentMinutes,
      !rule.mustBeInitialEncounter,
      false,
      disqualifyReasonCodes.includes(CocmBillingAlgorithmRuleCheck.MUST_BE_INITIAL_ENCOUNTER),
      t
    ),
    rowToDecisionTableRow(
      CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER,
      rule.minimumMinutes,
      currentMinutes,
      !rule.mustBeSubsequentEncounter,
      false,
      disqualifyReasonCodes.includes(CocmBillingAlgorithmRuleCheck.MUST_BE_SUBSEQUENT_ENCOUNTER),
      t
    ),
  ];
}

function AlgorithmTraceRule(props: {
  ruleResult: RuleResultDetails;
  currentMinutes: number;
  resultType: ResultType;
}) {
  const { ruleResult, resultType } = props;
  const { t } = useTranslation(["billing"]);
  const theme = useTheme();

  const decisionSections = [
    {
      rows: algorithmResults(
        ruleResult.failReasonCodes || [],
        ruleResult.disqualifyReasonCodes || [],
        ruleResult.rule,
        props.currentMinutes,
        t
      ),
    },
  ];

  const background =
    resultType === "active"
      ? theme.palette.billing.currentBillable
      : resultType === "next"
      ? theme.palette.billing.nextBillable
      : undefined;

  return (
    <Accordion
      defaultExpanded={resultType === "active" || resultType === "next"}
      sx={{ backgroundColor: background }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        area-controls={`${ruleResult.id}-panel`}
        id={`${ruleResult.id}-header`}
      >
        <BillableUnitExplanation rule={props.ruleResult.rule} /> - Priority {props.ruleResult.priority} -{" "}
        {t(`billing:predictionWinner.status.${props.resultType}.title`)}
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1}>
          <Typography>{t(`billing:predictionWinner.status.${props.resultType}.description`)}</Typography>
          <DecisionTable sections={decisionSections} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

export default function BillingWinnerDetailsModal(props: {
  winnerId: EnrollmentMonthBillingRuleResultWinnerId;
  onClose: () => void;
}) {
  const { t } = useTranslation(["billing"]);
  const { remoteData } = apolloQueryHookWrapper(
    useBillingPredictionWinnerDetailsQuery({
      variables: { id: props.winnerId },
    })
  );

  const content = remoteData.caseOf({
    Success: (result) => {
      if (result.billingEnrollmentMonthBillingRuleResultWinner) {
        return (
          <BillingWinnerDetailsModalInner winner={result.billingEnrollmentMonthBillingRuleResultWinner} />
        );
      } else {
        return <NotFound />;
      }
    },
    Failure: (message) => <ErrorMessage message={message.message} />,
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
  });

  const title = remoteData.caseOf({
    Success: (result) => {
      const row = result.billingEnrollmentMonthBillingRuleResultWinner;
      if (row) {
        return t("billing:predictionWinner.titleWithDetails", {
          patientName: row.enrollmentMonth.enrollment.careEpisode.patient.nameLastFirst,
          date: row.enrollmentMonth.beginningOfMonth,
        });
      } else {
        return t("billing:predictionWinner.title");
      }
    },
    _: () => t("billing:predictionWinner.title"),
  });

  return (
    <ResponsiveDialog dialogWidth="50%" open={true} onClose={() => props.onClose()} title={title}>
      <DialogContent>{content}</DialogContent>
    </ResponsiveDialog>
  );
}
