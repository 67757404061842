const enums = {
  AppointmentMeasurementCategory: {
    COMPLETE: {
      title: "Complete",
      description: "All data has been collected for this appointment",
    },
    IN_PROGRESS: {
      title: "In Progress",
      description: "At least one respondent has started the assessment",
    },
    NOT_PARTICIPATING: {
      title: "Not Participating",
      description: "This appointment has not been enabled for MBC and no measurements will be taken",
    },
    NOT_MEASURED: {
      title: "Not Measured",
      description: "This appointment has no measurement associated",
    },
    NOT_STARTED: {
      title: "Not Started",
      description:
        "The window to complete assessments is open but no respondents have started their assessment yet",
    },
    FAILED: {
      title: "Failed",
      description: "No feedback was collected and measurement is now closed",
    },
    MEASUREMENT_CANCELED: {
      title: "Measurement Canceled",
      description:
        "Either the appointment was canceled, or the client did not show for the session and did not fill out the un ssessment",
    },
    FUTURE: {
      title: "Future",
      description:
        "This appointment is in the future and the window to enter assessment data is not open yet",
    },
    NOTIFICATIONS_BLOCKED: {
      title: "Notifications Blocked",
      description:
        "We are unable to send notifications because the patient has opted out or is missing contact information",
    },
    ERROR: {
      title: "Error",
      description: "We have encountered an error",
    },
    AWAITING_INTERVIEW: {
      title: "Awaiting Interview",
      description: "The measurement is waiting on a provider interview",
    },
  },
  DischargeReason: {
    CHANGED_PROVIDER: {
      title: "Patient Changed Provider",
      description: "The patient has changed their care provider",
    },
    COMPLETED: {
      title: "Program Completed",
      description: "The patient successfully completed the program",
    },
    DROPPED_OUT: {
      title: "Patient Dropped Out or Declined to Participate",
      description: "The patient dropped out or otherwise declined to participate",
    },
    LOST_CONTACT: {
      title: "Lost Contact with Patient",
      description: "The patient stopped responding after some time",
    },
    OTHER: {
      title: "Other",
      description: "Other",
    },
    NO_CONTACT: {
      title: "No Contact with Patient",
      description: "The patient was unable to be contacted",
    },
    REFERRAL_TO_CARE: {
      title: "Referral To Care",
      description: "The patient has been referred to further care",
    },
    GRADUATED: {
      title: "Graduated",
      description: "The patient has graduated from care",
    },
  },
  ScaleThresholdClass: {
    CONTINUOUS: {
      title: "Continuous",
      description: "Continuous",
    },
    LOW: {
      title: "Low",
      description: "Low",
    },
    MILD: {
      title: "Mild",
      description: "Mild",
    },
    MODERATE: {
      title: "Moderate",
      description: "Moderate",
    },
    MOD_SEVERE: {
      title: "Moderate Severe",
      description: "Moderate Severe",
    },
    NONE: {
      title: "None",
      description: "None",
    },
    SEVERE: {
      title: "Severe",
      description: "Severe",
    },
    UNANSWERED: {
      title: "Unanswered",
      description: "Unanswered",
    },
    UNKNOWN: {
      title: "Unknown",
      description: "Unknown",
    },
  },
  PatientQualityIssueType: {
    AGE_INCORRECT: {
      title: "Age Doubtful",
      description: "The Age of this patient needs to be confirmed",
    },
    CAREGIVER_CONTACT_FORBIDDEN: {
      title: "Caregiver Contact Forbidden",
      description: "Caregiver has forbidden contact",
    },
    CAREGIVER_NEVER_OPENED: {
      title: "Caregiver Never Opened",
      description: "Caregiver has not opened a notification",
    },
    CAREGIVER_NO_CONTACT_INFO: {
      title: "Caregiver No Contact Details",
      description: "Caregiver has no contact details",
    },
    CAREGIVER_SHARES_CONTACT_INFO: {
      title: "Shared Contact Info",
      description: "Caregiver shares contact information with the patient",
    },
    NOT_MEASURED_FOR_EXTENDED_PERIOD: {
      title: "Large Measurement Gap",
      description: "No Data has been collected for this patient for over 6 weeks",
    },
    PATIENT_NEVER_OPENED: {
      title: "Never Opened",
      description: "Patient has not opened an assessment",
    },
    PATIENT_OVER_11_CONTACT_FORBIDDEN: {
      title: "Contact Forbidden",
      description: "Patient has opted out of contact",
    },
    PATIENT_OVER_11_NO_CONTACT_INFO: {
      title: "No Contact Details",
      description: "Patient has no contact details",
    },
    PATIENT_UNDER_18_NO_CAREGIVER: {
      title: "Missing Caregiver",
      description: "Child has no active caregiver",
    },
  },

  TriggeredWorkflowStatus: {
    ACTIVE: {
      title: "Active",
      description: "Workflow is triggered, shows as active in the UI, can be interacted with",
    },
    CANCELED: {
      title: "Canceled",
      description: "Workflow is no longer active because the underlying conditions have changed",
    },
    COMPLETE: {
      title: "Complete",
      description: "Everything users need to do related to the workflow has happened",
    },
    SNOOZED: {
      title: "Snoozed",
      description: "Workflow is temporarily paused until the target time is reached",
    },
  },
  PatientQualityIssueStatus: {
    FIXED_IN_EHR: {
      title: "Fixed in EHR",
      description: "It's been marked as fixed in the EHR, but hasn't come through to us yet.",
    },
    NOT_AN_ISSUE: {
      title: "Not an issue",
      description: "This is not an issue",
    },
    OUT_OF_TREATMENT: {
      title: "Out of treatment",
      description: "This patient is not in treatment and therefore the flag was cleared",
    },
    RESOLVED: {
      title: "Resolved",
      description: "All is fixed",
    },
    SNOOZE: {
      title: "Snoozed for 7 days",
      description: "Not resolved but come back later",
    },
    UNRESOLVED: {
      title: "Unresolved",
      description: "It hasn't been looked at",
    },
    WONT_FIX: {
      title: "Won't fix",
      description: "This IS an issue, but it's too difficult to fix",
    },
  },
  TaskStatus: {
    ACTIVE: {
      title: "Active",
      description: "Active",
    },
    COMPLETE: {
      title: "Done",
      description: "Done",
    },
    WONTDO: {
      title: "Won't Do",
      description: "Won't Do",
    },
  },

  Gender: {
    MALE: {
      title: "Male",
      description: "Male",
    },
    FEMALE: {
      title: "Female",
      description: "Female",
    },
    OTHER: {
      title: "Other",
      description: "Other",
    },
    UNKNOWN: {
      title: "Unknown",
      description: "Unknown",
    },
  },

  // Note that this is for describing each language to a user who speaks the language of this translation, not the
  // language that is being described. Thus ES -> "Spanish" instead of ES -> "Espanola".
  ScaleLocale: {
    EN: {
      title: "English",
      description: "English",
    },
    ES: {
      title: "Spanish",
      description: "Spanish",
    },
  },

  RelatedPersonRelationship: {
    CHILD: {
      title: "Child",
      description: "Child",
    },
    FATHER: {
      title: "Father",
      description: "Father",
    },
    GUARDIAN: {
      title: "Guardian/Caregiver",
      description: "Guardian/caregiver",
    },
    MOTHER: {
      title: "Mother",
      description: "Mother",
    },
    OTHER: {
      title: "Other",
      description: "Other",
    },
    TEACHER: {
      title: "Teacher",
      description: "Teacher",
    },
  },
  EntityDecisionAlgorithmStatus: {
    ACTIVE: {
      title: "Active",
      description: "Algorithm is active. Alerts will be created and displayed",
    },
    DISABLED: {
      title: "Disabled",
      description: "Algorithm will not trigger, and alerts are hidden",
    },
    HIDDEN: {
      title: "Hidden",
      description: "Algorithm continues to trigger, but alerts will be shown",
    },
    PAUSED: {
      title: "Paused",
      description: "Algorithm will not trigger, but alerts for existing triggers will be seen",
    },
  },
  EnrollmentStatus: {
    ENROLLED: {
      title: "Enrolled",
      description: "This care episode is enrolled in collaborative care",
    },
    UNENROLLED: {
      title: "Unenrolled (All)",
      description: "This care episode's collaborative care is not currently active",
    },
    UNENROLLED_NEVER_ENROLLED: {
      title: "Unenrolled (Never Enrolled)",
      description: "This care episode's collaborative care was never started and is not currently active",
    },
    DISCHARGED: {
      title: "Unenrolled (Discharged)",
      description: "This care episode's collaborative care is finished and is not currently active",
    },
  },
  GenderIdentity: {
    FEMALE: {
      title: "Female",
      description: "The patient identifies as female",
    },
    MALE: {
      title: "Male",
      description: "The patient identifies as male",
    },
    NON_BINARY: {
      title: "Non-binary",
      description: "The patient identifies with neither/both female and male",
    },
    NON_DISCLOSE: {
      title: "Undisclosed",
      description: "The patient does not wish to disclose his gender identity",
    },
    OTHER: {
      title: "Other",
      description: "Other gender identity",
    },
    TRANSGENDER_FEMALE: {
      title: "Transgender Female",
      description: "The patient identifies as transgender male-to-female",
    },
    TRANSGENDER_MALE: {
      title: "Transgender Male",
      description: "The patient identifies as transgender female-to-male",
    },
  },
  NotificationPreference: {
    BOTH: {
      title: "Both",
      description: "Send both text message and email notifications",
    },
    EMAIL: {
      title: "Email",
      description: "Only send email notifications",
    },
    NONE: {
      title: "None",
      description: "Do not send any notifications",
    },
    TEXT: {
      title: "Text",
      description: "Only send text message notifications",
    },
  },
  ConsultRequestReason: {
    CHANGE_OF_TREATMENT_PLAN: {
      title: "Treatment plan changed",
      description: "Patient has changed to a new course of treatment",
    },
    DIAGNOSTIC_CLARITY: {
      title: "Diagnostic clarity",
      description: "Providers need help assessing a case",
    },
    DISCHARGE: {
      title: "Considering discharge",
      description: "Patient may be ready for discharge",
    },
    INCREASED_MEASURE_SEVERITY: {
      title: "Increased measure severity",
      description: "Severity of patient's measures has increased recently",
    },
    LACK_OF_CLINICAL_IMPROVEMENT: {
      title: "Lack of improvement",
      description: "Patient not showing improvement after treatment",
    },
    MEDICAL_COMPLICATIONS: {
      title: "Medical complications",
      description: "Patient is experiencing medical complications",
    },
    MEDICATION_MANAGEMENT_QUESTION: {
      title: "Medication management",
      description: "Providers need help managing medication for this patient",
    },
    MEDICATION_SIDE_EFFECT: {
      title: "Medication side effect",
      description: "Patient is experiencing a side effect from medication",
    },
    NEW_INFORMATION: {
      title: "New information",
      description: "New information about a patient may change diagnosis or treatment",
    },
    OTHER: {
      title: "Other",
      description: "Reasons not captured in other options",
    },
    QUICK_WIN: {
      title: "Quick win",
      description: "Good news about a patient for team morale",
    },
    SDOH: {
      title: "SDOH factors",
      description: "SDOH factors my change a patient's diagnosis or treatment",
    },
  },
  ConsultMeetingStatus: {
    ENDED: {
      title: "Ended",
      description: "THis meeting has ended",
    },
    ONGOING: {
      title: "Ongoing",
      description: "This meeting is currently happening",
    },
  },
  DataDumpResource: {
    ANON_RESEARCH_SET: {
      title: "Anonymized Research Set",
      description: "An anonymized version of the Research Set that does not contain PHI",
    },
    ANSWER: {
      title: "Answers",
      description: "All responses for individual questions",
    },
    APPOINTMENT: {
      title: "Appointments",
      description: "Appointments",
    },
    CONTACT_DETAILS_QUALITY: {
      title: "Contact Details Quality",
      description: "Shows patient records that may have quality issues",
    },
    COVERAGE: {
      title: "Insurance Coverage",
      description: "Information on a patient's insurance coverage",
    },
    DIAGNOSTIC_REPORT: {
      title: "Diagnostic Reports",
      description: "Diagnostic Reports",
    },
    ENCOUNTER: {
      title: "Encounters",
      description: "Measurement Encounters",
    },
    EPISODE_OF_CARE: {
      title: "Episodes of Care",
      description: "Episodes of Care",
    },
    OBSERVATION: {
      title: "Observations",
      description: "Observations, i.e. scored responses for scales such as the PHQ9",
    },
    ORGANIZATION: {
      title: "Organizations",
      description: "Organizational Units",
    },
    OUTCOMES: {
      title: "Outcomes Data",
      description: "A format specifically designed to allow easy outcomes metrics analysis",
    },
    PATIENT: {
      title: "Patients",
      description: "Patients",
    },
    PRACTITIONER: {
      title: "Practitioners",
      description: "Data on practitioners/providers in the system",
    },
    QUESTION: {
      title: "Questions",
      description: "Data Dictionary of system questions users may be asked",
    },
    QUESTIONNAIRE: {
      title: "Questionnaires",
      description: "Data Dictionary of the available questionnaires/survey instruments",
    },
    QUESTIONNAIRE_RESPONSE: {
      title: "Questionnaire Responses",
      description: "Which respondents took which surveys at what time",
    },
    REIMBURSEMENT_LIST: {
      title: "Reimbursement List",
      description: "Export including potentially reimbursable surveys",
    },
    RELATED_PERSON: {
      title: "Related People",
      description: "Related People",
    },
    REPORT_VIEW: {
      title: "Provider Feedback Report Viewing",
      description: "When providers viewed each patients feedback report",
    },
    RESEARCH_PATIENT_SET: {
      title: "Research Patient Set",
      description: "A research data set featuring one line per patient",
    },
    RESEARCH_SET: {
      title: "Research Data Set",
      description:
        "A simplified export format which allows users to get information suitable for looking at outcomes in Excel or other spreadsheet tools",
    },
    USER_CONSENT: {
      title: "User Consents",
      description: "User Consent information",
    },
    COLLABORATIVE_CARE_CASE_CONSULT: {
      title: "Collaborative Care Case Consultations",
      description: "Case consultations done as part of collaborative care",
    },
    COLLABORATIVE_CARE_CONSULT_MEETING: {
      title: "Collaborative Care Consultation Meetings",
      description: "Information about consultation meetings done for collaborative care",
    },
    COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE: {
      title: "Collaborative Care Consultation Meeting Attendees",
      description: "Attenddess for consultation meetings for collaborative care",
    },
    COLLABORATIVE_CARE_CONSULT_REQUEST: {
      title: "Collaborative Care Consult Request",
      description: "Consultation requests for patients enrolled in collaborative care",
    },
    COLLABORATIVE_CARE_ENROLLMENTS: {
      title: "Collaborative Care Enrollments",
      description: "Enrollments for collaborative care",
    },
    COLLABORATIVE_CARE_PANEL: {
      title: "Collaborative Care Panels",
      description: "Panels for collaborative care",
    },
    COLLABORATIVE_CARE_PANEL_TEAM_MEMBER: {
      title: "Collaborative Care Panel Team Members",
      description: "Team members on panels in collaborative care",
    },
    COLLABORATIVE_CARE_TASK: {
      title: "Collaborative Care Tasks",
      description: "Task data for collaborative care enrollments",
    },
    COLLABORATIVE_CARE_TIME_ENTRY_LOG: {
      title: "Collaborative Care Time Entry Logs",
      description: "Time tracking data for collaborative care tasks",
    },
    COLLABORATIVE_CARE_NOTE: {
      title: "Collaborative Care Notes",
      description: "Note data for collaborative care enrollments",
    },
    RESEARCH_EXPERIMENT: {
      title: "Research Experiment",
      description: "Data about experiments. Deprecated",
    },
    DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM: {
      title: "Decision Support Entity Decision Algorithms",
      description: "Decision Support Algorithms",
    },
    DECISION_SUPPORT_PROVIDER_NOTIFICATION: {
      title: "Decision Support Provider Notifications",
      description: "Decision Support Provider Notifications",
    },
    DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM: {
      title: "Decision Support Triggered Decision Algorithms",
      description: "Decision Support Triggered Algorithms",
    },
    DECISION_SUPPORT_TRIGGERED_WORKFLOW: {
      title: "Decision Support Triggered Workflows",
      description: "Decision Support Triggered Workflows",
    },
    DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION: {
      title: "Decision Support Triggered Workflow Actions",
      description: "Decision Support Triggered Actions",
    },
    DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG: {
      title: "Decision Support Triggered Workflow Action Logs",
      description: "Decision Support Triggered Action Logs",
    },
    DECISION_SUPPORT_WORKFLOW: {
      title: "Decision Support Workflows",
      description: "Decision Support Workflows",
    },
    DECISION_SUPPORT_WORKFLOW_ACTION: {
      title: "Decision Support Workflow Actions",
      description: "Decision Support Actions",
    },
    GOAL: {
      title: "Patient Goal",
      description:
        "A patient-specific goal created with custom text to be used to monitor specific patient progress",
    },
    GOAL_ANSWER: {
      title: "Patient Goal Answer",
      description: "An answer from a respondent on a patient-specific goal",
    },
  },
  EntityType: {
    CARE_EPISODE: {
      title: "Episode Of Care",
      description: "An episode of care",
    },
    CARE_UNIT_TYPE: {
      title: "Patient or Group",
      description: "The type of patient",
    },
    ENTITY_DECISION_ALGORITHM: {
      title: "Entity Decision Algorithm",
      description: "An instance of a decision support algorithm",
    },
    EXPERIMENT: {
      title: "Experiment",
      description: "An experiment",
    },
    EXPERIMENT_VARIANT: {
      title: "Experiment Variant",
      description: "The variant of an experiment",
    },
    INSTITUTE: {
      title: "Instance",
      description: "A top level instance",
    },
    INSTITUTE_CONDITION: {
      title: "Diagnosis",
      description: "A diagnosis as specified by the institute EHR",
    },
    INSTITUTE_PAYOR: {
      title: "Insurance Company",
      description: "An insurance company",
    },
    ORGANIZATION: {
      title: "Organization",
      description: "An organization within an institute",
    },
    PATIENT: {
      title: "Patient",
      description: "A patient",
    },
    PATIENT_SESSION: {
      title: "Measurement",
      description: "A measurement of a patient",
    },
    PROVIDER: {
      title: "Provider",
      description: "A clinician/provider",
    },
    TREATMENT_SERVICE: {
      title: "Treatment Service",
      description: "A treatment service",
    },
    TREATMENT_TRACK: {
      title: "Treatment Track",
      description: "A treatment track",
    },
    USER: {
      title: "User",
      description: "A user of the system. Could be a patient, provider, related person",
    },
    WORKFLOW: {
      title: "Decision Support Workflow",
      description: "A workflow as part of a decision support algorithm",
    },
    WORKFLOW_ACTION: {
      title: "Decision Support Action",
      description: "An action taken as part of a decision support algorithm",
    },
    PANEL: {
      title: "Panel",
      description: "A team of providers overseeing a panel of patients",
    },
    PROVIDER_RELATIONSHIP: {
      title: "Provider in Role",
      description: "A provider in a particular role",
    },
    PRIMARY_CARE_PHYSICIAN: {
      title: "PCP",
      description: "A provider operating in a PCP role",
    },
    CARE_MANAGER: {
      title: "Care Manager",
      description: "A provider operating in a Care Manager role",
    },
    LOCATION: {
      title: "Location",
      description: "A physical location",
    },
    ENCOUNTER: {
      title: "Encounter",
      description: "An encounter with a provider",
    },
    ROOT: {
      title: "All Data",
      description: "All available data",
    },
    INSTITUTE_GROUP: {
      title: "Institute Group",
      description: "A group of institutes",
    },
  },
  Trend: {
    DECLINING: {
      title: "Worsening",
      description: "There was a clinically significant worsening",
    },
    IMPROVING: {
      title: "Improving",
      description: "There was a clinically significant improvement",
    },
    INVALID: {
      title: "Invalid",
      description: "The result was not valid and could not be used",
    },
    NOT_SPECIFIED: {
      title: "Not Specified",
      description: "There is not enough data or the algorithm chose not to evaluate",
    },
    NOT_SUPPORTED: {
      title: "Not Supported",
      description: "Acute change is not supported",
    },
    STABLE: {
      title: "Stable",
      description: "There was no clinically significant change",
    },
  },
  SeverityCategory: {
    INVALID: {
      title: "Invalid",
      description: "The result was not valid and could not be used",
    },
    MILD: {
      title: "Mild",
      description: "Mild",
    },
    MODERATE: {
      title: "Moderate",
      description: "Moderate",
    },
    NONE: {
      title: "None",
      description: "Non-clinical",
    },
    NOT_SPECIFIED: {
      title: "Not Specified",
      description: "There is not enough data or the algorithm chose not to evaluate",
    },
    NOT_SUPPORTED: {
      title: "Not Supported",
      description: "Acute change is not supported",
    },
    SEVERE: {
      title: "Severe",
      description: "Severe",
    },
  },
  PatientConditionStatus: {
    ACTIVE: {
      title: "Active",
      description: "Condition is active",
    },
    INACTIVE: {
      title: "Inactive",
      description: "Condition is inactive",
    },
    RECURRENCE: {
      title: "Recurrence",
      description: "Condition is active and has recurred",
    },
    REMISSION: {
      title: "Remission",
      description: "Condition is in remission",
    },
    RESOLVED: {
      title: "Resolved",
      description: "Condition is resolved",
    },
  },
  UserConsentStatus: {
    ACCEPTED: {
      title: "Accepted",
      description: "The user accepted consent",
    },
    DECLINED: {
      title: "Declined",
      description: "The user declined consent",
    },
    DEFERRED: {
      title: "Deferred",
      description: "The user did not make a decision about consent and deferred",
    },
  },
  CoverageRelationship: {
    CHILD: {
      title: "Child",
      description: "The Beneficiary is a child of the Subscriber",
    },
    COMMON: {
      title: "Common",
      description: "The Beneficiary is a common law spouse of the Subscriber",
    },
    INJURY: {
      title: "Injury",
      description: "The Beneficiary is covered under insurance of the Subscriber due to an injury.",
    },
    NONE: {
      title: "None",
      description: "The Beneficiary has no relationship to the Subscriber",
    },
    OTHER: {
      title: "Other",
      description: "The Beneficiary has some other relationship to the Subscriber",
    },
    PARENT: {
      title: "Parent",
      description: "The Beneficiary is a parent of the Subscriber",
    },
    SELF: {
      title: "Self",
      description: "The Beneficiary is the Subscriber",
    },
    SPOUSE: {
      title: "Spouse",
      description: "The Beneficiary is the spouse of the Subscriber",
    },
  },
  CareEpisodeStatus: {
    ACTIVE: {
      title: "Active",
      description: "Active",
    },
    CANCELED: {
      title: "Canceled",
      description: "Canceled - currently unused",
    },
    FINISHED: {
      title: "Finished",
      description: "Finished",
    },
    ON_HOLD: {
      title: "On Hold",
      description: "On hold - currently unused",
    },
    PLANNED: {
      title: "Planned",
      description: "Planned - currently unused",
    },
    WAITLIST: {
      title: "Waitlist",
      description: "Waitlist - currently unused",
    },
  },
  AppointmentStatus: {
    ARRIVED: {
      title: "Arrived",
      description: "Arrived",
    },
    BOOKED: {
      title: "Booked",
      description: "Booked",
    },
    CANCELED: {
      title: "Canceled",
      description: "Canceled",
    },
    CHECKED_IN: {
      title: "Checked In",
      description: "Checked in",
    },
    ENTERED_IN_ERROR: {
      title: "Entered In Error",
      description: "Entered in error",
    },
    FULFILLED: {
      title: "Fulfilled",
      description: "Fulfilled",
    },
    NOSHOW: {
      title: "No Show",
      description: "No-show",
    },
    PENDING: {
      title: "Pending",
      description: "Pending",
    },
    PROPOSED: {
      title: "Proposed",
      description: "Proposed",
    },
    WAIT_LIST: {
      title: "Wait List",
      description: "Wait list",
    },
  },
  InvitationStatus: {
    BEGUN: {
      title: "Begun",
      description: "The invitation has had at least one quetion answered by the participant",
    },
    CANCELED: {
      title: "Canceled",
      description: "The invitation was canceled",
    },
    MANUALLY_CANCELED: {
      title: "Manually Canceled",
      description: "The invitation was canceled manually by a care manager",
    },
    COMPLETE: {
      title: "Complete",
      description: "The invitation has been completed by the participant",
    },
    EXPIRED: {
      title: "Expired",
      description: "The invitation was not started and has expired",
    },
    FUTURE: {
      title: "Future",
      description: "The invitation is for the future and cannot be used yet",
    },
    LOCKED_OUT: {
      title: "Locked Out",
      description: "The invitation has been locked due to failed login attempts.",
    },
    NEW: {
      title: "New",
      description: "The invitation is open but is not configured to send any notifications",
    },
    NOT_REQUIRED: {
      title: "Not Required",
      description: "The invitation was not required",
    },
    OPENED: {
      title: "Opened",
      description: "The invitation was opened by the participant",
    },
    PARTIAL: {
      title: "Partial",
      description: "The invitation was started but not completed and has now expired",
    },
    SENT: {
      title: "Sent",
      description: "The invitation is open for feedback and notification have been sent",
    },
  },
  InvitationFormat: {
    INTERVIEW: {
      title: "Interview",
      description: "Interview, i.e. questionnaire is asked by provider",
    },
    SELF_REPORT: {
      title: "Self Report",
      description: "Self report, i.e. completed by the participant themselves",
    },
  },
  DataSourceRecordLogStatus: {
    AWAITING_PROCESSING: {
      title: "Awaiting Processing",
      description: "Record is awaiting processing",
    },
    CREATED: {
      title: "Created",
      description: "This was the first time this EHR record was seen. A new Mirah record was created",
    },
    ID_ERROR: {
      title: "ID Error",
      description: "Mirah could not determine a valid identifier for this record and it was not processed",
    },
    INVALID: {
      title: "Invalid",
      description: "The data from the EHR had too many errors and could not be saved in our system.",
    },
    LOAD_ERROR: {
      title: "Load Error",
      description:
        "An unspecified error occurred while processing the data. Please contact Mirah support if you continue to see this.",
    },
    PROCESSING: {
      title: "Processing",
      description: "Record is currently processing",
    },
    SKIPPED: {
      title: "Skipped",
      description: "This record was skipped and will not be imported.",
    },
    STALE: {
      title: "Stale",
      description: "By the time this record was processed, it was not deemed current and will be ignored.",
    },
    SYSTEM_ERROR: {
      title: "System Error",
      description: "An unknown error occurred while processing.",
    },
    UNCHANGED: {
      title: "Unchanged",
      description:
        "This record was processed by our system but no changes were detected so our record was not updated.",
    },
    UNKNOWN_ERROR: {
      title: "Unknown Error",
      description: "An unknown error occurred while processing",
    },
    UPDATED: {
      title: "Updated",
      description:
        "This record was processed by our system and found to have changes which were updated in our record.",
    },
  },
  DataSourceRecordLogError: {
    ASSOCIATION_MISSING: {
      title: "Association Missing",
      description:
        "This field links to another item (for example, a patient, provider, or organization) which we have no record of",
    },
    ASSOCIATION_NOT_IMPORTED: {
      title: "Association Not Imported",
      description:
        "This field links to another item (for example, a patient, provider, or organization) which we have not imported in our system. This is usually because the associated item had errors",
    },
    BLANK_NOT_ALLOWED: {
      title: "Blank Value Not Allowed",
      description: "A blank value for this field is not permitted",
    },
    INVALID_COLUMN: {
      title: "Invalid Column",
      description:
        "The column does not correspond to anything our system understands. This is likely to be a problem with the data being sent to Mirah, please contact your IT team",
    },
    INVALID_FORMAT: {
      title: "Invalid Format",
      description:
        "The value was in an invalid format. For example, a date, email address, or phone number that is in the wrong format.",
    },
    INVALID_TYPE: {
      title: "Invalid Type",
      description: "The column data has not be typed correctly (ie a date field marked as a string).",
    },
    MISSING_COLUMN: {
      title: "Missing Column",
      description:
        "The column requested was not found in the source document. This is likely a problem with the data being sent to Mirah, please contact your IT team",
    },
    NOT_PRESENT: {
      title: "Not Present",
      description: "The value was required but no value was found",
    },
    UNKNOWN_ERROR: {
      title: "Unknown Error",
      description: "An unknown error occurred",
    },
    UNMAPPED: {
      title: "Unmapped",
      description:
        "A value was supplied that has not been mapped to a Mirah value. For example, a gender, race or ethnicity that our system could not understand.",
    },
  },
  DataSourceRecordAlgorithm: {
    APPOINTMENT_CARE_EPISODE_CREATION: {
      title: "Create Care Episode From Appointment",
      description: "Creates a new care episode for an appointment",
    },
    APPOINTMENT_CARE_EPISODE_PROVIDER: {
      title: "Add Provider to Care Team",
      description: "Adds a provider to an episode of care",
    },
    APPOINTMENT_CREATE_ATHENA_ENCOUNTER: {
      title: "Create Athena Encounter From Appointment",
      description: "Creates an athena encounter from this appointment",
    },
    APPOINTMENT_DEFAULT_PROVIDER: {
      title: "Set Patient Default Provider",
      description: "Sets the default provider to that of this appointment",
    },
    APPOINTMENT_PATIENT_SESSION_CREATION: {
      title: "Create Measurement From Appointment",
      description: "Creates a new measurement for this appointment",
    },
    PATIENT_COVERAGE: {
      title: "Update Insurance Information",
      description: "Extracts coverage information from patient details",
    },
    EXTERNAL_OBSERVATION_IMPORT: {
      title: "Import External Observation",
      description:
        "Imports an external observation into Mirah's scoring system so it can be viewed on the feedback report",
    },
  },
  CareEpisodeInferenceReason: {
    APPOINTMENT_NO_TREATMENT_SERVICE: {
      title: "Required Treatment Service not found",
      description:
        "Appointments require a treatment service to be matched and this appointment has no treatment service",
    },
    CARE_EPISODES_MANAGED_EXTERNALLY: {
      title: "Episodes of care externally managed",
      description: "Mirah is receiving episodes of care from the EHR and will chose the first available",
    },
    EXACT_MATCH_ORGANIZATION: {
      title: "Matched on Organization",
      description: "There is exactly one episode of care in the same organizations as the provider",
    },
    MATCHED_TREATMENT_SERVICE: {
      title: "Matched on Treatment Service",
      description: "An active episode of care with a matching treatment service was found",
    },
    MOST_RECENT_MATCH_ORGANIZATION: {
      title: "Most recent match on Organization",
      description:
        "There are multiple episodes of care in the same organization as the provider, the one with the most recent measurement has been chosen.",
    },
    NO_MATCH_DEFAULT: {
      title: "Most recent chosen, no exact match",
      description:
        "No episodes of care matched the exact criteria, so the most recent active episode of care was chosen",
    },
    NO_MATCH_FOUND: {
      title: "No suitable match was found",
      description: "There are no episodes of care suitable to be used",
    },
    PROVIDER_ON_CARE_TEAM: {
      title: "Provider is on the care team",
      description: "The provider is on the care team for this episode of care",
    },
    SINGLE_EPISODE_OF_CARE_ALLOWED: {
      title: "Single episode of care allowed",
      description:
        "The system is configured to only allow one episode of care at a time, and that episode of care will always be chosen",
    },
  },
  TreatmentServiceInferenceReason: {
    INSUFFICIENT_DATA_TO_MATCH: {
      title: "Not enough data to match",
      description: "Not enough data was supplied to match a treatment service",
    },
    MATCHED_ORGANIZATION: {
      title: "Matched Organization",
      description: "A treatment service with the same organization was found",
    },
    MATCHED_PATIENT_CONDITION: {
      title: "Matched Diagnosis",
      description: "A treatment service with the same patient diagnosis was found",
    },
    MATCHED_PROVIDER: {
      title: "Matched Provider",
      description: "A treatment service with the same provider was found",
    },
    MATCHED_SERVICE_TYPE: {
      title: "Matched Service Type",
      description: "A treatment service with the same service type was found",
    },
    NO_MATCHES_FOUND: {
      title: "No Matches Found",
      description:
        "No treatment services were found matching this combination of provider, unit and service type",
    },
    TREATMENT_SERVICES_DISABLED: {
      title: "Treatment Service Disabled",
      description: "Treatment services are disabled for this institute",
    },
  },
  InvitationPhase: {
    AFTER: {
      title: "After appointment",
      description: "After the target date",
    },
    BEFORE: {
      title: "Before appointment",
      description: "Before the target date",
    },
    DURING: {
      title: "During appointment",
      description: "During the target date",
    },
  },
  RecurrenceFrequency: {
    ANNUALLY: {
      title: "Annually",
      description: "The appointment repeats anually",
    },
    BIANNUALLY: {
      title: "Twice annually",
      description: "The appointment repeats twice per year",
    },
    BIMONTHLY: {
      title: "Every two months",
      description: "The appointment repeats every two months",
    },
    BIWEEKLY: {
      title: "Every two weeks",
      description: "The appointment repeats every two weeks",
    },
    DAILY: {
      title: "Daily",
      description: "The appointment repeats every day",
    },
    MONTHLY: {
      title: "Monthly",
      description: "The appointment repeats monthly",
    },
    NONE: {
      title: "Does not repeat",
      description: "The appointment does not repeat",
    },
    QUARTERLY: {
      title: "Quarterly",
      description: "The appointment repeats once per quarter",
    },
    WEEKDAYS: {
      title: "Weekdays",
      description: "The appointment repeats every weekday",
    },
    WEEKLY: {
      title: "Weekly",
      description: "The appointment repeats once per week",
    },
  },
  CareEpisodeProviderRelationship: {
    ADMINISTRATOR: {
      title: "Administrator",
      description: "Administrator",
    },
    CARE_MANAGER: {
      title: "Care manager",
      description: "Care manager",
    },
    CASE_MANAGER: {
      title: "Case manager",
      description: "Case manager",
    },
    CLINICIAN: {
      title: "Clinician",
      description: "Clinician",
    },
    COUNSELOR: {
      title: "Counselor",
      description: "Counselor",
    },
    DIRECTOR: {
      title: "Director",
      description: "Director",
    },
    PRIMARY_CARE_PHYSICIAN: {
      title: "PCP",
      description: "Primary care physician",
    },
    PSYCHIATRIST: {
      title: "Psychiatrist",
      description: "Psychiatrist",
    },
    PSYCHOLOGIST: {
      title: "Psychologist",
      description: "Psychologist",
    },
    RESEARCH_ASSISTANT: {
      title: "Research Assistant",
      description: "Research assistant",
    },
    SOCIAL_WORKER: {
      title: "Social Worker",
      description: "Social worker",
    },
    THERAPIST: {
      title: "Therapist",
      description: "Therapist",
    },
    SUPERVISOR: {
      title: "Supervisor",
      description: "Supervisor",
    },
    EXECUTIVE: {
      title: "Executive",
      description: "Executive",
    },
    FRONT_DESK: {
      title: "Front Desk",
      description: "Front Desk",
    },
  },
  CareEpisodeTreatmentTrackStatus: {
    ACTIVE: {
      title: "Active",
      description: "Treatment track is active for this care episode",
    },
    COMPLETE: {
      title: "Complete",
      description: "Treatment track is complete for this episode and no longer active",
    },
    ENTERED_IN_ERROR: {
      title: "Entered in error",
      description: "Treatment track was enrolled in error",
    },
  },
  ScaleTargeting: {
    GENERAL_FUNCTIONING: {
      title: "General Functioning",
      description: "This scale measures general functioning",
    },
    HEADLINE: {
      title: "Headline",
      description: "This scale is single most important target. Only one per care episode",
    },
    NOT_TARGETED: {
      title: "Not Targeting",
      description: "This scale is not the target of treatment",
    },
    SUBSCALE_OF_TARGET: {
      title: "Subscale of Target",
      description: "This scale is a subscale of a targeted scale",
    },
    TARGETED: {
      title: "Targeted",
      description: "This scale is the target of treatment",
    },
  },
  PanelTeamMemberFunction: {
    BEHAVIOR_HEALTH_CONSULTANT: {
      title: "Behavioral Health Consultant",
      description: "Behavioral Health Consultant",
    },
    CARE_MANAGER: {
      title: "Care Manager",
      description: "Care Manager",
    },
    CONSULTING_PSYCH: {
      title: "Consulting Psychiatrist",
      description: "Consulting Psychiatrist",
    },
    PRIMARY_CARE: {
      title: "Primary Care Provider",
      description: "Primary Care Provider",
    },
  },
  ImplementationTargetReportGraphDataSeries: {
    DATA: {
      title: "Data",
      description: "This series is the actual data",
    },
    TARGET: {
      title: "Target",
      description: "This series is the target line",
    },
  },
  ImplementationTargetReportPeriod: {
    MONTH: {
      title: "Month",
      description: "The report applies to a calendar month",
    },
    WEEK: {
      title: "Week",
      description: "The report applies to a 7-day week",
    },
  },
  ImplementationTargetStatPeriod: {
    LAST_7_DAYS: {
      title: "Last 7 days",
      description: "The stat applies to the last 7 days",
    },
    LAST_30_DAYS: {
      title: "Last 30 days",
      description: "The stat applies to the last 30 days",
    },
    SINCE_GOAL_START: {
      title: "Since goal start",
      description: "The stat applies since the start of the goal",
    },
    BEFORE_CURRENT_REPORT: {
      title: "Total before period",
      description: "The stat applies since the start of the goal but not including the current report",
    },
    LAST_MONTH: {
      title: "Last month",
      description: "The stat applies to the previous month",
    },
    THIS_MONTH: {
      title: "This month",
      description: "The stat applies to the current month of report",
    },
  },
  ImplementationTargetStatus: {
    ACTIVE: {
      title: "Active",
      description: "This target is active",
    },
    RETIRED: {
      title: "Retired",
      description: "This target is no longer active and has been retired",
    },
  },
  ImplementationTargetType: {
    COCM_NEW_ENROLLMENTS: {
      title: "New Collaborative Care Enrollments",
      description:
        "This target helps you measure new enrollments into your collaborative care program. It is particularly useful early during implementation in the first lauch phase, where it is important to get as many patients enrolled as possible to ensure your care managers are busy",
    },
    COCM_MONTHLY_BILLED_MINUTES: {
      title: "Collaborative Care Monthly Billed Minutes",
      description:
        "This target concerns how many minutes are being billed per month. It will reset at the start of every month. Tracking minutes global minutes throughout the month is essential to ensure adequate care is being provided.",
    },
    COCM_MONTHLY_EXPECTED_BILLING: {
      title: "Collaborative Care Monthly Estimated Billing",
      description:
        "This target concerns how much revenue is estimated to be billed per month. This is based on an average rate across all payers and is only approximate.",
    },
    COCM_MONTHLY_EXPECTED_RVUS: {
      title: "Collaborative Care Monthly Estimated RVUs",
      description: "This target concerns how many RVUs are estimated to be billed per month",
    },
    COCM_MONTHLY_EXPECTED_VALUE_UNITS: {
      title: "Collaborative Care Monthly Care Units",
      description: "This target concerns how many Care Units have been delivered per month",
    },
    COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES: {
      title: "Collaborative Care Monthly Billable Minutes",
      description: "This target concerns the minutes for billable CoCM codes",
    },
    COCM_MONTHLY_BILLING_EFFICIENCY: {
      title: "Collaborative Care Billing Efficiency",
      description: "This target concerns the effective use of time for billing",
    },
  },
  ImplementationTargetOnTrack: {
    OFF_TRACK: {
      title: "Off Track",
      description: "The target is on track",
    },
    ON_TRACK: {
      title: "On Track",
      description: "The target is on track",
    },
  },
  CocmBillingAlgorithmStatus: {
    ACTIVE: {
      title: "Active",
      description: "Algorithm is active. Charges will be generated",
    },
    RETIRED: {
      title: "Retired",
      description: "Algorithm is retired and will not be used",
    },
  },
  CocmBillingAlgorithmRuleCheckValue: {
    ESTIMATED_RATE: {
      title: "Estimated Rate",
      description: "Measure billing success on estimated dollars received",
    },
    RVUS: {
      title: "RVUs",
      description: "Measure billing success on RVUs",
    },
    VALUE_UNITS: {
      title: "Care Units",
      description: "Measure billing success on Care Units",
    },
    BILLABLE_MINUTES: {
      title: "Billable Hours",
      description: "Measure billing success on number of billable minutes",
    },
  },
  CocmBillingAlgorithmRuleCheck: {
    ASSESSMENT_COMPLETED: {
      title: "Assessment Completed",
      description: "Whether an assessment has been completed",
    },
    MINIMUM_MINUTES: {
      title: "Minimum Minutes",
      description: "Whether the minimum required minutes have been met",
    },
    NOT_BILLED_99492_OR_99493_ALREADY: {
      title: "Blocking codes billed already",
      description: "Whether there have already been 99492 and 99493 codes billed",
    },
    MUST_BE_SUBSEQUENT_ENCOUNTER: {
      title: "Must be billed as an subsequent encounter",
      description: "Whether there has been an initial encounter already that allows this being billed",
    },
    MUST_BE_INITIAL_ENCOUNTER: {
      title: "Must be billed as an initial encounter",
      description: "Whether there has been an initial encounter already that prevents this being billed",
    },
  },
  EntityTreeNodeQueryType: {
    EQUAL_OR_LESS_SPECIFIC: {
      title: "Match or be less specific",
      description: "Objects which are exact matches or match but are less specific",
    },
    EQUAL_OR_MORE_SPECIFIC: {
      title: "Match or be more specific",
      description: "Objects which are exact matches or match but are more specific",
    },
    EXACT_MATCH: {
      title: "Exact Match",
      description: "Only objects matching this exact set of parameters will be returned",
    },
    ALL: {
      title: "ALL",
      description: "All objects",
    },
    LESS_SPECIFIC: {
      title: "Less Specific",
      description: "Objects which are less specific",
    },
    MORE_OR_LESS_SPECIFIC: {
      title: "More or Less Specific",
      description: "Objects which are more or less specific but not equal",
    },
    MORE_SPECIFIC: {
      title: "More Specific",
      description: "Objects which are more specific",
    },
  },
  EnrollmentMonthMinutesTargetStatus: {
    ON_TARGET: {
      title: "On target",
      description: "The month is on target for minutes for the month",
    },
    OVER_TARGET: {
      title: "Over target",
      description: "The month is over target minutes for the month",
    },
    UNDER_TARGET: {
      title: "Under target",
      description: "The month is under target minutes for the month",
    },
  },
  ClinicalAlertValue: {
    ALERT: {
      title: "Risk Indicated",
      description: "The alert triggered for this value",
    },
    NOT_SUPPORTED: {
      title: "No Data",
      description: "Alerting is not supported for this CV",
    },
    NO_ALERT: {
      title: "Minimal Risk",
      description: "There was no alert for this value",
    },
  },
  OncePerPeriod: {
    CALENDAR_MONTH: {
      title: "calendar month",
      description: "Administer only once per calendar month",
    },
    CALENDAR_QUARTER: {
      title: "calendar quarter",
      description: "Administer only once per calendar quarter",
    },
    CALENDAR_YEAR: {
      title: "calendaryear",
      description: "Administer only once per calendar year",
    },
  },
};

export default enums;
